import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WalletModalComponent } from '../modals/wallet-modal/wallet-modal.component';
import { UsersService } from '../../services/users.service';
import { CoinColor } from '../../interfaces/coin-color';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-coins',
  templateUrl: './coins.component.html',
  styleUrls: ['./coins.component.scss'],
})
export class CoinsComponent implements OnInit, OnChanges {
  @Input() color: CoinColor = 'green';
  user: any;
  coins: number = this.userService.coins$();

  constructor(
    public productService: ProductsService,
    private utilsService: UtilsService,
    private userService: UsersService,
  ) { }

  async ngOnInit() {
    this.userService.setcoinsComponent(this);
    await this.updateCoins();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['update']) {
      await this.updateCoins();
    }
  }

  public async updateCoins() {
    this.user = this.userService.getLoggedUser();
    this.userService.coins$.set(this.user?.user_detail?.coins);
    this.coins = this.userService.coins$();
  }

  async showWallet() {
    const modal = await this.utilsService.showModal(WalletModalComponent);
    modal.present();
    modal.onDidDismiss().then( ()=>{
      this.updateCoins();
    });
  }
}
