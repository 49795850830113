import { Component, Input, OnInit } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-social-sharing-modal',
  templateUrl: './social-sharing-modal.component.html',
  styleUrls: ['./social-sharing-modal.component.scss'],
})
export class SocialSharingModalComponent  implements OnInit {

  @Input() screenshot: string;
  message = '¡Mira el resultado de mi último partido!';
  url = 'https://ksport.com/splash';

  constructor(
    private socialSharing: SocialSharing
  ) { }

  ngOnInit() {}

  shareOnWhatsapp() {
    this.socialSharing.shareViaWhatsApp(
      this.message,
      this.screenshot,
      this.url
    ).then(() => {
      console.log('Shared successfully!');
    }).catch((error) => {
      console.error('Error sharing', error);
    });
  }

  shareOnFacebook() {
    this.socialSharing.shareViaFacebook(
      this.message,
      this.screenshot,
      this.url
    ).then(() => {
      console.log('Shared successfully!');
    }).catch((error) => {
      console.error('Error sharing', error);
    });
  }

  shareOnInstagram() {
    this.socialSharing.shareViaInstagram(
      this.message,
      this.screenshot
    ).then(() => {
      console.log('Shared successfully!');
    }).catch((error) => {
      console.error('Error sharing', error);
    });
  }

  shareOnTwitter() {
    this.socialSharing.shareViaTwitter(
      this.message,
      this.screenshot,
      this.url
    ).then(() => {
      console.log('Shared successfully!');
    }).catch((error) => {
      console.error('Error sharing', error);
    });
  }

  share() {
    this.socialSharing.share(
      this.message,
      null,
      this.screenshot,
      this.url
    ).then(() => {
      console.log('Shared successfully!');
    }).catch((error) => {
      console.error('Error sharing', error);
    });
  }

}
