import { Component, OnInit } from "@angular/core";
import { TrainingService } from "src/app/services/training.service";

@Component({
  selector: "app-streak-track",
  templateUrl: "./streak-track.component.html",
  styleUrls: ["./streak-track.component.scss"],
})
export class StreakTrackComponent implements OnInit {
  streak: number;
  hasTrained: boolean;
  soccerCoinUrl: string;

  constructor(
    private trainingService: TrainingService
  ){}

  ngOnInit(): void {
    if(this.streak === undefined){
      this.streak = this.trainingService.consecutiveDays()
    }
    this.updateHasTrained(this.hasTrained);
    this.updateStreak(this.streak)
  }

  public updateStreak(streak: number): void {
    this.streak = streak;
  }

  public updateHasTrained(hasTrained: boolean): void {
    this.hasTrained = hasTrained;
    this.soccerCoinUrl = this.hasTrained
      ? "assets/icon/balon.svg"
      : "assets/icon/balonGris.svg";
  }
}
