import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import to from 'await-to-js';
import { debounceTime, lastValueFrom, Subject, Subscription } from 'rxjs';
import { IAvailableTeam } from 'src/app/interfaces/IAvailableTeam';
import { TeamService } from 'src/app/services/team.service';
import { TournamentService } from 'src/app/services/tournament.service';
import { UsersService } from 'src/app/services/users.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.scss'],
})
export class InviteFriendsComponent  implements OnInit {
  
  step: number = 1;
  list: IAvailableTeam[] = [];
  selectedList: IAvailableTeam[] = [];
  page = 1;
  totalPages = 1;
  subscription: Subscription = null;
  filterData = [];
  filterSubject: Subject<any> = new Subject<any>();
  teamInfomation: any;
  user: any;
  val: string = undefined;
  tournamentId: number;
  selectedListIds: any[] = [];

  @ViewChild(IonContent, { static: false }) content: IonContent;

  constructor(
    private userService: UsersService,
    private utilsService: UtilsService,
    private teamService: TeamService,
    private modalCtrl: ModalController,
    private tournamentService: TournamentService
  ) { }

  ngOnInit() {
    this.user = this.userService.getLoggedUser();
    if (this.user.teams) {
      this.teamInfomation = this.user.teams[0];
    }
    this.filterSubject.pipe(debounceTime(1000)).subscribe(async (_: any) => {
      this.page = 1;
      await this.getAvailableTeam(true, null);
    });
  }

  search(searchTerm: any) {
    this.val = searchTerm.target.value;
    console.log(this.val)
    if(this.val === '' || this.val === ' '){
      this.list = [];
      return
    }
    this.filterSubject.next(this.val);
  }

  async getAvailableTeam(first: boolean, event: any) {
    this.utilsService.showLoading();
    const [_, response] = await to(
      lastValueFrom(
        this.teamService.availablePlayers(
          this.user.id,
          this.page,
          10,
          this.val,
          this.selectedListIds
        ),
      ),
    );
    if (response) {
      this.utilsService.dismissLoading();
      if (!first) {
        this.list = this.list.concat(response.data.paginated.data);
      } else {
        this.list = response.data.paginated.data;
      }
      this.filterData = this.list;
      this.totalPages = response.data.paginated.total_pages;
      this.page++;
      event?.target.complete();
    }
    if (_) {
      this.utilsService.dismissLoading();
    }
  }

  addToSelectedList(item) {
    const alreadyExists = this.selectedList.some((i) => i.id === item.id);
    if (alreadyExists) {
      return;
    }
    this.selectedList.push(item);
    console.log(this.selectedList)
    this.selectedListIds = this.selectedList.map(item => item.id);
    this.list = this.list.filter((i) => i.id !== item.id);
  }

  removeToSelectedList(item) {
    this.selectedList = this.selectedList.filter((i) => i.id !== item.id);
    this.selectedListIds = this.selectedList.map(item => item.id);
  }

  async sendInvitations() {
    const data = {
      creatorTeamId: this.user?.teams[0]?.id,
      tournamentTeams: this.selectedList,
      tournamentId: this.tournamentId,
    }
    if (data && this.selectedList.length > 0) {
      this.utilsService.showLoading();
      const tournamentTeams =data.tournamentTeams.map((i) => {
        return { team_id: i.id, tournament_id: data.tournamentId };
      });
      tournamentTeams.push({
        team_id: data.creatorTeamId,
        tournament_id: data.tournamentId,
      });
      const [err, response] = await to(
        lastValueFrom(
          this.tournamentService.createMultipleTournamentTeams(
            data.creatorTeamId,
            tournamentTeams,
          ),
        ),
      );
      if (response.message) {
        this.modalCtrl.dismiss();
      }
      if (err) {
        this.utilsService.showToast(err, "red");
      }
      this.utilsService.dismissLoading()
    } else {
      this.modalCtrl.dismiss();
    }
  }
}
