import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ToastDataService } from "src/app/services/toastData.service";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "app-notification-card",
  templateUrl: "./notification-card.component.html",
  styleUrls: ["./notification-card.component.scss"],
})
export class NotificationCardComponent implements OnInit {
  @Input() cardData: any;
  @Input() isToast = false;
  @Input() isDisabled = false;
  @Output() cardClick = new EventEmitter();
  @Output() onClose = new EventEmitter();
  toastId: number;
  constructor(
    private toastrService: ToastrService,
    private toastDataService: ToastDataService,
    private utilService: UtilsService,
  ) {}

  ngOnInit() {
    if (this.toastDataService.getData() !== undefined) {
      this.cardData = this.toastDataService.getData();
      this.isToast = this.toastDataService.getData().isToast;
      this.toastId = this.toastDataService.getData().toastId;
    }
  }

  onClick(): void {
    if (this.isDisabled) {
      return;
    }
    if (this.isToast) {
      this.onClear();
      this.utilService.goPage("notifications");
      return;
    }
    this.cardClick.emit();
  }

  onCloseClick(): void {
    if (this.isToast) {
      this.onClear();
      return;
    }
    this.onClose.emit();
  }

  onClear(): void {
    const toastsCopy = [...this.toastrService.toasts];
    toastsCopy.forEach((toast) => {
      this.toastrService.remove(toast.toastId);
    });
  }
}
