import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';

export type DeviceInfo = {
  info: any,
  uuid: any,
  lang: string
}

@Injectable({
  providedIn: 'root'
})
export class DeviceUtils {

  public DEVICE: DeviceInfo;

  constructor() {
  }

  async getDevice(){
    let info =  await Device.getInfo();
    let uuid =  await Device.getId();
    let lang =  await Device.getLanguageCode();

    this.DEVICE = {
      'info': info,
      'uuid': uuid,
      'lang': lang.value
    }

    return this.DEVICE
  }

}
