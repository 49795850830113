<ion-content>
  <ion-grid>
    <ion-row class="ion-justify-content-center ion-text-center">
      <ion-label>RESUMEN DE TEMPORADA</ion-label>
    </ion-row>
    <section class="team-streak-section">
      <ion-row class="ion-justify-content-center ion-text-center">
        <ion-col size="12">
          <ion-label color="primary" class="f-s-20">{{this.season?.tournamentTeams?.points}} </ion-label><ion-label color="primary">PTS</ion-label>
        </ion-col>
        <ion-col size="12" class="streak">
          <ion-row class="ion-justify-content-center">
            <ion-img class="image-streak" *ngFor="let match of this.playedMatches" [src]="getImagePath(match.state)"></ion-img>
          </ion-row>
        </ion-col>
      </ion-row>
    </section>
    <ion-row class="ion-justify-content-center ion-text-center">
      <ion-col size="12">
        <ion-label>Estás en</ion-label>
      </ion-col>
      <ion-col size="12" class="flex-container">
        <div class="triangle1"></div>
        <div class="stage">
          <ion-label>{{this.season?.tournamentTeams?.tournament?.name}}</ion-label>
        </div>
        <div class="triangle2"></div>
      </ion-col>
    </ion-row>
    <!-- <ion-row class="ion-justify-content-center ion-text-center ion-align-items-center ion-margin-top">
      <ion-icon src="assets/icon/coin.svg" class="icon-coin"></ion-icon>
      <ion-label color="primary" class="f-s-18">{{earnedCoins}}</ion-label>
    </ion-row> -->
    <ion-row class="ion-margin-top">
      <ion-col size="12">
        <ion-label>Mejores jugadores:</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-between row-best-players">
      <app-mini-card *ngFor="let player of bestPlayers" [card]="player"></app-mini-card>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-label>Mejor modelo de juego:</ion-label>
      </ion-col>
      <!-- TO DO: hacer de enserio esto de "mejor", hoy en dia solo muestra el último model que está en el team del user -->
      <ion-col size="4" class="game-style-col" *ngFor="let item of this.formationService.gameModels; index as i">
        <ion-card color="dark" class="game-style-card" [ngClass]="{'active-card': this.season.tournamentTeams?.teams?.current_styles_id == i+1}">
          <span class="ion-text-uppercase"> {{item.name}} </span>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-label>Mejor sistema de juego:</ion-label>
      </ion-col>
      <!-- TO DO: hacer de enserio esto de "mejor", hoy en dia solo muestra el último formation que está en el team del user -->
      <ion-col size="4" class="game-style-col" *ngFor="let item of this.formationService.gameSystems; index as i">
        <ion-card color="dark" class="game-style-card" [ngClass]="{'active-card': this.season.tournamentTeams?.teams?.current_formation_id == i+1}">
          <span class="ion-text-uppercase"> {{item.name}} </span>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>