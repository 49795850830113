<div class="card">
  <div class="content">
    <div class="info mt-35 ml-20">
      <label class="font-size-36">{{ card?.media }}</label>
      <img src="/assets/images/countries/ar.png" alt="country-flag.png">
      <img src="/assets/images/countries/ar.png" alt="country-flag.png">
    </div>
    <div class="profile-image">
      <img [src]="card?.profilepicture" alt="profile.png">
    </div>
  </div>
  <div class="footer">
    <label class="font-size-23">{{ card?.lastname | uppercase }}</label>
    <ion-grid class="skills">
      <ion-row>
        <ion-col data-skill="CA" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_counter_attack}}</span>
        </ion-col>
        <ion-col data-skill="DEF" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_defense }}</span>
        </ion-col>
        <ion-col data-skill="DRI" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_dribbling }}</span>
        </ion-col>
        <ion-col data-skill="OF" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_offensive }}</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col data-skill="PAS" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_passing }}</span>
        </ion-col>
        <ion-col data-skill="PER" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_performance_index }} </span>
        </ion-col>
        <ion-col data-skill="PRE" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_pressing }}</span>
        </ion-col>
        <ion-col data-skill="SPD" class="skill ion-justify-content-center d-flex" size="3">
          <span >{{ card?.technical_skill_speed }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>
</div>