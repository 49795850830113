<ion-header class="ion-no-margin ion-no-padding">
  <ion-toolbar class="ion-no-margin ion-no-padding">
    <ion-title class="white">{{ 'sponsor.title' | transloco}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid class="content ion-no-padding">
    <ion-row class="banner ion-no-margin ion-no-padding">
      <ion-col size="8">
        <h4 class="golden-text">{{ 'sponsor.mayor_reward' | transloco }}</h4>
        <h4 class="golden-text d-flex-middle gap-10">
          <img src="/assets/golden_coin.svg" alt="" width="30" height="30" />
          +500
        </h4>
      </ion-col>
      <ion-col class="d-flex-middle" size="4">
        <ion-img src="/assets/images/golden_cup.svg"></ion-img>
      </ion-col>
    </ion-row>

    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12" class="ion-padding">
        <ion-label>{{ 'sponsor.selectSponsor' | transloco}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <app-sponsor-card *ngFor="let s of sponsor" [sponsor]="s" [selected]="sponsorSelected?.id === s?.id"
        (click)="selectSponsor(s)"></app-sponsor-card>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <div class="ion-padding-horizontal mb-10">
      <ion-label class="white">{{ 'sponsor.renovateAfter' | transloco}}</ion-label>
    </div>
    <ion-button [disabled]="!sponsorSelected" (click)="confirm()" expand="block">
      Confirmar
    </ion-button>
  </ion-toolbar>
</ion-footer>