import { ICard } from "src/app/interfaces/ICard";
import { Injectable } from "@angular/core";
import { FormationsService } from "./formations.service";
import { TeamService } from "./team.service";
import { ISearchFilters } from "../interfaces/ISearchFilters";

@Injectable({
  providedIn: "root",
})
export class PlayerListService {
  private playerLists: ICard[];

  constructor(
    public formationsService: FormationsService,
    public teamService: TeamService,
  ) {
    //TODO:
    //buscar `this.playerLists` la primera vez para tenerlo en memoria
    //y no buscarlo en bd por cada keypressed
  }

  getReservePlayers(players: ICard[], activePlayers: number[]) {
    const reservePlayers = players.filter(
      (player) => !activePlayers.includes(player.id),
    );
    return reservePlayers;
  }

  getPlayersWithoutContract(players: ICard[]) {
    const filteredPlayers = players.filter(
      (player: ICard) =>
        !player.nrocontrato || Number(player.nrocontrato) === 0,
    );
    return filteredPlayers;
  }

  getActivePlayers() {
    const formation = this.formationsService.getActivePlayersWOPlaceholder();
    return formation;
  }

  filterItems(playerLists: ICard[], searchTerm: string) {
    return playerLists.filter(
      (item) =>
        JSON.stringify(item).trim().toLowerCase().indexOf(searchTerm) !== -1,
    );
  }

  filterPlayers(list: ICard[], filters: ISearchFilters) {
    if (list) {
      let result: ICard[] = list;
      result =
        filters.quality !== null
          ? result.filter(
              (item) => item.player.category.name === filters.quality.name,
            )
          : result;
      result =
        filters.team !== null
          ? result.filter((item) =>
              item.player.club.name.includes(filters.team),
            )
          : result;

      result =
        filters.position !== null
          ? result.filter((item) =>
              item.player.position.includes(filters.position),
            )
          : result;
      return result;
    }
    return [];
  }

}
