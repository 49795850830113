import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { QuickSellComponent } from '../quick-sell/quick-sell.component';

@Component({
  selector: 'app-maximum-capacity',
  templateUrl: './maximum-capacity.component.html',
  styleUrls: ['./maximum-capacity.component.scss'],
})
export class MaximumCapacityComponent implements OnInit {
  @Input() excess: number;
  @Input() allPlayers: number;


  constructor(
    private utilsService: UtilsService
  ) { }

  ngOnInit() {}

  sell(){
    this.utilsService.dismissModal();
    this.utilsService.showModal(
      QuickSellComponent,
      [''],
      {allPlayers: this.allPlayers, excess: this.excess}
    )
  }

}
