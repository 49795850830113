import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-contracts',
  templateUrl: './player-contracts.component.html',
  styleUrls: ['./player-contracts.component.scss'],
})
export class PlayerContractsComponent implements OnInit {
  @Input() matches;
  @Input() text: string = 'CONTRATO';
  @Input() urlImg: string = '/assets/contracts.png';
  @Input() matchesQuantity = 'PARTIDOS';

  constructor() {}

  ngOnInit() {}
}
