import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  active;

  constructor(private router: Router,
              private utils: UtilsService) {
    this.active = this.router.url;
  }

  ngOnInit() {
  }

  goPage(page) {
    this.utils.goPage(page);
  }

}
