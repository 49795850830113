import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { signInResolver } from "./utils/resolvers/sing-in.resolver";

const routes: Routes = [
  {
    path: "",
    redirectTo: "splash",
    pathMatch: "full",
  },
  {
    path: "splash",
    loadChildren: () =>
      import("./pages/auth/splash/splash.module").then(
        (m) => m.SplashPageModule,
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "sign-up",
    loadChildren: () =>
      import("./pages/auth/sign-up/sign-up.module").then(
        (m) => m.SignUpPageModule,
      ),
  },
  {
    path: "sign-in",
    loadChildren: () =>
      import("./pages/auth/sign-in/sign-in.module").then(
        (m) => m.SignInPageModule,
      ),
  },
  {
    path: "sign-in-google",
    loadChildren: () =>
      import("./pages/auth/sing-in-google/sing-in-google.module").then(
        (m) => m.SingInGoogleModule,
      ),
    resolve: {
      accessToken: signInResolver,
    },
  },
  {
    path: "confirm-email",
    loadChildren: () =>
      import("./pages/auth/confirm-email/confirm-email.module").then(
        (m) => m.ConfirmEmailPageModule,
      ),
  },
  {
    path: "card",
    loadChildren: () =>
      import("./pages/card/card.module").then((m) => m.CardPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "market",
    loadChildren: () =>
      import("./pages/market/market.module").then((m) => m.MarketPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "news",
    loadChildren: () =>
      import("./pages/news/news.module").then((m) => m.NewsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "team",
    loadChildren: () =>
      import("./pages/team/team.module").then((m) => m.TeamPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./pages/notifications/notifications.module").then(
        (m) => m.NotificationsPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "match",
    loadChildren: () =>
      import("./pages/match/match.module").then((m) => m.MatchPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "password-recovery",
    loadChildren: () =>
      import("./pages/auth/password-recovery/password-recovery.module").then(
        (m) => m.PasswordRecoveryPageModule,
      ),
  },
  {
    path: "wallet",
    loadChildren: () =>
      import("./pages/wallet/wallet.module").then((m) => m.WalletPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "confirm-match",
    loadChildren: () =>
      import("./pages/match/friendly/confirm-match/confirm-match.module").then(
        (m) => m.ConfirmMatchPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "socket",
    loadChildren: () =>
      import("./pages/socket/socket.module").then((m) => m.SocketPageModule),
    // canActivate: [AuthGuard], //se necesita acá?
  },
  {
    path: "training",
    loadChildren: () =>
      import("./pages/training/training.module").then((m) => m.TrainingModule),
    canActivate: [AuthGuard], //se necesita acá?
  },
  {
    path: "splash",
    loadChildren: () =>
      import("./pages/auth/splash/splash-routing.module").then(
        (m) => m.SplashPageRoutingModule,
      ),
    // canActivate: [AuthGuard], //se necesita acá?
  },
  {
    path: "sponsor-objectives",
    loadChildren: () =>
      import(
        "./pages/sponsor-objectives/sponsor-objectives.module"
      ).then((m) => m.SponsorObjetivesPageModule),
  },
  {
    path: 'sponsor-rewards/:reward',
    loadChildren: () => import('./pages/sponsor-rewards/sponsor-rewards.module').then( m => m.SponsorRewardsPageModule)
  },
  {
    path: 'ranking',
    loadChildren: () => import(
      './pages/ranking/ranking.module'
    ).then( m => m.RankingPageModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
