/* eslint-disable max-len */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import to from 'await-to-js';
import { log } from 'console';
import { lastValueFrom } from 'rxjs';
import { FileHandlerService } from 'src/app/services/file-handler.service';

@Component({
  selector: 'app-information-shield',
  templateUrl: './information-shield.component.html',
})
export class InformationShieldComponent implements OnChanges {
  @Input() primaryColor = '';
  @Input() secondaryColor = '';
  @Input() svg = '';
  @Input() name = '';
  @Input() containerClass: any = 'information-shield';
  @Input() size = 'big';

  @Input() keyFromS3 = '';
  @Input() height = 90;

  @Output() imagesCompleted = new EventEmitter();

  url: string;

  constructor(private fileHandlerService: FileHandlerService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.keyFromS3.currentValue) {
      this.getUrl();
    }
  }

  async getUrl() {
    const [_, resp] = await to(lastValueFrom(this.fileHandlerService.getSignedUrl(this.keyFromS3, 'image/svg+xml')));
    if(resp){
      this.url = resp['data']?.url;
    }
    this.imagesCompleted.emit();
  }

}
