import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  AnimationController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IParamsHtmlToast } from '../interfaces/IParamsHtmlToast';

export type ToastPosition = 'bottom' | 'middle' | 'top';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public isLoading = false;

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private readonly translocoService: TranslocoService,
    private animationCtrl: AnimationController
  ) { }

  /**
   * Buttons es un arreglo de objects
   * más info: https://ionicframework.com/docs/api/alert#usage
   */
  async showAlert(header, message, backdropDismiss?, buttons?) {

    backdropDismiss = backdropDismiss !== undefined ? backdropDismiss : true;

    const alert = await this.alertController.create({
      mode: 'ios',
      header,
      backdropDismiss: backdropDismiss,
      message,
    });
    if (buttons) {
      alert.buttons = [buttons];
    }
    await alert.present();
  }

  async showPrompt(
    header,
    message,
    acceptButton: string = 'OK',
    rejectButton: string = 'Cancel'
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const confirm = await this.alertController.create({
        mode: 'md',
        header,
        message,
        cssClass: 'alert',
        buttons: [
          {
            text: rejectButton,
            role: 'cancel',
            handler: () => resolve(false),
          },
          {
            text: acceptButton,
            handler: () => resolve(true),
          },
        ]
      });
      await confirm.present();
    });
  }

  async showModal(modalPage, cssClass = [''], componentProps?, backdropDismiss?) {
    const modal = await this.modalController.create({
      component: modalPage,
      componentProps,
      cssClass,
      backdropDismiss
    });

    await modal.present();
    return modal;
  }

  dismissModal(data?: any) {
    this.modalController.dismiss(data);
  }

  async showLoading(message?, dismiss?: boolean) {
    this.isLoading = true;
    this.spinner.show();
  }

  async dismissLoading() {
    this.isLoading = false;
    this.spinner.hide();
  }

  async showToast(
    pText,
    pColor = 'dark',
    duration = 5000,
    position?: ToastPosition
  ) {
    const toast = await this.toastController.create({
      position,
      message: pText,
      color: pColor,
      duration,
    });
    toast.present();
  }

  async showHtmlToast(text: string, title: string, params?: IParamsHtmlToast) {
    this.toastr.show(text, title, params);
  }

  async goPage(page, queryParams?: any) {
    this.router.navigate([`${page}`], { queryParams });
  }

  sortObjectArray(
    array: any[],
    descentdent: boolean,
    sortKey: string,
    childSortKey?: string
  ) {
    const order = descentdent ? -1 : 1;
    if (childSortKey) {
      return array.sort((a, b) =>
        a[sortKey][childSortKey] > b[sortKey][childSortKey]
          ? order
          : b[sortKey][childSortKey] > a[sortKey][childSortKey]
            ? -order
            : 0
      );
    }
    return array.sort((a, b) =>
      a[sortKey] > b[sortKey] ? order : b[sortKey] > a[sortKey] ? -order : 0
    );
  }

  randomHash(length: number, idToBind?: number, idAtEnd?: boolean) {
    const id = idToBind ? idToBind.toString() : undefined;
    const fixedLength = length + 2;
    let hash = Math.random().toString(fixedLength).substring(2, fixedLength);
    hash = id
      ? idAtEnd
        ? (hash + id).substring(0, fixedLength - id.length)
        : (id + hash).substring(0, fixedLength - id.length)
      : hash;
    return hash;
  }

  notAvailableToast() {
    this.showToast(
      'Función actualmente no está disponible',
      'warning',
      5000,
      'middle'
    );
  }

  async showModalSheet(
    modalComponent,
    params,
    initialBreakpoint: number = 0.5
  ) {
    const modal = await this.modalController.create({
      component: modalComponent,
      componentProps: {
        ...params,
      },
      breakpoints: [0, 0.3, 0.5, 0.8],
      initialBreakpoint,
    });
    await modal.present();
    return modal;
  }

  showHtmlMessage(message: string) {
    const params = {
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      enableHtml: true,
      borderColor: 'red',
    };
    this.spinner.hide();
    this.showHtmlToast(message, '', params);
  }

  async translateAndShowError(errorMessage) {
    this.translocoService
      .selectTranslate(errorMessage)
      .subscribe((response) => {
        this.showHtmlMessage(response);
      });
  }
}
