import { Injectable, signal, WritableSignal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "./global.service";

@Injectable({ providedIn: "root" })
export class TrainingService {
  urlApi: string;

  daysStatus: WritableSignal<any> = signal({});
  consecutiveDays: WritableSignal<number> = signal(0);
  fullTrainingRewards: WritableSignal<any[]> = signal([]);

  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
  ) {
    this.urlApi = this.globalService.URL_API;
  }

  getDailyRewardStatus(id: string) {
    return this.http.get<any>(`${this.urlApi}/reward/user/${id}/training`);
  }

  train(id: string, dayNumber: number, isTrainingRecovering: boolean) {
    return this.http.post<any>(`${this.urlApi}/reward/user/${id}/training`, {
      id,
      dayNumber,
      isTrainingRecovering,
    });
  }
}
