<ion-card class="card" [ngClass]="selected ? 'active' : ''">
  <ion-row
    class="card-header ion-no-margin ion-padding-horizontal"
    [ngStyle]="{ '--background-url': 'url(' + sponsor.backgroundUrl + ')' }"
  >
    <ion-img class="logo" [src]="sponsor.imageUrl"></ion-img>
  </ion-row>
  <ion-row class="ion-padding gap-8">
    <ion-col
      *ngFor="
        let sponsorObjectiveChallenges of sponsor.sponsorObjective[0]
          .sponsorObjectivesChallenges
      "
      class="benefit d-flex gap-10 ion-align-items-center text-white"
      size="12"
    >
      <ion-img class="logo" src="/assets/images/golden_check.svg"></ion-img>
      {{
        "sponsor.sponsor_benefit." +
          sponsorObjectiveChallenges?.challenge?.description
          | transloco
            : {
                quantity: sponsorObjectiveChallenges?.challenge?.targetQuantity
              }
      }}
    </ion-col>
  </ion-row>
  <ion-row
    class="card-footer ion-no-margin ion-padding-horizontal ion-justify-content-center"
  >
    <ion-label
      class="d-flex ion-justify-content-center ion-align-items-center font-size-16"
    >
      {{ "sponsor.win_more_daily_challenges" | transloco }}
    </ion-label>
  </ion-row>
</ion-card>
