import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

export const signInResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const code = route.queryParams['code'];
  if (code) return inject(UsersService).singInWithGoogle(code);
  
  return false;
};
