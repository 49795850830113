import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TrainingTeamModalComponent } from '../modals/training-team-modal/training-team-modal.component';
import { ModalController } from '@ionic/angular';
import { StreakTrackComponent } from '../streak-track/streak-track.component';
import { BellNotificationComponent } from '../bell-notification/bell-notification.component';
import { CoinsComponent } from '../coins/coins.component';
import { TrainingService } from 'src/app/services/training.service';

export type typeHeader = 'home' | 'sponsor&shield' | 'onlySponsor' | 'versus' | 'onlyBack';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss'],
})

export class HeadersComponent  implements OnInit {
  @Input() user: any;
  @Input({required: true}) typeHeader: typeHeader;

  @Input() title: string;
  @Input() fontSize: string = '18px';

  @Input() back: boolean = false;
  @Output() backButton: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(StreakTrackComponent) public streakTrackComponent: StreakTrackComponent;
  @Input() daysStatus: any;
  @Input() fullTrainingRewards: any;
  @Input() consecutiveDays: number;

  @Input() showButtons: boolean = true;

  @Input() tournamentSponsorUrl: string;
  @Input() arrowColor: string;
  
  @ViewChild(CoinsComponent) public coinsComponent: CoinsComponent;
  @ViewChild(BellNotificationComponent) public bellNotificationComponent: BellNotificationComponent;

  constructor(
    private modalCtrl: ModalController,
    private trainingService: TrainingService
  ) { }

  ngOnInit() {
    if(this.consecutiveDays){this.updateStreak();}
    if(this.trainingService?.consecutiveDays?.toString()){
      this.daysStatus = this.trainingService.daysStatus().valueOf();
      this.fullTrainingRewards = this.trainingService.fullTrainingRewards().valueOf();
      this.consecutiveDays = this.trainingService.consecutiveDays().valueOf();
    }
  }

  async handleOpenTrainingTeamModal() {
    const { days, hadTrainingToday, hasToRecoverTraining } = this.daysStatus;
    const modal = await this.modalCtrl.create({
      component: TrainingTeamModalComponent,
      componentProps: {
        isOpenByUser: false,
        showTrainingDays: false,
        consecutiveDays: 0,
        hasToRecoverTraining,
        days,
        hadTrainingToday,
        fullTrainingRewards: this.fullTrainingRewards,
      },
      breakpoints: [0, 0.75, 1],
      initialBreakpoint: 0.75,
    });
    await modal.present();

    return modal;
  }

  public updateStreak() {
    this.streakTrackComponent?.updateStreak(this.consecutiveDays);
    this.streakTrackComponent?.updateHasTrained(
      this.daysStatus?.hadTrainingToday,
    );
    if(this.daysStatus)this.trainingService.daysStatus?.set(this.daysStatus)
    if(this.consecutiveDays)this.trainingService.consecutiveDays?.set(this.consecutiveDays)
    if(this.fullTrainingRewards)this.trainingService.fullTrainingRewards?.set(this.fullTrainingRewards)
  }

  backClicked(){
    this.backButton.emit(true)
  }
}
