import { Component, OnInit } from '@angular/core';
import { BannersService } from 'src/app/services/banners.service';

@Component({
  selector: 'app-general-banner',
  templateUrl: './general-banner.component.html',
  styleUrls: ['./general-banner.component.scss'],
})
export class GeneralBannerComponent implements OnInit {
  generalBanners: any[];
  currentBannerIndex: number = 0;
  bannerTimer: any;
  fadeInOutClass: string = '';

  constructor(
    private bannersService: BannersService
  ) { }

  ngOnInit() {
    this.bannersService.getGeneralBanners().subscribe((banners) => {
      this.generalBanners = banners.data;
      this.randomOrderArray(this.generalBanners);
      this.startBannerRotation();
    });
  }

  startBannerRotation() {
    if (this.generalBanners && this.generalBanners.length > 0) {
      this.rotateBanner();
    }
  }

  rotateBanner() {
    const currentBanner = this.generalBanners[this.currentBannerIndex];
    const advertisementDuration = currentBanner.advertisementDuration * 1000 + 300;

    setTimeout(() => {
      this.fadeInOutClass = 'fade-in-out';
      setTimeout(() => {
        this.currentBannerIndex = (this.currentBannerIndex + 1) % this.generalBanners.length;
        this.fadeInOutClass = 'fade-in-out show';
        this.rotateBanner();
      }, 300);
    }, advertisementDuration);
  }

  randomOrderArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

}
