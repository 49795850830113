import { Component, OnInit } from '@angular/core';
import { ICard } from 'src/app/interfaces/ICard';
import { FormationsService } from 'src/app/services/formations.service';

@Component({
  selector: 'app-season-resume',
  templateUrl: './season-resume.component.html',
  styleUrls: ['./season-resume.component.scss'],
})
export class SeasonResumeComponent  implements OnInit {
  team;
  teamStreak;
  season;
  bestPlayers: ICard[];
  playedMatches;

  constructor(
    public formationService: FormationsService
  ) {
  }

  async ngOnInit() {
    await this.completeMatches()
    const sortedTeam = this.team.sort((a, b) => b.player?.technical_skill_performance_index - a.player?.technical_skill_performance_index);
    this.bestPlayers = sortedTeam.slice(0, 3);
    
  }

  async completeMatches() {
    this.playedMatches.sort((a, b) => a.id - b.id);
    while (this.playedMatches.length < 10) {
      this.playedMatches.push({ state: undefined });
    }
  }
  

  getImagePath(result: string): string {
    switch (result) {
      case 'G':
        return 'assets/icon/match-win-season.svg';
      case 'E':
        return 'assets/icon/match-tie-season.svg';
      case 'P':
        return 'assets/icon/match-lose-season.svg';
      default:
        return 'assets/icon/match-pending-season.svg';
    }
  }

}
