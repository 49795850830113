import { AbstractControl } from "@angular/forms";

export function PasswordValidator(control: AbstractControl) {
    const password = control.get('password');
    const password_confirm = control.get('password_confirm');

    if(!!password && !!password_confirm){
        if(password.value !== password_confirm.value){
            control.get('password_confirm').setErrors({ mismatch: true });
            return ({mismatch: true});
        }else{
            return null
        }
    }
}