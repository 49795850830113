import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { UtilsService } from './utils.service';
import { ISearchFilters } from '../interfaces/ISearchFilters';
import { IMarketProduct } from '../interfaces/IMarketProduct';
import { IApiGenericResponse } from '../interfaces/IApiResponse';
import { IPack } from '../interfaces/IPack';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  urlApi = this.globalService.URL_API;
  // Create the interfaces for these variables
  loadedProduct: any;
  buyedProduct: any;

  userCoins = 0;
  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
    public utilsService: UtilsService
  ) {
  }

  getProducts(filters?: ISearchFilters) {
    let query = new HttpParams();
    query =
      filters && filters.position
        ? query.append('position', filters.position)
        : query;
    query =
      filters && filters.team ? query.append('team', filters.team) : query;
    query =
      filters && filters.quality && filters.quality.id
        ? query.append('quality', filters.quality.id)
        : query;
    query =
      filters && filters.price && filters.price.lower >= 0
        ? query.append('priceFrom', filters.price.lower.toString())
        : query;
    query =
      filters && filters.price && filters.price.upper >= 0
        ? query.append('priceTo', filters.price.upper.toString())
        : query;
    return this.http.get<IApiGenericResponse<IMarketProduct>>(
      `${this.urlApi}/get-products`,
      { params: query }
    );
  }

  getTopSellingProducts(){
    return this.http.get<any>(`${this.urlApi}/products/top-selling`)
  }

  loadProduct(product: any) {
    this.loadedProduct = product;
  }

  loadBuyedProduct(product: any) {
    this.buyedProduct = product;
  }

  clearProduct() {
    this.loadProduct = undefined;
  }

  pay(pack: any, free: boolean = false) {
    const data = {
      packtemplate_id: pack.packtemplate_id,
      userto_id: pack.user_id,
      price: pack.price,
      quantity: 1,
      couponCode: pack.couponCode,
      id: pack.id ? pack.id : 5,
      userfrom_id: JSON.parse(localStorage.getItem('ksport')).user.id,
      isInitialPack: free,
      product_id: pack.product_id,
      card_id: pack.cardId,
      team_id: JSON.parse(localStorage.getItem('ksport')).user.teams[0].id
    };
    console.log(data)
    return this.http.post(`${this.urlApi}/buy-product`, JSON.stringify(data));
  }
}
