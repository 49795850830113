<div *ngIf="status === 'ready' && showActions; else pendingActions">
  <div *ngIf="receivedInvitation">
    <ion-row class="ion-align-items-center ion-no-margin padding-vertical">
      <ion-col size="12" class="general-performance-small">
        <ion-row class="ion-align-items-center">
          <ion-col size="2">
            <ion-row class="ion-align-items-center">
              <img src="assets/icon/timer.svg" alt="" width="19" height="20">
            </ion-row>
          </ion-col>
          <ion-col size="10">
            <h3><strong></strong>Es el turno de tu rival</h3>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </div>
  <div *ngIf="!receivedInvitation">
    <div *ngIf="playedByAway">
      <ion-row class="ion-align-items-center ion-no-margin padding-vertical">
        <ion-col size="12" class="general-performance-small">
          <ion-row class="ion-align-items-center">
            <ion-col size="2">
              <ion-row class="ion-align-items-center">
                <img src="assets/icon/timer.svg" alt="" width="19" height="20">
              </ion-row>
            </ion-col>
            <ion-col size="10">
              <h3><strong></strong>¡Tu turno!</h3>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center ion-no-margin">
        <ion-col size="12" class="general-performance-small">
          <ion-button size="small" color="dark" expand="block" fill="solid" class="button"
                      (click)="acceptInvitation.emit(status)">
            Jugar partido
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="!playedByAway">
      <ion-row class="ion-align-items-center ion-no-margin padding-vertical">
        <ion-col size="12" class="general-performance-small">
          <ion-row class="ion-align-items-center">
            <ion-col size="2">
              <ion-row class="ion-align-items-center">
                <img src="assets/icon/timer.svg" alt="" width="19" height="20">
              </ion-row>
            </ion-col>
            <ion-col size="10">
              <h3><strong>Pendiente de aceptación</strong></h3>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center ion-no-margin">
        <ion-col size="12" class="general-performance-small">
          <ion-button size="small" color="dark" expand="block" fill="solid" class="button" disabled>
            Jugar partido
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>

<ng-template #pendingActions>
  <div *ngIf="status === 'pending'; else playedActions">
    <div *ngIf="!showActions">
      <ion-row class="ion-align-items-center ion-no-margin padding-vertical">
        <ion-col size="12" class="general-performance-small">
          <ion-row class="ion-align-items-center">
            <ion-col size="2">
              <ion-row class="ion-align-items-center">
                <img src="assets/icon/timer.svg" alt="" width="19" height="20">
              </ion-row>
            </ion-col>
            <ion-col size="10">
              <h3><strong>Pendiente de aceptación</strong></h3>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center ion-no-margin">
        <ion-col size="12" class="general-performance-small">
          <ion-button size="small" color="dark" expand="block" fill="solid" class="button" disabled>
            Jugar partido
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="showActions">
      <ion-row class="ion-align-items-center ion-no-margin">
        <ion-col size="6" class="general-performance-small">
          <ion-button size="small" color="dark" expand="block" fill="solid" class="button"
                      (click)="acceptInvitation.emit(status)">
            Aceptar
          </ion-button>
        </ion-col>
        <ion-col size="6" class="general-performance-small">
          <ion-button size="small" color="dark" expand="block" fill="solid" class="button"
                      (click)="declineInvitation.emit(status)">
            Rechazar
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ng-template>

<ng-template #playedActions>
  <div *ngIf="status === 'played' && showActions; else rejectedActions">
    <ion-row class="ion-align-items-center ion-no-margin padding-vertical">
      <ion-col size="12" class="general-performance-small">
        <ion-row class="ion-align-items-center">
          <ion-col size="2">
            <ion-row class="ion-align-items-center" (click)="reliveInvitation.emit(status)">
              <img [src]="'assets/icon/match-' + playedMatch.result + '-played.svg'" alt="" width="19" height="20">
            </ion-row>
          </ion-col>
          <ion-col size="10">
            <h3><strong>{{ resultToShow }}</strong></h3>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center ion-no-margin">
      <ion-col size="6" class="general-performance-small">
        <ion-row class="ion-align-items-center">
          <ion-col size="12">
            <ion-button size="small" color="dark" expand="block" fill="solid" class="button"
              [innerHTML]="playedMatch.result === 'win' ? 'Volver a desafiar' : 'Revancha'" (click)="sendNewInvitation()">
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col size="6">
        <ion-button size="small" color="dark" expand="block" fill="solid" class="button"
                    (click)="reliveInvitation.emit(status)">
          Revivir partido
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ng-template>

<ng-template #rejectedActions>
  <div *ngIf="status === 'rejected' && showActions; else otherActions">
    <ion-row class="ion-align-items-center ion-no-margin padding-vertical">
      <ion-col size="12" class="general-performance-small">
        <ion-row class="ion-align-items-center">
          <ion-col size="2">
            <ion-row class="ion-align-items-center">
              <img src="assets/icon/cancel.svg" alt="" width="19" height="20">
            </ion-row>
          </ion-col>
          <ion-col size="10">
            <h3><strong>¡Haz rechazado la invitación!</strong></h3>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </div>
</ng-template>

<ng-template #otherActions>
  <div *ngIf="!status && showActions">
    <ion-row class="ion-align-items-center ion-no-margin">
      <ion-col size="12" class="general-performance-small">
        <ion-button size="small" color="dark" expand="block" fill="solid" class="button"
                    (click)="acceptInvitation.emit(status)">
          Enviar invitación
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ng-template>
