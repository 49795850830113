<svg *ngIf='size === "small" && !keyFromS3' width="35px" height="35px" viewBox="0 0 90 90" version="1.1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink" class="{{containerClass}}">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
          <path [attr.d]="svg" [attr.fill]="primaryColor"></path>
      </g>
      <text x="45" y="55" id="A-Copy-4" font-family="Formula1-Display-Bold, Formula1" font-size="38" font-weight="600" [attr.fill]="secondaryColor">
          <tspan text-anchor="middle">{{ name?.charAt(0) | uppercase }}</tspan>
      </text>
  </g>
</svg>
<svg *ngIf='size === "big" && !keyFromS3' width="90px" height="90px" viewBox="0 0 90 90" version="1.1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink" class="{{containerClass}}">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
          <path [attr.d]="svg" [attr.fill]="primaryColor"></path>
      </g>
      <text x="45" y="55" id="A-Copy-4" font-family="Formula1-Display-Bold, Formula1" font-size="38" font-weight="600" [attr.fill]="secondaryColor">
          <tspan text-anchor="middle">{{ name?.charAt(0) | uppercase }}</tspan>
      </text>
  </g>
</svg>

<img [src]="url" alt="" [height]="height">