import { Component, Input, OnInit } from "@angular/core";
import { ProductsService } from "src/app/services/products.service";
import { UtilsService } from "src/app/services/utils.service";
import { RevealedPackComponent } from "../../revealed-pack/revealed-pack.component";
import { IRangeValue } from "src/app/interfaces/IRangeValue";

@Component({
  selector: "app-starter-cards-modal",
  templateUrl: "./starter-cards-modal.component.html",
  styleUrls: ["./starter-cards-modal.component.scss"],
})
export class StarterCardsModalComponent implements OnInit {
  @Input() isChallenge?: boolean = false;

  elementsOpacity = 0;
  elementsOpacityInverted = 1;
  elementRotateDeg = 0;
  isOpen: boolean;
  firstCard: any;
  product: any;

  constructor(
    private utilsServices: UtilsService,
    private productServices: ProductsService,
  ) {}

  ngOnInit() {}

  dismiss() {
    this.utilsServices.dismissModal();
  }

  async openPack() {
    this.utilsServices.showLoading();
    const user_id = await JSON.parse(localStorage.getItem("ksport")).user.id;
    this.productServices
      .pay({ packtemplate_id: 5, user_id, price: 0 }, true)
      .subscribe(
        async (res: any) => {
          this.utilsServices.dismissLoading();
          this.dismiss();
          const cards = await res.data.packs_details;
          const ksport = JSON.parse(localStorage.getItem("ksport"));
          ksport.user.packs = cards;
          localStorage.setItem("ksport", JSON.stringify(ksport));
          console.log("StarterCardsModalComponent", this.isChallenge);
          this.utilsServices.showModal(RevealedPackComponent, [""], {
            isShop: false,
            cards,
            isChallenge: this.isChallenge,
          });
        },
        (error) => {
          this.dismiss();
          this.utilsServices.dismissLoading();
        },
      );
  }

  changeOpacity(event: IRangeValue) {
    // we divide by 10 because the opacity ranges from 0 to 1
    this.elementsOpacity = event.value / 10;
    this.elementsOpacityInverted = event.invertedValue / 10;
    this.elementRotateDeg = event.value;
  }
}
