<ion-content>
    <ion-grid class="container">
        <ion-row class="ion-margin-top">
            <h5>{{label}}</h5>
        </ion-row>
        <ion-row class="options ion-justify-content-center ion-align-items-center ion-margin-top">
            <ion-col (click)="selectOption(item)" *ngFor="let item of options" size="4" [ngClass]="item.id === optionSelected ? 'selected' : ''">
                <img [src]="item.url" alt="logoImg">
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>