import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAvailableTeam } from '../../interfaces/IAvailableTeam';
import { UsersService } from '../../services/users.service';
import { IStreakCount } from 'src/app/interfaces/IStreakCount';
import { IStreak } from 'src/app/interfaces/IStreak';

@Component({
  selector: 'app-available-player',
  templateUrl: './available-player.component.html',
  styleUrls: ['./available-player.component.scss'],
})
export class AvailablePlayerComponent implements OnInit {
  @Input() availableTeam: IAvailableTeam;
  @Input() status: string;
  @Input() yourTurn: string;
  @Input() against = false;
  @Input() showActions = true;
  @Input() receivedInvitation: boolean;
  @Input() playedByAway: boolean;
  @Input() teamStreak: IStreak[];
  @Input() playedMatch;
  @Input() teamStreakCount: IStreakCount;
  @Input() background: any;
  @Output() acceptInvitation = new EventEmitter<any>();
  @Output() declineInvitation = new EventEmitter<any>();
  @Output() reliveInvitation = new EventEmitter<any>();
  user: any = null;

  constructor(
    private userService: UsersService
  ) { }

  get wonMatches() {
    const wonMatches = this.availableTeam?.users?.user_detail?.won;
    return wonMatches ? wonMatches : 0;
  }

  ngOnInit() {
    this.user = this.userService.getLoggedUser();
  }

  getImagePath(result: string): string {
    switch (result) {
      case 'win':
        return 'assets/icon/match-win.svg';
      case 'tie':
        return 'assets/icon/match-tie.svg';
      case 'lose':
        return 'assets/icon/match-lose.svg';
      case 'first-pending':
        return 'assets/icon/match-first-pending.svg';
      case 'pending':
        return 'assets/icon/match-pending.svg';
      default:
        return '';
    }
  }

}
