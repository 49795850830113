import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
import { PackService } from 'src/app/services/pack.service';

@Component({
  selector: 'app-modal-buy-confirmation',
  templateUrl: './modal-buy-confirmation.component.html',
  styleUrls: ['./modal-buy-confirmation.component.scss'],
})
export class ModalBuyConfirmationComponent {

  @ViewChild('discountCodeInput', { static: false }) discountCodeInput: IonInput;
  @Input() productName: string;
  @Input() productDescription: string;
  collapse: boolean;

  discountCode: FormControl = new FormControl('');

  constructor(private modalController: ModalController, private packService: PackService) { }

  async buy() {
    if(this.discountCodeInput?.value){
      this.getValidCoupon(this.discountCodeInput.value.toString());
    }else{
      this.modalController.dismiss({ confirm: true, couponCode: '' });
    }
  }

  close() {
    this.modalController.dismiss(false);
  }

  cleanError() {
    this.discountCode.setErrors(null);
  }

  async getValidCoupon(discountCode: string) {
    const [_, res] = await to<any>(
      lastValueFrom(this.packService.isValidCoupon(this.discountCodeInput.value.toString()))
    );
    if (res?.data?.id) {
      this.modalController.dismiss({ confirm: true, couponCode: this.discountCodeInput.value });
    } else {
      this.discountCode.setErrors({
        invalid: true
      });
      return false;
    }
  }

}
