import {Component, Input, OnInit} from '@angular/core';
import {ICard} from '../../interfaces/ICard';

@Component({
  selector: 'app-player-card-list',
  templateUrl: './player-card-list.component.html',
  styleUrls: ['./player-card-list.component.scss'],
})
export class PlayerCardListComponent implements OnInit {
  @Input() playerList: ICard[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}
