<app-headers
  [typeHeader]="'onlyBack'"
  [back]="true"
  (backButton)="dismiss()"
></app-headers>
<ion-content>
  <ion-grid class="container">
    <ion-row class="ion-justify-content-center ion-align-items-center ion-margin ion-padding">
      <ion-col size="12" class="ion-text-center ion-margin ion-padding">
        <app-svg-image [logoUrl]="newSelectedLogo" [desingUrl]="newSelectedDesing?.url"
          [desingSvg]="newSelectedDesing.svg(primaryColor, secondaryColor)"
          [label]="name.value.slice(0, 1)" [colorTexto]="wordColor"
          (svgImage)="logoBase64 = $event"></app-svg-image>
      </ion-col>
    </ion-row>

    <ion-row class="fields-container ion-justify-content-center ion-align-items-center">
      <ion-col size="6">
        <div position="stacked">Escudo:</div>
        <div class="field" (click)="changeLogo()">
          <img [src]="newSelectedLogo?.url" alt="">
        </div>
      </ion-col>
      <ion-col size="6">
        <div position="stacked">Estampa:</div>
        <div class="field" (click)="changeDesing()">
          <img [src]="newSelectedDesing?.url" alt="">
        </div>
      </ion-col>
      <ion-col size="6">
        <div position="stacked">Color 01:</div>
        <div class="field">
          <input [(colorPicker)]="primaryColor" [style.background]="primaryColor" />
        </div>
      </ion-col>
      <ion-col size="6">
        <div position="stacked">Color 02:</div>
        <div class="field">
          <input [(colorPicker)]="secondaryColor" [style.background]="secondaryColor" />
        </div>
      </ion-col>
      <ion-col size="12">
        <div position="stacked">Color letra:</div>
        <div class="field">
          <input [(colorPicker)]="wordColor" [style.background]="wordColor" />
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="footer">
      <ion-col size="12">
        <ion-button [disabled]="name.invalid" (click)="createShield()" expand="block">
          Confirmar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<!-- <app-fixed-action>
  <ion-col size="12">
    <ion-button [disabled]="name.invalid" (click)="createShield()" expand="block">
      Confirmar
    </ion-button>
  </ion-col>
</app-fixed-action> -->