import { Component, Input, OnInit } from '@angular/core';
import to from 'await-to-js';
import { IApiGenericResponse } from 'src/app/interfaces/IApiResponse';
import { IFormationInfo } from 'src/app/interfaces/IFormationInfo';
import { IUser } from 'src/app/interfaces/IUser';
import { FormationsService } from 'src/app/services/formations.service';
import { TeamService } from 'src/app/services/team.service';
import { UsersService } from 'src/app/services/users.service';
import { ISeason } from '../../interfaces/ISeason';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-season-summary',
  templateUrl: './season-summary.component.html',
  styleUrls: ['./season-summary.component.scss'],
})
export class SeasonSummaryComponent implements OnInit {
  @Input() season: ISeason;
  @Input() matches: any[] = [];
  activePlayers: any[];
  user: IUser;

  constructor(
    private teamService: TeamService,
    public formationsService: FormationsService,
    private usersService: UsersService
  ) { }

  async ngOnInit() {
    this.user = this.usersService.getLoggedUser();
    await this.loadActivePlayers();
  }

  async loadActivePlayers() {
    const [error, response] = await to<IApiGenericResponse<IFormationInfo>>(lastValueFrom(
      this.teamService.getTeamInformation(this.user.teams[0].id)));
    if (response) {
      this.activePlayers = response.data.formations[0].lineUp;
    }
  }
}
