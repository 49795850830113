<div class="wrapper">
  <h5 class="title">Ingresa tu código de descuento</h5>
  <div class="input-container">
    <label>Código de descuento</label>
    <ion-input class="input-item" [ngClass]="{'error': hasError}" placeholder="Código de descuento" type="text"
      (ionChange)='onInputTime($event)'></ion-input>
    <div class="error" *ngIf="hasError">
      <img src="assets/emergency_home_red.svg" alt="emergency_home_red.svg" />
      <p>Este código caducó o es inválido</p>
    </div>
  </div>
  <div class="button-container">
    <ion-button class="button" expand="block" [disabled]="inputValue.length <= 0" (click)="onSubmit()">
      Aplicar código
    </ion-button>
    <ion-button
      class="button ion-color ion-color-light ios button-block button-small button-outline ion-activatable ion-focusable hydrated"
      expand="block" fill="outline" (click)="onCancel()">
      Cancelar
    </ion-button>
  </div>
</div>