import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-sell-attention-modal',
  templateUrl: './sell-attention-modal.component.html',
  styleUrls: ['./sell-attention-modal.component.scss'],
})
export class SellAttentionModalComponent implements OnInit {
  @Input() totalToSell: number;

  constructor(private modal: ModalController, private userService: UsersService) { }

  ngOnInit() {}

  close(sell: boolean){
    this.modal.dismiss(sell);
  }
}
