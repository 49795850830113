import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-svg-image',
  templateUrl: './svg-image.component.html',
  styleUrls: ['./svg-image.component.scss']
})
export class SvgImageComponent implements OnInit, OnChanges {

  @Input() logoUrl: any;
  @Input() desingUrl: string;
  @Input() label: string;
  @Input() colorTexto: string;
  @Input() desingSvg: any;

  @Output() svgImage: EventEmitter<any> = new EventEmitter();

  animate = true;

  ngOnInit() {
    setInterval(() => {
      this.animate = !this.animate;
    }, 4000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getSvg();
  }


  async getSvg() {

    const designSvg = this.resize(this.desingSvg);
    const maskSvg = this.resize(this.logoUrl?.svg);

    const container = document.getElementById('designContainer');

    this.clearContainer(container);
    // La mascara ya no viene de la url sino que es otro svg
    const maskElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    maskElement.innerHTML = maskSvg;
    const mask = document.getElementById('imageMask');
    mask.appendChild(maskElement);

    const textLogo = `<text x="100" y="100" font-size="72" font-family="Formula1-Display-Bold, Formula1" text-anchor="middle"
    alignment-baseline="middle">${this.label}</text>`;
    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    textElement.setAttribute('fill', this.colorTexto);
    textElement.innerHTML = textLogo;

    // Crear elemento SVG para el diseño
    const designElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    designElement.innerHTML = designSvg;

    // Aplicar la máscara al diseño
    designElement.setAttribute('mask', 'url(#imageMask)');

    // Agregar el diseño al contenedor SVG
    container.appendChild(designElement);
    container.appendChild(textElement);

    this.svgImage.emit(this.japaneseVersionImageConverter(container));

  }

  emitResult(result) {
    this.svgImage.emit(result);
  }


  resize(originalSvg) {
    const targetSize = 200;

    const container = document.createElement('div');
    container.innerHTML = originalSvg;

    const svgElement = container.firstChild as SVGElement;
    svgElement.setAttribute('width', targetSize.toString());
    svgElement.setAttribute('height', targetSize.toString());

    const serializer = new XMLSerializer();
    const resizedSvg = serializer.serializeToString(svgElement);

    return resizedSvg;
  }


  clearContainer(container) {
    const svgElements = container.querySelectorAll('svg');
    // Eliminar los elementos <svg> uno por uno
    svgElements.forEach((svgElement) => {
      svgElement.remove();
    });
  }

  japaneseVersionImageConverter(input) {
    // Uwu
    // const output = document.querySelector('#output') as HTMLImageElement;

    const svgData = new XMLSerializer().serializeToString(input);
    const svgDataBase64 = btoa(unescape(encodeURIComponent(svgData)));
    const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`;

    const image = new Image();

    image.addEventListener('load', () => {
      const width = input.getAttribute('width');
      const height = input.getAttribute('height');
      const canvas = document.createElement('canvas');

      canvas.setAttribute('width', width);
      canvas.setAttribute('height', height);

      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, width, height);

      // const dataUrl = canvas.toDataURL('image/png');
      // output.src = dataUrl;
    });

    image.src = svgDataUrl;
    return svgDataUrl;
  }


}
