import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { DatePipe } from '@angular/common';
import { TranslocoRootModule } from './transloco-root.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageInterceptor } from './core/interceptors/message.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { HtmlToastComponent } from './components/html-toast/html-toast.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
// import { SpinnerInterceptor } from './core/interceptors/spinner.interceptor';

// Note we need a separate function as it's required
// by the AOT compiler.
export const playerFactory = () => player;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    TranslocoRootModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ toastComponent: HtmlToastComponent }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: MessageInterceptor, multi: true },
    DatePipe,
    SocialSharing
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

declare global {
  interface Window {
    checkoutProController?: any;
  }
}
