/* eslint-disable @typescript-eslint/quotes */
import { PriceTagComponent } from "./price-tag/price-tag.component";
import { InformationShieldComponent } from "./information-shield/information-shield.component";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { PlayerListComponent } from "./player-list/player-list.component";
import { IonicModule } from "@ionic/angular";
import { ShieldPickerComponent } from "./shield-picker/shield-picker.component";
import { CoinsComponent } from "./coins/coins.component";
import { PositionsTableComponent } from "./positions-table/positions-table.component";
import { MiniCardComponent } from "./mini-card/mini-card.component";
import { RevealedPackComponent } from "./revealed-pack/revealed-pack.component";
import { BronzeCardComponent } from "./mini-card/bronze-card/bronze-card.component";
import { SilverCardComponent } from "./mini-card/silver-card/silver-card.component";
import { GoldenCardComponent } from "./mini-card/golden-card/golden-card.component";
import { WalletModalComponent } from "./modals/wallet-modal/wallet-modal.component";
import { SharedModule } from "../shared/shared.module";
import { PlayerCardListComponent } from "./player-card-list/player-card-list.component";
import { StarterCardsModalComponent } from "./modals/starter-cards-modal/starter-cards-modal.component";
import { RedirectModalComponent } from "./modals/redirect-modal/redirect-modal.component";
import { PaymentErrorModalComponent } from "./modals/payment-error-modal/payment-error-modal.component";
import { PaymentSuccessModalComponent } from "./modals/payment-success-modal/payment-success-modal.component";
import { PerformanceAndChemistryComponent } from "./performance-and-chemestry/performance-and-chemistry.component";
import { AvailablePlayerComponent } from "./available-player/available-player.component";
import { TeamInformationComponent } from "./team-information/team-information.component";
import { WinLoseMatchComponent } from "./win-lose-match/win-lose-match.component";
import { ConfirmSlideComponent } from "./confirm-slide/confirm-slide.component";
import { StatusMatchComponent } from "./status-match/status-match.component";
import { CardSeasonStateComponent } from "./card-season-state/card-season-state.component";
import { SeasonCardComponent } from "./season-card/season-card.component";
import { StepperComponent } from "./stepper/stepper.component";
import { SeasonSummaryComponent } from "./season-summary/season-summary.component";
import { SeasonMatchStateComponent } from "./season-match-state/season-match-state.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { HtmlToastComponent } from "./html-toast/html-toast.component";
import { PlayerPickerComponent } from "./player-picker/player-picker.component";
import { PlayerContractsComponent } from "./player-contracts/player-contracts.component";
import { BuyContractPlayerModalComponent } from "./modals/buy-contract-player-modal/buy-contract-player-modal.component";
import { QrModalComponent } from "./modals/qr-modal/qr-modal.component";
import { FixedActionComponent } from "./fixed-action/fixed-action.component";
import { BellNotificationComponent } from "./bell-notification/bell-notification.component";
import { SeassonEndComponent } from "./seasson-end/seasson-end.component";
import { TeamCreatedModalComponent } from "./modals/team-created-modal/team-created-modal.component";
import { MaximumCapacityComponent } from "./maximum-capacity/maximum-capacity.component";
import { QuickSellComponent } from "./quick-sell/quick-sell.component";
import { SellAttentionModalComponent } from "./modals/sell-attention-modal/sell-attention-modal.component";

import { VideoPlayerComponent } from "./video-player/video-player.component";
import { ShieldComponent } from "./shield/shield.component";
import { SheetSelectorComponent } from "./sheet-selector/sheet-selector.component";
import { ColorPickerModule } from "ngx-color-picker";
import { SvgImageComponent } from "./svg-image/svg-image.component";
import { GeneralBannerComponent } from "./general-banner/general-banner.component";
import { DiscountModalComponent } from "./modals/discount-modal/discount-modal.component";
import { EmailAlertModalComponent } from "./email-alert-modal/email-alert-modal.component";
import { SponsorCardComponent } from "./sponsor-card/sponsor-card.component";
import { SponsorModalComponent } from "./modals/sponsor-modal/sponsor-modal.component";
import { TranslocoModule } from "@ngneat/transloco";
import { NoticiaCardComponent } from "./noticia-card/noticia-card.component";
import { TrainingTeamModalComponent } from "./modals/training-team-modal/training-team-modal.component";
import { HightlightSkillComponent } from "./hightlight-skill/hightlight-skill.component";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { StreakTrackComponent } from "./streak-track/streak-track.component";
import { NotificationCardComponent } from "./notification-card/notification-card.component";
import { MediumCardComponent } from "./medium-card/medium-card.component";
import { CoinPackComponent } from "./coin-pack/coin-pack.component";
import { ModalBuyConfirmationComponent } from "./modals/modal-buy-confirmation/modal-buy-confirmation.component";
import { CheckMatchConditionsComponent } from "./check-match-conditions/check-match-conditions.component";
import { ObjectiveCardComponent } from "./objective-card/objective-card.component";
import { GameSystemAndModelComponent } from "./game-system-and-model/game-system-and-model.component";
import { InviteFriendsComponent } from "./invite-friends/invite-friends.component";
import { SeasonResumeComponent } from "./season-resume/season-resume.component";
import { HeadersComponent } from "./headers/headers.component";
import { SocialSharingModalComponent } from "./modals/social-sharing-modal/social-sharing-modal.component";

@NgModule({
  declarations: [
    AvailablePlayerComponent,
    BellNotificationComponent,
    BronzeCardComponent,
    BuyContractPlayerModalComponent,
    CardSeasonStateComponent,
    CoinsComponent,
    ConfirmSlideComponent,
    FixedActionComponent,
    GoldenCardComponent,
    HtmlToastComponent,
    InformationShieldComponent,
    LoadingSpinnerComponent,
    MiniCardComponent,
    NavBarComponent,
    PaymentErrorModalComponent,
    PaymentSuccessModalComponent,
    PerformanceAndChemistryComponent,
    PlayerCardListComponent,
    PlayerContractsComponent,
    PlayerListComponent,
    PlayerPickerComponent,
    PositionsTableComponent,
    PriceTagComponent,
    QrModalComponent,
    RedirectModalComponent,
    RevealedPackComponent,
    SeasonCardComponent,
    SeasonMatchStateComponent,
    SeasonSummaryComponent,
    SeassonEndComponent,
    ShieldPickerComponent,
    SilverCardComponent,
    StarterCardsModalComponent,
    StepperComponent,
    TeamCreatedModalComponent,
    TeamInformationComponent,
    VideoPlayerComponent,
    WalletModalComponent,
    WinLoseMatchComponent,
    ConfirmSlideComponent,
    StatusMatchComponent,
    CardSeasonStateComponent,
    SeasonCardComponent,
    StepperComponent,
    SeasonSummaryComponent,
    SeasonMatchStateComponent,
    LoadingSpinnerComponent,
    HtmlToastComponent,
    QrModalComponent,
    FixedActionComponent,
    BellNotificationComponent,
    TeamCreatedModalComponent,
    MaximumCapacityComponent,
    QuickSellComponent,
    SellAttentionModalComponent,
    ShieldComponent,
    SheetSelectorComponent,
    SvgImageComponent,
    GeneralBannerComponent,
    DiscountModalComponent,
    EmailAlertModalComponent,
    SponsorCardComponent,
    SponsorModalComponent,
    NoticiaCardComponent,
    TrainingTeamModalComponent,
    HightlightSkillComponent,
    TooltipComponent,
    StreakTrackComponent,
    NotificationCardComponent,
    MediumCardComponent,
    CoinPackComponent,
    ModalBuyConfirmationComponent,
    ObjectiveCardComponent,
    CheckMatchConditionsComponent,
    GameSystemAndModelComponent,
    InviteFriendsComponent,
    SeasonResumeComponent,
    HeadersComponent,
    SocialSharingModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    ColorPickerModule,
    TranslocoModule,
  ],
  exports: [
    AvailablePlayerComponent,
    BellNotificationComponent,
    BronzeCardComponent,
    CardSeasonStateComponent,
    CoinsComponent,
    ConfirmSlideComponent,
    FixedActionComponent,
    GoldenCardComponent,
    HtmlToastComponent,
    InformationShieldComponent,
    LoadingSpinnerComponent,
    MiniCardComponent,
    NavBarComponent,
    PaymentErrorModalComponent,
    PaymentSuccessModalComponent,
    PerformanceAndChemistryComponent,
    PlayerCardListComponent,
    PlayerContractsComponent,
    PlayerListComponent,
    PlayerPickerComponent,
    PositionsTableComponent,
    PriceTagComponent,
    QrModalComponent,
    RedirectModalComponent,
    RevealedPackComponent,
    SeasonCardComponent,
    SeasonMatchStateComponent,
    SeasonSummaryComponent,
    SeassonEndComponent,
    ShieldPickerComponent,
    SilverCardComponent,
    StarterCardsModalComponent,
    StatusMatchComponent,
    StepperComponent,
    TeamCreatedModalComponent,
    TeamInformationComponent,
    VideoPlayerComponent,
    WinLoseMatchComponent,
    ConfirmSlideComponent,
    CardSeasonStateComponent,
    SeasonCardComponent,
    StepperComponent,
    SeasonSummaryComponent,
    SeasonMatchStateComponent,
    LoadingSpinnerComponent,
    HtmlToastComponent,
    BuyContractPlayerModalComponent,
    QrModalComponent,
    FixedActionComponent,
    BellNotificationComponent,
    TeamCreatedModalComponent,
    MaximumCapacityComponent,
    QuickSellComponent,
    SellAttentionModalComponent,
    ShieldComponent,
    SheetSelectorComponent,
    SvgImageComponent,
    GeneralBannerComponent,
    DiscountModalComponent,
    EmailAlertModalComponent,
    SponsorCardComponent,
    SponsorModalComponent,
    NoticiaCardComponent,
    TrainingTeamModalComponent,
    HightlightSkillComponent,
    TooltipComponent,
    StreakTrackComponent,
    NotificationCardComponent,
    MediumCardComponent,
    CoinPackComponent,
    ModalBuyConfirmationComponent,
    ObjectiveCardComponent,
    CheckMatchConditionsComponent,
    GameSystemAndModelComponent,
    InviteFriendsComponent,
    SeasonResumeComponent,
    HeadersComponent,
    SocialSharingModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
