<div class="player-contracts">
  <div class="container">
    <!-- Bandera nacionalidad y equipo -->
    <div class="flex-info">
      <h2>{{ matches }}</h2>
      <p>{{ matchesQuantity }}</p>
    </div>
    <!-- Name and number of contracts -->
    <div class="footer">
      <strong class="title">{{ text }}</strong>
    </div>
  </div>

  <img class="card-type card-bronze" [src]="urlImg" alt="card-bronze.png" />
</div>
