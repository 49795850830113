import { FormControl } from '@angular/forms';

export interface ValidationResult {
    [key: string]: boolean;
}

export class SecurePasswordValidator {

    public static strong(control: FormControl): ValidationResult {
        const hasNumber = /\d/.test(control.value);
        const hasUpper = /[A-Z]/.test(control.value);
        const hasLower = /[a-z]/.test(control.value);
        const valid = hasNumber && hasUpper && hasLower;

        // return { hasNumber, hasUpper, hasLower };
        if (!valid) {
            return { hasNumber, hasUpper, hasLower };
        }
        return null;
    }
}
