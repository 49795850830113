import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-tag',
  templateUrl: './price-tag.component.html',
  styleUrls: ['./price-tag.component.scss'],
})

export class PriceTagComponent implements OnInit {

  @Input() price: number;
  @Input() color: boolean = false;
  @Input() error: boolean = false;

  constructor() {
    this.getColor();
  }

  ngOnInit() {}

  getColor(){
    return !this.color ?  "primary" : "primary-black"
  }
}
