<ion-content>
  <ion-grid class="p-0">
    <ion-row>
      <!-- carta MVP -->
      <ion-col size="12" class="p-0">
        <div class="mvp-content">
          <h3 class="media">{{ selectedPlayer?.player?.media }}</h3>

          <img
            class="flag"
            src="/assets/images/countries/ar.png"
            alt="ar.png"
          />
          <img
            class="shield"
            src="/assets/images/shields/boca.png"
            alt="ar.png"
          />
          <img
            class="card-type card-bronze"
            [src]="selectedPlayer?.player?.profilepicture"
            alt="card-background.png"
            class="player"
          />
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="rounded">
      <!-- Descripcion -->
      <ion-col size="12" class="ion-text-center">
        <h5>{{ packDetails?.name }}</h5>
        <p class="center">
          {{ packDetails?.description }}
        </p>
      </ion-col>
      <!-- lista de mini cards -->
      <ion-col *ngIf="playersCards" size="12" class="ion-text-center">
        <h6>Tarjetas de jugadores</h6>
      </ion-col>
      <ion-col
        *ngFor="let card of playersCards; let i = index"
        size="4"
        [class]="i > 8 ? 'add-margin' : ''"
      >
        <app-mini-card
          [card]="card"
          [ngClass]="{
            highlight: selectedPlayer && selectedPlayer.id === card.id
          }"
          (click)="pickPlayer(card)"
        ></app-mini-card>
      </ion-col>
      <ion-col *ngIf="specialCards" size="12" class="ion-text-center">
        <h6>Tarjetas especiales</h6>
      </ion-col>
      <ion-col
        *ngFor="let card of specialCards"
        size="4"
        class="minicard-content"
      >
        <app-mini-card [card]="card"></app-mini-card>
      </ion-col>
      <ion-col size="12">
        <ion-button expand="block" (click)="dismiss()" class="">
          <strong>Continuar</strong>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
