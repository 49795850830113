import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { MatchService } from '../../services/match.service';
import { ISeason } from '../../interfaces/ISeason';
import { IStreak } from 'src/app/interfaces/IStreak';
import { TeamService } from 'src/app/services/team.service';
import { SeasonResumeComponent } from '../season-resume/season-resume.component';
import { FormationsService } from 'src/app/services/formations.service';

@Component({
  selector: 'app-card-season-state',
  templateUrl: './card-season-state.component.html',
  styleUrls: ['./card-season-state.component.scss'],
})
export class CardSeasonStateComponent implements OnInit, OnChanges {
  @Input() season: ISeason;
  @Input() teamId: number;
  @Input() playedMatches;
  displayMatches = [];
  teamStreak: IStreak[];
  team;
  teamPlayers;
  newSeason: boolean = false;

  constructor(
    private utilService: UtilsService,
    private matchService: MatchService,
    private teamService: TeamService,
    public formationsService: FormationsService
  ) { }

  async ngOnInit() {

    this.matchService.getStreakOfTheLastGamesByTeamId(this.teamId, 11, 1).subscribe(async res => {
      this.teamStreak = res.data;
      if (this.teamStreak.length === 0) {
        this.newSeason = true;
      }
      this.completeTeamStreak();
    });
    this.teamService
    .getTeamInformation(this.teamId)
    .subscribe((response) => {
      this.team = response.data;
      this.teamPlayers = this.formationsService.refreshTeamInformation(
        this.team
      );
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && !!this.season && !!this.season?.matches){
      console.log(!!this.season?.matches)
      this.displayMatches = [...this.season?.matches];
      this.completeMatches();
    }
  }

  completeTeamStreak() {
    if (this.teamStreak.length < 11) {
      const objectsToAdd = 11 - this.teamStreak.length;
      for (let i = 0; i < objectsToAdd; i++) {
        this.teamStreak.push({ 
          matchId: null,
          result: 'pending',
          date: null,
          localTeamId: null,
          localGoals: null,
          awayTeamId: null,
          awayGoals: null,
         });
      }
    }
  }

  completeMatches() {
    this.displayMatches?.sort((a, b) => a.id - b.id);
    while (this.displayMatches?.length < 10) {
      this.displayMatches.push({ state: undefined });
    }
  }

  getImagePath(result: string): string {
    switch (result) {
      case 'G':
        return 'assets/icon/match-win-season.svg';
      case 'E':
        return 'assets/icon/match-tie-season.svg';
      case 'P':
        return 'assets/icon/match-lose-season.svg';
      default:
        return 'assets/icon/match-pending-season.svg';
    }
  }

  async openSeasonResume() {
    // console.log(this.teamPlayers);
    console.log("season", this.season);
    console.log("teamStreak", this.teamStreak);
    const modal = await this.utilService.showModalSheet(
      SeasonResumeComponent,
      { 
        team: this.teamPlayers,
        teamStreak: this.teamStreak,
        season: this.season,
        playedMatches: this.playedMatches
      },
      0.9
    );
    modal.onDidDismiss().then(async (modalData: any) => {
      console.log('modal cerrado');
    })
  }

}
