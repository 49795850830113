import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AppConstants } from 'src/app/app.constants';
import { TeamService } from 'src/app/services/team.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UsersService } from '../../services/users.service';
import { SheetSelectorComponent } from '../sheet-selector/sheet-selector.component';


@Component({
  selector: 'app-shield',
  templateUrl: './shield.component.html',
  styleUrls: ['./shield.component.scss'],
})
export class ShieldComponent implements OnInit {

  @ViewChild('svgContainer') svgContainer!: ElementRef;

  createClubForm: FormGroup;
  type = 0;
  svgs: any;
  user: any;
  loaded = false;
  logosSvg = AppConstants.logos;
  desingsSvg = AppConstants.desings;
  primaryColor = '#ffffff';
  secondaryColor = '#037dff';
  wordColor = '#ffffff';
  newSelectedLogo: any = AppConstants.logos[0];
  newSelectedDesing: any = AppConstants.desings[0];
  logoBase64: string;
  shieldId = 1;

  constructor(
    private utilsService: UtilsService,
    private userService: UsersService,
    private teamService: TeamService,
    public modalController: ModalController) {
  }

  get name() {
    return this.createClubForm?.get('name');
  }

  ngOnInit() {
    this.createClubForm = this.teamService.getcreateClubForm();
    this.user = this.userService.getCurrentUser();
    console.log("%% This User: ", this.user);

    if(this.user?.teams[0]?.id){
      this.type = this.user?.teams[0]?.type;
      this.primaryColor = this.user?.teams[0]?.primarycolor;
      this.secondaryColor = this.user?.teams[0]?.secondarycolor;
      this.newSelectedLogo = AppConstants?.logos[this.user?.teams[0]?.shield_id-1];
      this.newSelectedDesing = AppConstants?.desings[this.user?.teams[0]?.type];
      this.wordColor = this.user?.teams[0]?.wordColor;
      this.setName(this.user?.teams[0]?.name);
    }
  }

  setName(value) {
    this.createClubForm.patchValue({ name: value });
  }

  pickShield(shieldType) {
    this.type = shieldType;
  }

  dismiss() {
    this.modalController.dismiss();

  }

  createShield() {
    const shieldData = {
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
      wordColor: this.wordColor,
      type: this.newSelectedLogo.id, 
      current_styles_id: this.newSelectedDesing.id,
      svgImage: this.logoBase64,
      shield_id: this.newSelectedLogo.id
    }    
    this.modalController.dismiss(shieldData);
  }

  async changeLogo() {
    const modal = await this.utilsService.showModalSheet(SheetSelectorComponent,
      { options: this.logosSvg, label: 'Escudos disponibles', optionSelected: this.newSelectedLogo.id });
    modal.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        this.newSelectedLogo = modalData.data;
      }
    });
  }

  async changeDesing() {
    const modal = await this.utilsService.showModalSheet(SheetSelectorComponent,
      { options: this.desingsSvg, label: 'Diseños disponibles', optionSelected: this.newSelectedDesing.id });

    modal.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        this.newSelectedDesing = modalData.data;
      }
    });
  }
}
