import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICard } from 'src/app/interfaces/ICard';
import { UtilsService } from 'src/app/services/utils.service';
import { IUser } from '../../interfaces/IUser';
import { BuyContractPlayerModalComponent } from '../modals/buy-contract-player-modal/buy-contract-player-modal.component';

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss'],
})
export class PlayerListComponent implements OnInit {
  @Input() contract: boolean;
  @Input() playerList: ICard[] = [];
  @Input() deleteBtn?: boolean;
  @Output() player: EventEmitter<ICard> = new EventEmitter();

  user: IUser;

  constructor(
    private utilsService: UtilsService,
  ) {
  }

  ngOnInit() {

  }

  async renew(playerlist: ICard) {

    const modal = await this.utilsService.showModalSheet(
      BuyContractPlayerModalComponent,
      { card: playerlist },
      0.6
    );
    const metaModal = modal.onDidDismiss();
    const result = (await metaModal).data;
    if (result) {
      this.utilsService.dismissModal(result);
    }

  }

  sell(player: ICard, i: number) {
    const rowWrapper = document.getElementById('row-wrapper' + i);
    rowWrapper.classList.toggle('dark-filter');
    this.player.emit(player);
  }

}
