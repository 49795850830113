<ion-card (click)="goToNew()" class="card">
  <div class="header-card">
    <div class="seccion-container">
      <img class="logo" alt="" />
      <span class="seccion">{{ noticia.seccion }}</span>
    </div>
    <div class="fecha">{{ noticia.createdAt | date: "dd/MM - HH:mm" }} hs</div>
  </div>
  <h3 class="titulo">{{ noticia.titulo }}</h3>
  <div class="img-container">
    <img [src]="noticia.img" atl="" class="img" />
  </div>
  <p class="cuerpo">{{ noticia.cuerpo }}</p>
  <p class="autor">Por: {{ noticia.autor }}</p>
</ion-card>
