<ion-grid class="ion-no-padding ion-no-margin">
  <ion-card class="playerlist">
    <ion-card-content class="list-row-wrapper">
      <ion-row *ngFor="let playerCard of playerList; index as i" [ngClass]="{
        bronze: playerCard.player.category.name === 'Bronze',
        silver: playerCard.player.category.name === 'Silver',
        gold: playerCard.player.category.name === 'Golden'
      }" class="ion-padding-vertical ion-padding-horizontal row-wrapper" id="row-wrapper{{i}}">
        <ion-col size="3" class="ion-align-self-center ion-text-center">
          <img class="round-img" alt="img-player.png"
            [src]="playerCard?.player?.profilepicture || 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y'" />

          <ion-chip class="ion-no-padding" color="dark">

          </ion-chip>
        </ion-col>

        <ion-col class="ion-align-self-center ion-text-center" size="6">
          <ion-row class="ion-no-margin ion-no-padding" (click)="sell(playerCard, i)">
            <ion-col size="12" class="ion-no-margin ion-no-padding ion-justify-content-start d-flex">
              <ion-label color="light" class="font-size-16">{{ playerCard?.player?.lastname }}</ion-label>
            </ion-col>
            <ion-col size="12"
              class="ion-no-margin ion-no-padding ion-justify-content-start d-flex ion-align-items-center font-size-14 skills">
              <ion-label color="light">{{ playerCard.player.position | nameInitials }}</ion-label>

              <ion-label color="secondary" class="highlight-text">{{ playerCard.player.media }}%</ion-label>

              <div class="light-text" *ngIf="!contract && !deleteBtn">
                {{ playerCard.nrocontrato }}
                <ion-icon src="assets/ksport-matches.svg"></ion-icon>
              </div>

              <div *ngIf="deleteBtn" class="light-text">
                <ion-icon src="assets/SoccerCoin.svg" ></ion-icon>
              </div>

              <div *ngIf="contract" class="light-text">
                <ion-icon color="primary" name="reload-outline" (click)="renew(playerCard)"></ion-icon>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col class="ion-align-self-center ion-text-center" size="3">
          <app-hightlight-skill size="sm" [prefixType]="'icon'"></app-hightlight-skill>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-grid>