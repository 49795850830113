import { Injectable, NgZone, WritableSignal, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { DeviceUtils } from '../utils/device';
import { UtilsService } from './utils.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationCardComponent } from '../components/notification-card/notification-card.component';
import { ToastDataService } from './toastData.service';
import { BellNotificationComponent } from '../components/bell-notification/bell-notification.component';
import { initializeApp } from 'firebase/app';
import { FCM } from '@capacitor-community/fcm';

export type UserDevice = {
  userId: number;
  gcmId: string;
  device: string;
  osVersion: string;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  device;
  notificationCount: WritableSignal<number> = signal(0);
  bellNotificationComponent: BellNotificationComponent | null = null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  URL_API = this.globalService.URL_API;



  constructor(
    private globalService: GlobalService,
    private http: HttpClient,
    private deviceUtils: DeviceUtils,
    private utilsService: UtilsService,
    private toastrService: ToastrService,
    private toastDataService: ToastDataService,
    private ngZone: NgZone,
  ) {
    this.device = this.deviceUtils.getDevice();
  }

  setBellNotificationComponent(component: BellNotificationComponent) {
    this.bellNotificationComponent = component;
  }

  getNotification(userId: number): Observable<any> {
    return this.http.get(
      `${this.URL_API}/notifications/get-notifications/${userId}`,
    );
  }

  postGCM(body: UserDevice): Observable<any> {
    return this.http.post(`${this.URL_API}/device/check-user-device`, body);
  }

  async pushNotificationsAddListener(userId) {

    await PushNotifications.addListener('registration', async (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
      await this.retrieveAndSaveListenerToken(token.value, userId);
    });

    await PushNotifications.addListener('pushNotificationReceived', async (res: any) => {
      this.ngZone.run(async () => {
        this.toastDataService.setData({
          title: res?.title,
          isToast: true,
        });

        const { toastId } = this.toastrService.show(null, null, {
          enableHtml: true,
          tapToDismiss: true,
          disableTimeOut: true,
          toastComponent: NotificationCardComponent,
        });

        this.notificationCount.update(count => count += 1);
        this.bellNotificationComponent.updateNotification();

        this.toastDataService.setData({
          title: res?.title,
          isToast: true,
          toastId,
        });
      });
    });
  }

  async retrieveAndSaveListenerToken(token, userId) {
    this.postGCM({
      userId,
      device: this.device?.info?.platform,
      gcmId: token,
      osVersion: this.device?.info?.osVersion,
    }).subscribe((res) => {
      if (res.status !== 200) {
        this.utilsService.showAlert(
          '🤔 Oops',
          'Algo no está funcionando correctamente, comprueba tu conexión a internet y vuelve a intentarlo nuevamente',
          false
        );
      }
    });
  }

  updateNotification(notificationId: string): Observable<any> {
    return this.http.put(`${this.URL_API}/notifications/update-notifications`, {
      id: notificationId,
      seen: true,
    });
  }

  async initializateApp() {
    const firebaseConfig = {
      apiKey: 'AIzaSyAK2VXB8nWZ0dEb2jAMOZjefaZK0rIf9QM',
      authDomain: 'ksport-2faca.firebaseapp.com',
      projectId: 'ksport-2faca',
      storageBucket: 'ksport-2faca.appspot.com',
      messagingSenderId: '813393974963',
      appId: '1:813393974963:web:3f049071c027f0df5d82c6'
    };

    const app = initializeApp(firebaseConfig);
    console.log(app);
    this.subscribeToTopic('general');
  }

  async subscribeToTopic(topic: string) {
    FCM.subscribeTo({ topic }).then(res => {
      console.log('Subscribe to topic', res);
    }).catch(err => {
      console.log(err);
    });
  }


}
