<ion-row class="buy-contract ion-justify-content-center ion-align-items-start ion-padding-bottom">
  <ion-col class="ion-margin-bottom" size="12">
    <div class="player-selected ion-margin-bottom">
      <div class="border-left"></div>
      <ion-img class="circle-profile" [src]="card?.player?.profilepicture" alt=""></ion-img>
      <div>{{ card?.player?.lastname }}, {{ card?.player?.name }}</div>
    </div>
    <div class="card-player-selected ion-margin-top">
      <ion-grid>
        <ion-row class="ion-margin-bottom">
          <ion-col size="12">Contratos disponibles para asociar:</ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <section class="contracts-wrapper">
            <swiper-container [options]="slideOptsPacks" slides-per-view="3"  grid-rows="1" colu navigation="true">
              <!-- <div class="available-contracts">
                <app-player-contracts [matchesQuantity]="'PARTIDO'" [matches]="1" [urlImg]="'/assets/contractsADS.png'"
                  [text]="' ADS'" (click)="renew(null, true)"></app-player-contracts>
              </div> -->
              <swiper-slide
                class="available-contracts"
                *ngFor="let contract of productContracts; index as i"
              >
              <app-player-contracts *ngIf="i === 0" [matchesQuantity]="'PARTIDO'" [matches]="1" [urlImg]="'/assets/contractsADS.png'"
                  [text]="' ADS'" (click)="renew(null, true)"></app-player-contracts>
                <app-player-contracts 
                  [matches]="contract?.packs_templates?.quantity" (click)="renew(contract, false)" *ngIf="contract?.packs_templates?.quantity > 0">
                </app-player-contracts>
                <div class="circle-contracts" *ngIf="i !== 0">
                  <ion-icon slot="start" src="assets/SoccerCoin.svg" class="ion-margin-end"></ion-icon> {{contract?.price}}
                </div>
              </swiper-slide>
            </swiper-container>
            
          </section>
        </ion-row>
      </ion-grid>
    </div>
  </ion-col>
  <ion-col class="ion-margin-top" size="12">
    <div class="card-container">
      <div class="ion-text-center">
        ¿Necesitás más contratos?
        <br />
        Adquirilos en nuestro Marketplace
      </div>
      <ion-button class="ion-margin-top" size="block" color="primary" (click)="goMarket()">
        Ir a la tienda
      </ion-button>
    </div>
  </ion-col>
</ion-row>