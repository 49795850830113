<div class="pack ion-padding gap-10"
  [ngClass]="[classList ? classList : '', last && !modal ? 'flex-row' : 'flex-col ion-justify-content-center ion-align-items-center', modal ? 'w-50 ion-align-self-center' : '']">
  <div class="flex-col ion-justify-content-center ion-align-items-center gap-15"
    [ngClass]="last && !modal ? 'w-50' : ''">
    <ion-label class="tag font-size-12">{{ pack?.packs_templates?.name | uppercase }}</ion-label>
    <img src="assets/SoccerCoin.svg" alt="soccerCoin.svg">
    <ion-label class="font-size-18">{{ pack?.packs_templates?.quantity }} coins</ion-label>
    <div class="price-tag font-size-12">
      MX$ {{ pack?.price }}
    </div>
  </div>
  <ion-button *ngIf="modal" (click)="buy(pack)" expand="block" class="w-100">
    Comprar
  </ion-button>

  <div class="last" *ngIf="last && !modal">
    <div class="last-card flex-col ion-align-items-center ion-justify-content-center gap-5" [ngClass]="classList ? classList : ''">
      <img src="assets/SoccerCoin.svg" alt="soccerCoin.svg">
      <ion-label class="font-size-22">+ {{ pack?.packs_templates?.quantity/20 }}</ion-label>
      <ion-label class="font-size-18">¡de regalo!</ion-label>
    </div>
    <ion-label class="tag font-size-12">Total de coins: {{(pack?.packs_templates?.quantity/20) + pack?.packs_templates?.quantity}}</ion-label>
  </div>
</div>