<ion-grid class="container button-bar" *ngIf="typeHeader === 'home'">
  <ion-row class="ion-justify-content-center ion-margin-top">
    <ion-col size="2" class="ion-justify-content-center d-flex">
      <a routerLink="/profile" class="perfil">
        <app-information-shield
          [keyFromS3]="user?.teams[0]?.svg_shield"
          [height]="36"
          [name]="user?.teams[0]?.name"
          size="small"
        >
        </app-information-shield>
      </a>
    </ion-col>
    <ion-col size="3" class="ion-text-left">
      <img
        src="assets/ksport-mini-icon.svg"
        alt="logo icon"
        width="56"
        height="38"
      />
    </ion-col>
    <ion-col size="2" class="d-flex-end header-col training">
      <app-streak-track
        *ngIf="!!this.daysStatus?.hadTrainingToday"
        [ngClass]="{'header-col-hidden': !user}"
        (click)="handleOpenTrainingTeamModal()"
      />
    </ion-col>
    <ion-col size="5" class="header-col coins-notification">
      <div class="monedas" *ngIf="user">
        <app-coins></app-coins>
        <a routerLink="/notifications">
          <app-bell-notification></app-bell-notification>
        </a>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

<div *ngIf="typeHeader === 'sponsor&shield'" >
  <ion-grid class="button-bar">
    <ion-row class="ion-justify-content-center sponsor-row">
      <ion-col [size]="showButtons? 5 : 12" class="ion-justify-content-center d-flex">
        <ion-label>{{user?.userSponsor?.sponsor?.description}}</ion-label>
      </ion-col>
      <ion-col size="2" class="d-flex-end header-col training" *ngIf="showButtons">
        <app-streak-track
          [ngClass]="{'header-col-hidden': !user}"
          (click)="handleOpenTrainingTeamModal()"
        />
      </ion-col>
      <ion-col size="5" class="header-col coins-notification" *ngIf="showButtons">
        <div class="monedas" *ngIf="user">
          <app-coins></app-coins>
          <app-bell-notification></app-bell-notification>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="ion-text-left toolbar">
    <ion-grid >
      <ion-row>
        <ion-col size="10" class="title-col">
          <ion-icon name="arrow-back-outline" (click)="backClicked()" color="light" *ngIf="back">
          </ion-icon>
          <ion-label [ngStyle]="{'font-size': fontSize, 'font-family': 'Formula1'}">{{title}}</ion-label>
        </ion-col>
        <ion-col size="2" class="flex">
          <app-information-shield
          [height]="42"
          [keyFromS3]="user?.teams[0]?.svg_shield"
          ></app-information-shield>
          <div class="sponsor">
            {{user?.userSponsor?.sponsor?.description}}
          </div>
          <div class="triangle"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>

<div *ngIf="typeHeader === 'onlyBack'" >
  <ion-grid class="button-bar">
    <ion-row class="ion-justify-content-center sponsor-row">
      <ion-col [size]="12" class="ion-justify-content-center d-flex">
        <ion-label>{{user?.userSponsor?.sponsor?.description ?? 'KSPORT'}}</ion-label>
      </ion-col>
    </ion-row>
    
    <ion-row class="back-button">
      <ion-col size="12">
        <ion-icon name="arrow-back-outline" (click)="backClicked()" color="light" *ngIf="back">
        </ion-icon>
        <ion-label [ngStyle]="{'font-size': fontSize}">{{title}}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- <ion-grid>
  </ion-grid> -->
</div>


<div *ngIf="typeHeader === 'onlySponsor'" >
  <ion-grid class="button-bar">
    <ion-row class="ion-justify-content-center sponsor-row">
      <ion-col [size]="showButtons? 5 : 12" class="ion-justify-content-center d-flex">
        <ion-label>{{user?.userSponsor?.sponsor?.description}}</ion-label>
      </ion-col>
      <ion-col size="2" class="d-flex-end header-col training" *ngIf="showButtons">
        <app-streak-track
          [ngClass]="{'header-col-hidden': !user}"
          (click)="handleOpenTrainingTeamModal()"
        />
      </ion-col>
      <ion-col size="5" class="header-col coins-notification" *ngIf="showButtons">
        <div class="monedas" *ngIf="user">
          <app-coins></app-coins>
          <app-bell-notification></app-bell-notification>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="ion-text-left sponsor-toolbar">
    <ion-grid >
      <ion-row>
        <ion-col size="8" class="title-col">
          <ion-icon name="arrow-back-outline" (click)="backClicked()" [color]="arrowColor == 'dark' ? 'dark' : 'light'" *ngIf="back">
          </ion-icon>
        </ion-col>
        <ion-col size="4">
          <ion-img [src]="tournamentSponsorUrl? tournamentSponsorUrl: user?.userSponsor?.imageUrl "></ion-img>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>