<div class="container-line">
  <div class="line-container">
    <div class="score" [ngStyle]="{ top: logoTop() }">
      +<strong>{{ seasonPoint }}</strong> PTS
    </div>
    <div class="line done" [ngStyle]="{ height: logoTop() }"></div>
    <ion-img
      [ngStyle]="{ top: logoTop() }"
      class="logo-team"
      src="/assets/escudos/magia.svg"
    ></ion-img>
    <div class="line"></div>
    <div class="circle">
      <ion-icon name="trophy-outline"></ion-icon>
    </div>
  </div>

  <div class="stage-container" *ngIf="tournament">
    <div *ngFor="let key of zoneKeys" class="stage" [ngStyle]="{ opacity: current !== key ? '0.5' : '1' }">
      {{ zones[key] }}
      <div class="triangle"></div>
    </div>
  </div>
</div>
