<ion-content>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <img [src]="screenshot" *ngIf="screenshot" alt="Captura de pantalla" style="max-width: 60%; height: auto;"/>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-margin-top"><p>Selecciona una red social</p></ion-row>
    <ion-row>
      <ion-col size="2.4">
        <ion-row class="ion-justify-content-center">
          <ion-button (click)="shareOnWhatsapp()"><ion-icon name="logo-whatsapp"></ion-icon></ion-button>
        </ion-row>
      </ion-col>
      <ion-col size="2.4">
        <ion-row class="ion-justify-content-center">
          <ion-button (click)="shareOnFacebook()"><ion-icon name="logo-facebook"></ion-icon></ion-button>
        </ion-row>
      </ion-col>
      <ion-col size="2.4">
        <ion-row class="ion-justify-content-center">
          <ion-button (click)="shareOnInstagram()"><ion-icon name="logo-instagram"></ion-icon></ion-button>
        </ion-row>
      </ion-col>
      <ion-col size="2.4">
        <ion-row class="ion-justify-content-center">
          <ion-button (click)="shareOnTwitter()"><ion-icon name="logo-twitter"></ion-icon></ion-button>
        </ion-row>
      </ion-col>
      <ion-col size="2.4">
        <ion-row class="ion-justify-content-center">
          <ion-button (click)="share()"><ion-icon name="add-outline"></ion-icon></ion-button>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>