import { Component, Input, OnInit } from '@angular/core';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
import { SponsorBenefitType } from 'src/app/enums/sponsorBenefitType';
import { Sponsor } from 'src/app/interfaces/sponsor';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SponsorService } from 'src/app/services/sponsor.service';
import { UsersService } from 'src/app/services/users.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-sponsor-modal',
  templateUrl: './sponsor-modal.component.html',
  styleUrls: ['./sponsor-modal.component.scss'],
})
export class SponsorModalComponent implements OnInit {
  @Input() onlySponsor = true;
  sponsorSelected: Sponsor;
  sponsor: Sponsor[] = [];
  user;

  constructor(
    private utilsService: UtilsService, 
    private sponsorService: SponsorService, 
    private userService: UsersService,
    private notificationService: NotificationsService
    ) { }

  async ngOnInit() {
    this.user = this.userService.getLoggedUser();
    console.log(this.user);
    const [_, resp] = await to(lastValueFrom(this.sponsorService.getSponsors(SponsorBenefitType.MONTHLY)));
    if (resp?.data) {
      this.sponsor = resp?.data;
    }
  }

  dismiss() {
    this.utilsService.dismissModal();
  }

  async selectSponsor(sponsor: Sponsor) {
    this.sponsorSelected = sponsor;
  }

  async confirm() {
    if (this.onlySponsor) {
      const teamSponsorBody = {
        teamId: this.user?.teams[0]?.id,
        sponsorId: this.sponsorSelected?.id,
        expiredAt: new Date()
      };
      const [_, resp] = await to(lastValueFrom(this.sponsorService.selectSponsor(teamSponsorBody)));
      if (resp?.data) {
        const ksport = JSON.parse(localStorage.getItem('ksport'));
        ksport.user.userSponsor = resp?.data;
        this.notificationService.subscribeToTopic(this.sponsorSelected?.id)
        localStorage.setItem('ksport', JSON.stringify(ksport));
        this.dismiss();
      }
    } else {
      this.utilsService.dismissModal(this.sponsorSelected);
    }

  }

}
