<ion-content scroll-y="hidden">
  <div class="video-wrapper">
    <div class="video-container">
      <video #videoElement (click)="playPauseVideo()">
        <source src="./assets/videos/CabifyArgentina.mp4" type="video/mp4" />
      </video>
      <div class="video-controls">
        <div class="progress-bar">
          <div [style.width.%]="progressPercent"></div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<app-fixed-action *ngIf="canSkip">
  <ion-button *ngIf="showStopButton" (click)="stopVideo()" expand="block">
    Saltar</ion-button
  >
  <ion-button *ngIf="showRewardButton" (click)="closeVideo()" expand="block">
    Reclama tu recompensa
  </ion-button>
</app-fixed-action>

<app-fixed-action *ngIf="!canSkip && showRewardButton">
  <ion-button (click)="startMatch()" expand="block">
    Comenzar partido
  </ion-button>
</app-fixed-action>