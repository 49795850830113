<ion-header *ngIf="selectedPlayer?.player" class="ion-padding">
  <ion-row class="player-picker ion-justify-content-center ion-align-items-start ion-margin-top">
    <ion-col size="12">
      <h6>
        <ion-label color="light">Jugador seleccionado:</ion-label>
      </h6>
      <div class="card-player-selected">
        <div class="ion-padding-vertical">
          <app-mini-card [card]="selectedPlayer" *ngIf="selectedPlayer" [ngClass]="substitutePlayer ? 'darker' : ''">
          </app-mini-card>
          <app-mini-card [card]="reservePlayers[0]" *ngIf="!selectedPlayer"
            [ngClass]="substitutePlayer ? 'darker' : ''">
          </app-mini-card>
          <app-mini-card [card]="substitutePlayer" *ngIf="substitutePlayer?.player" [class]="
            selectedPlayer?.contract === 0 ? 'absWhitoutContract' : 'abs'
          "></app-mini-card>
        </div>
        <ion-grid class="ion-padding-horizontal">
          <ion-row>
            <ion-col size="6">
              <div class="button-name border-red" *ngIf="substitutePlayer?.player">
                <span>{{ selectedPlayer?.player?.lastname }}</span>
              </div>
            </ion-col>
            <ion-col size="6">
              <div class="button-name border-green" *ngIf="substitutePlayer?.player">
                <span>{{ substitutePlayer?.player?.lastname }}</span>
              </div>
            </ion-col>
            <ion-col size="12" class="font-size-12">PERFORMANCE:</ion-col>
          </ion-row>
          <ion-row>
            <ion-col data-skill="CA" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_counter_attack < 10 ? '0' + selectedPlayer?.player?.technical_skill_counter_attack : selectedPlayer?.player?.technical_skill_counter_attack }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_counter_attack < 10 ? '0' + substitutePlayer?.player?.technical_skill_counter_attack : substitutePlayer?.player?.technical_skill_counter_attack }}&nbsp; &nbsp;</span>
            </ion-col>
            <ion-col data-skill="DEF" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_defense < 10 ? '0' + selectedPlayer?.player?.technical_skill_defense : selectedPlayer?.player?.technical_skill_defense }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_defense < 10 ? '0' + substitutePlayer?.player?.technical_skill_defense : substitutePlayer?.player?.technical_skill_defense }}&nbsp; &nbsp;</span>
            </ion-col>
            <ion-col data-skill="DRI" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_dribbling < 10 ? '0' + selectedPlayer?.player?.technical_skill_dribbling : selectedPlayer?.player?.technical_skill_dribbling }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_dribbling < 10 ? '0' + substitutePlayer?.player?.technical_skill_dribbling : substitutePlayer?.player?.technical_skill_dribbling }}&nbsp; &nbsp;</span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col data-skill="OF" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_offensive < 10 ? '0' + selectedPlayer?.player?.technical_skill_offensive : selectedPlayer?.player?.technical_skill_offensive }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_offensive < 10 ? '0' + substitutePlayer?.player?.technical_skill_offensive : substitutePlayer?.player?.technical_skill_offensive }}&nbsp; &nbsp;</span>
            </ion-col>
            <ion-col data-skill="PAS" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_passing < 10 ? '0' + selectedPlayer?.player?.technical_skill_passing : selectedPlayer?.player?.technical_skill_passing }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_passing < 10 ? '0' + substitutePlayer?.player?.technical_skill_passing : substitutePlayer?.player?.technical_skill_passing }}&nbsp; &nbsp;</span>
            </ion-col>
            <ion-col data-skill="PER" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_performance_index < 10 ? '0' + selectedPlayer?.player?.technical_skill_performance_index : selectedPlayer?.player?.technical_skill_performance_index }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_performance_index < 10 ? '0' + substitutePlayer?.player?.technical_skill_performance_index : substitutePlayer?.player?.technical_skill_performance_index }}&nbsp; &nbsp;</span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4" class="col-custom">
              <app-hightlight-skill size="sm" [prefixType]="'number'"
                [skillValue]="substitutePlayer?.player?.technical_skill_physical?? 0"
                [shorthand]="hightlightSkill"></app-hightlight-skill>
            </ion-col>
            <ion-col data-skill="PRE" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_pressing < 10 ? '0' + selectedPlayer?.player?.technical_skill_pressing : selectedPlayer?.player?.technical_skill_pressing }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_pressing < 10 ? '0' + substitutePlayer?.player?.technical_skill_pressing : substitutePlayer?.player?.technical_skill_pressing }}&nbsp; &nbsp;</span>
            </ion-col>
            <ion-col data-skill="SPD" class="skill" size="4">
              <span class="red">{{ selectedPlayer?.player?.technical_skill_speed < 10 ? '0' + selectedPlayer?.player?.technical_skill_speed : selectedPlayer?.player?.technical_skill_speed }}&nbsp; &nbsp;</span>
              <span class="green" *ngIf="substitutePlayer">{{ substitutePlayer?.player?.technical_skill_speed < 10 ? '0' + substitutePlayer?.player?.technical_skill_speed : substitutePlayer?.player?.technical_skill_speed }}&nbsp; &nbsp;</span>
            </ion-col>
          </ion-row>
          <ion-row class="ion-no-padding">
            <ion-col size="12" class="ion-no-padding font-size-12 d-flex gap-6">
              HABILIDAD DESTACADA
              <app-tooltip label="Este jugador está entre los mejores 10 con esta habilidad"></app-tooltip>
            </ion-col>
            <ion-col size="12">
              <app-hightlight-skill size="md" prefixType="icon" [shorthand]="hightlightSkill"></app-hightlight-skill>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-col>
    <ion-col *ngIf="chemistry" size="12">
      <ion-row style="border-bottom: 0.5px solid #eee; padding-bottom: 5px;">
        <ion-col class="ion-no-padding font-size-14" size="4">Posición ideal</ion-col>
        <ion-col class="ion-no-padding font-size-14" size="4">Posición actual</ion-col>
        <ion-col class="ion-no-padding font-size-14 ion-text-center d-flex gap-10 ion-justify-content-center ion-align-items-center" size="4">Química
          <app-tooltip [label]="tooltipMessage" [translate]="-90"></app-tooltip></ion-col>
      </ion-row>
      <ion-row style="padding-top: 5px;">
        <ion-col class="ion-no-padding ion-align-self-center" size="4">{{ selectedPlayer?.lineUpPosition?.type || selectedPlayer?.player?.full_position?.type |
          uppercase }}</ion-col>
        <ion-col class="ion-no-padding ion-align-self-center" [ngClass]="'text-'+chemistry" size="4">
          <span *ngIf="card?.player" >{{ substitutePlayer?.player?.full_position?.type || selectedPlayer?.player.full_position?.type| uppercase }}</span>
        </ion-col>
        <ion-col class="ion-no-padding ion-align-self-center ion-text-center" size="4">
          <ion-img style="height: 25px !important;" [src]="'assets/icon/sentiment_'+ chemistry +'.svg'"></ion-img>
        </ion-col>
      </ion-row>
    </ion-col>
    <ion-col *ngIf="!substitutePlayer && card.player" size="12">
      <ion-button size="block" fill="outline" color="light" (click)="renew()" class="mt-3 round">
        Renovar contrato
      </ion-button>
    </ion-col>
    <ion-col *ngIf="substitutePlayer" size="12">
      <ion-button size="block" fill="outline" color="light" (click)="changePlayer(substitutePlayer)" class="mt-3 round">
        Aceptar
      </ion-button>
    </ion-col>
    <ion-col *ngIf="!substitutePlayer && !card.player" size="12">
      <ion-button size="block" fill="outline" color="light" (click)="changePlayer(card)" class="mt-3 round">
        Seleccionar
      </ion-button>
    </ion-col>
  </ion-row>
</ion-header>
<ion-content class="player-container overflow-auto ion-padding">
  <ion-row class="ion-margin-top">
    <ion-col size="12">
      <h6>
        <ion-label color="light">Sustituciones sugeridas:</ion-label>
      </h6>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-xs="4" *ngFor="let suggestedPlayer of suggestedSubstitutions; let i = index">
      <app-mini-card [card]="suggestedPlayer" (click)="pickPlayer(suggestedPlayer);" [ngClass]="{
          highlight: selectedPlayer && selectedPlayer.id === suggestedPlayer.id
        }"></app-mini-card>
    </ion-col>
    <div class="playersMarket" *ngIf="!(suggestedSubstitutions?.length > 0)">
      <p class="noPlayers">
        No tiene jugadores para esta posición
      </p>
      <a class="goMarket" (click)="redirectToMarket()">
        Visitar el market
      </a>
    </div>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <h6>
        <ion-label color="light">Otras sustituciones disponibles:</ion-label>
      </h6>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-xs="4" *ngFor="let reserve of reservePlayers; let i = index">
      <app-mini-card [card]="reserve" (click)="pickPlayer(reserve);" [ngClass]="{
          highlight: selectedPlayer && selectedPlayer.id === reserve.id
        }"></app-mini-card>
    </ion-col>
    <div class="playersMarket" *ngIf="!(reservePlayers?.length > 0)">
      <p class="noPlayers">
        No tiene jugadores disponibles
      </p>
      <a class="goMarket" (click)="redirectToMarket()">
        Visitar el market
      </a>
    </div>
  </ion-row>
  <ion-row *ngIf="selectedPlayer?.player">
    <ion-col size="12">
      <h6>
        <ion-label color="light">Jugadores en campo:</ion-label>
      </h6>
    </ion-col>
  </ion-row>
  <ion-row style="margin-bottom: 200px" *ngIf="selectedPlayer?.player">
    <ion-col size-xs="4" *ngFor="let player of activePlayers; let i = index">
      <app-mini-card [card]="player" [ngClass]="{
          highlight: selectedPlayer && selectedPlayer.id === player.id
        }" (click)="pickPlayer(player);" [showSkills]="true">
      </app-mini-card>
    </ion-col>
  </ion-row>
</ion-content>