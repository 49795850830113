<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <h2>Invita a tus amigos a participar</h2>
      </ion-col>
      <ion-col size="12">
        <span>
          Busca a tus amigos en la app. Si no los encuentras, te ayudaremos en el siguiente paso
        </span>
        <ion-searchbar 
          mode="ios"
          placeholder="Busca por nombre o equipo" 
          (ionChange)="search($event)" 
          (ionInput)="search($event)" 
          [debounce]="250" 
          color="dark"
          showCancelButton="never">
          </ion-searchbar>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="selectedList.length > 0">
      <ion-col size="12" *ngFor="let item of selectedList">
        <div class="player-list">
          <app-information-shield
            [keyFromS3]="item?.svg_shield"
            [height]="36"
            [name]="item?.name"
            size="small"
          >
          </app-information-shield>
          <ion-label>{{item?.name}}
            <br>
            <p>{{item?.users?.name}} {{item?.users?.lastname}}</p>
          </ion-label>
          <ion-img src="assets/icon/close.svg" class="delete" (click)="removeToSelectedList(item)"></ion-img>
        </div>
        <div class="separator"></div>
      </ion-col>
    </ion-row>
    <br>
    <br>
    <br>

    <ion-row class="list"> 
      <ion-col size="12" *ngFor="let item of list">
        <div class="player-list" (click)="addToSelectedList(item)">
          <app-information-shield
            [keyFromS3]="item?.svg_shield"
            [height]="36"
            [name]="item?.name"
            size="small"
          >
          </app-information-shield>
          <ion-label>{{item?.name}}
            <br>
            <p>{{item?.users?.name}} {{item?.users?.lastname}}</p>
          </ion-label>
        </div>
        <div class="separator"></div>
      </ion-col>
    </ion-row>

    <ion-row >
      <ion-col size="12">
        <ion-button 
          expand="block" 
          (click)="sendInvitations()" 
          >
          Continuar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>