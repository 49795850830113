import { Component, Input } from "@angular/core";

@Component({
  selector: "app-objective-card",
  templateUrl: "./objective-card.component.html",
  styleUrls: ["./objective-card.component.scss"],
})
export class ObjectiveCardComponent {
  @Input() hasCoins = false;
  @Input() isComplete = false;
  @Input() title: string;
  @Input() progress:number;
  @Input() objective:number;
  @Input() reward: number;
}
