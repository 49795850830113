import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ICard } from 'src/app/interfaces/ICard';
import { UtilsService } from 'src/app/services/utils.service';
import { MaximumCapacityComponent } from '../maximum-capacity/maximum-capacity.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-revealed-pack',
  templateUrl: './revealed-pack.component.html',
  styleUrls: ['./revealed-pack.component.scss'],
})
export class RevealedPackComponent implements OnInit {
  @Input() isShop?: boolean;
  @Input() isChallenge?: boolean;
  @Input() isExcess?: boolean;
  @Input() excess?: number;
  @Input() allPlayers?: ICard[];
  @Input() cards?: any;
  @Input() packDetails?: any;
  pack: ICard[];
  playersCards: ICard[];
  specialCards: ICard[];
  mappedCards: any = [];
  constructor(private utilsService: UtilsService, private modalControler: ModalController) {}
  selectedPlayer: ICard;

  ngOnInit() {
    this.getPack();

    console.log(this.cards, this.isExcess, this.excess);
  }

  getPack() {
    for (const card of this.cards) {
      const player = {
        code: card.id,
        type: card.players.category_id,
        contract: card.contracts.quantity,
        player: card.players,
      };
      this.mappedCards.push(player);
    }

    this.playersCards = this.mappedCards?.filter((card: ICard) =>
      [1, 2, 3].includes(card.type)
    );
    this.playersCards = this.utilsService?.sortObjectArray(
      this.playersCards,
      true,
      'player',
      'technical_skill_performance_index'
    );

    this.selectedPlayer = this.playersCards[0];
    this.specialCards = this.pack?.filter((card: ICard) =>
      [4, 5].includes(card.type)
    );
  }

  async dismiss() {
    this.utilsService.dismissModal();
    if(this.isExcess){
      const modal = await this.modalControler.create({
        component: MaximumCapacityComponent,
        cssClass: 'modal-class',
        componentProps: {
          excess: this.excess, 
          allPlayers: this.allPlayers
        },
        backdropDismiss: false
      })
      modal.present()
    } 
    if (this.isShop) {
      this.utilsService.goPage('market');
    }
    if (this.isChallenge) {
      this.utilsService.goPage('team/formation');
    }
  }

  pickPlayer(card: any) {
    this.selectedPlayer = card;
  }
}
