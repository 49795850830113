<ion-content>
  <ion-grid class="content">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12" class="ion-padding">
        <h1 class="title">Te regalamos</h1>

        <div class="pill">
          <img src="assets/SoccerCoin.svg" alt="" width="30" height="30" /><span
            class="pill-text"
            >10</span
          >
        </div>

        <ion-label>Si validas tu correo electrónico</ion-label>

        <div class="box">
          <ion-label class="box-text"
            >¡Te tomará muy pocos segundos y recibirás monedas
            gratis!</ion-label
          >
        </div>

        <ion-button (click)="goVerify()" expand="block" class="mb-25">
          Verificar mi correo
        </ion-button>
        <ion-button
          (click)="dismiss()"
          expand="block"
          fill="outline"
          color="light"
        >
          Omitir por ahora
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
