import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-information',
  templateUrl: './team-information.component.html',
  styleUrls: ['./team-information.component.scss'],
})
export class TeamInformationComponent implements OnInit {
  @Input() teamInformation: any;
  @Input() showEditFormationButton: boolean;
  @Input() edit = true;

  constructor() {}

  ngOnInit() {}
}
