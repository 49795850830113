import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ICoinPack } from '../interfaces/ICoinPack';



@Injectable({
  providedIn: 'root'
})
export class GatewayPaymentService {
  url: string;

  constructor(private httpClient: HttpClient) {
    this.url = `${environment.apiBase}/payment`;
  }

  createPreference(coinPack: ICoinPack, userId: number, gateway: string): Observable<any> {
    // Create a preference object
    const preference = {
      id: null,
      items: [
        {
          title: 'Buy coins',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          unit_price: coinPack.price,
          quantity: 1
        }
      ],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      back_urls: {
        success: `${environment.appBase}/market`,
        failure: `${environment.appBase}/market`,
        pending: `${environment.appBase}/market`
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      external_reference: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      auto_return: 'all',
      metadata: {
        id: coinPack.id,
        amount: coinPack.coins
      }
    };

    let query = new HttpParams();
    query = query.append('userId', userId);
    query = query.append('gateway', gateway);
    return this.httpClient.post(`${this.url}`, preference, { params: query });
  }

  // TODO: REMOVE? (the function is not used)
  createPendingPayment(payload) {
    return this.httpClient.post(`${this.url}/create-pending-payment`, payload);
  }

}
