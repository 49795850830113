import { Component, Input, OnInit } from '@angular/core';
import { ICard } from 'src/app/interfaces/ICard';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-bronze-card',
  templateUrl: './bronze-card.component.svg',
  styleUrls: ['./bronze-card.component.scss'],
})
export class BronzeCardComponent implements OnInit {
  @Input() card: ICard;
  cardId: string;
  bPath1: string;
  constructor(
    private utilsService: UtilsService
  ) {
    this.cardId = `${utilsService.randomHash(8)}-b-path-1`;
    this.bPath1 = `#${this.cardId}`;
  }

  ngOnInit() { }

}
