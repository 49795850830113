<div class="modal-content">
  <div class="btn-close" (click)="close()">
    <ion-icon class="font-size-26" name="close-outline"></ion-icon>
  </div>
  <h6 class="ion-text-center">Estás a punto de comprar: {{ productName }}</h6>
  <ion-label class="ion-text-center">{{ productDescription }}</ion-label>
  <div class="ion-padding">
    <small class="link ion-text-center mt-20" (click)="collapse = !collapse">¡Tengo un código de descuento!</small>
  </div>
  <div *ngIf="collapse" class="collapse-content ion-padding">
    <ion-input #discountCodeInput class="input-item" id="discountCode" [formControl]="discountCode" [ngClass]="{'error': discountCode.invalid}" (keyup)="cleanError()" placeholder="Código de descuento" type="text"></ion-input>
    <div class="flex-col ion-align-items-center" *ngIf="discountCode?.errors?.invalid">
      <img src="assets/emergency_home_red.svg" style="height: 20px;" alt="emergency_home_red.svg" />
      <p>Este código caducó o es inválido</p>
    </div>
  </div>

  <ion-button (click)="buy()" expand="block" class="mt-25 w-100">
    Comprar
  </ion-button>
</div>