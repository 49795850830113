<ion-content class="p-0">
  <ion-row>
    <ion-col size="12">
      <div class="content">
        <ion-icon src="assets/emergency_home.svg" class="ion-align-self-center"></ion-icon>
        <br><br>
        <ion-label class="title">Atención</ion-label>
        <br><br>
        <ion-label>Estás a punto de desvincular <b>{{totalToSell}} jugadores</b>. Esta acción no se puede deshacer.</ion-label>
      </div>
      <ion-button color="primary" (click)="close(true)" expand="block">
        Sí, desvincular
      </ion-button>
      <ion-button (click)="close(false) "color="light" fill="outline" expand="block">
        No, volver al menu anterior
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>