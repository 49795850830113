<ion-header>
  <ion-toolbar>
    <ion-icon slot="start" name="arrow-back-outline" (click)="dismiss()" color="light">
    </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="container">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12">
        <h1 class="title">Aviso de redirección</h1>
      </ion-col>
      <ion-col size="12">
        <label class="">
          Será redirigido a la plataforma de pago en unos segundos...
        </label>
      </ion-col>
    </ion-row>
    <ion-row class="cancel-button">
      <ion-col size="12">
        <ion-button (click)="dismiss()" expand="block" fill="outline">Cancelar</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>