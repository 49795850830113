import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Injectable({
  providedIn: 'root',
})
class AuthRouteGuard {
  constructor(private userService: UsersService, private router: Router) {
  }
  async canActivate(): Promise<boolean>  {
    try {
      let user = await this.userService.getLoggedUser();
      if (!!user && user.uuid && user.cognitoId) {
        return true;
      } else {
        this.redirectToLogin();
        return false;
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      return false;
    }
  }
  
  private redirectToLogin() {
    this.router.navigate(['sign-in']);
  }
}

export const AuthGuard: CanActivateFn = (): Promise<boolean> => {
  return inject(AuthRouteGuard).canActivate();
}

