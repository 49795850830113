import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})

export class TrophiesService {
    urlApi = this.globalService.URL_API;

    constructor(
        private globalService: GlobalService,
        private http: HttpClient
    ) { }

    getCups(teamId: number){
        return this.http.get(`${this.urlApi}/getall-usercup/${teamId}`);
    }

    setCup(body){
        return this.http.post(`${this.urlApi}/createUserCup`, body);
    }
}