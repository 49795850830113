import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials'
})
export class NameInitialsPipe implements PipeTransform {

  transform(value: string): string {
    const check = value.split(' ').length;
    const splited = value.split(' ')
      .map(i => i.charAt(0))
      .join('');
    return check > 1 ? splited : value;
  }

}
