import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPendingMatchesInvitations } from '../interfaces/IPendingMatchesInvitations';
import { ISignupChallenge } from '../interfaces/ISignupChallenge';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  URL_API = this.globalService.URL_API;

  constructor(private http: HttpClient, public globalService: GlobalService) {
  }

  signupChallenge(body: ISignupChallenge): Observable<any> {
    return this.http.post(`${this.URL_API}/challenge-to-signup`, body);
  }

  getPendingMatchesInvitations(
    body: IPendingMatchesInvitations
  ): Observable<any> {
    return this.http.post(`${this.URL_API}/get-matches-invitations`, body);
  }

  getAllInvitations(data: any): Observable<any> {
    const {page, take, userId, playedByLocal, playedByAway, status} = data;
    let query = new HttpParams();
    query = data.page ? query.append('page', data.page) : query;
    query = query.append('take', data.take ?? 10);
    query = query.append('userId', data.userId);
    const filter = {playedByLocal, playedByAway, invationStatus: status};
    query = filter.playedByAway ? query.append('playedByAway', filter.playedByAway) : query;
    query = filter.playedByLocal ? query.append('playedByLocal', filter.playedByLocal) : query;
    query = filter.invationStatus ? query.append('invationStatus', filter.invationStatus) : query;
    query = data.name ? query.append('name', data.name) : query;
    console.log({query});
    return this.http.get<any>(`${this.URL_API}/invitations`, {params: query});
  }

  getInvitation(id: string): Observable<any> {
    return this.http.get<any>(`${this.URL_API}/invitation/${id}`);
  }

  updateInvitationStatus(invitationId: string, body: any) {
    return this.http.post(`${this.URL_API}/invitations/update-status/`, {invitationId, ...body});
  }

  getFriendlyMatchInvitations(id){
    return this.http.get<any>(`${this.URL_API}/getFriendlyMatchInvitations/${id}`)
  }
}
