import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IAvailableTeam } from 'src/app/interfaces/IAvailableTeam';

@Component({
  selector: 'app-status-match',
  templateUrl: './status-match.component.html',
  styleUrls: ['./status-match.component.scss'],
})
export class StatusMatchComponent implements OnInit {

  @Input() availableTeam: IAvailableTeam;
  @Input() status: string;
  @Input() showActions: boolean;
  @Input() receivedInvitation: boolean;
  @Input() playedByAway: boolean;
  @Input() playedMatch;
  @Output() outputMatch = new EventEmitter<any>();
  @Output() acceptInvitation = new EventEmitter<any>();
  @Output() reliveInvitation = new EventEmitter<any>();
  @Output() declineInvitation = new EventEmitter<any>();
  resultToShow: string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.playedMatch ? this.setResultPlayedMatch() : null;
  }

  setResultPlayedMatch() {
    let winnerGoals, lostGoals;
    if (this.playedMatch.localGoals > this.playedMatch.awayGoals) {
      winnerGoals = this.playedMatch.localGoals;
      lostGoals = this.playedMatch.awayGoals;
    } else {
      winnerGoals = this.playedMatch.awayGoals;
      lostGoals = this.playedMatch.localGoals;
    }

    switch(this.playedMatch.result) {
      case 'win':
        this.resultToShow = `¡Ganaste ${winnerGoals} a ${lostGoals}!`;
        break;
      case 'lose':
        this.resultToShow = `Perdiste ${winnerGoals} a ${lostGoals}`;
        break;
      case 'tie':
        this.resultToShow = `Empataste ${ this.playedMatch.localGoals } a ${ this.playedMatch.awayGoals }`;
        break;
    }
  }

  sendNewInvitation() {
    this.router.navigate(['/match/friendly/teams/start-match', this.availableTeam?.id]);
  }

}
