<div class="container">
  <h5>{{ title | transloco: {quantity:objective} }}</h5>
  <div
    class="progress-container"
    [ngClass]="{ 'progress-container-nocoins': !hasCoins }"
  >
    <p class="progress-number">{{ progress }}/{{ objective }}</p>
    <ion-progress-bar class="progress-bar" [value]="progress/objective" [buffer]="1" />
    <div class="coins" >
      <img src="assets/icon/SoccerCoin.svg" *ngIf="hasCoins && !isComplete"/>
      <p *ngIf="hasCoins && !isComplete">+{{ reward }}</p>
      <img class="complete" src="assets/icon/complete-purple.svg" *ngIf="isComplete"/>
    </div>
  </div>
</div>
