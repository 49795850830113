import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  URL_API = environment.apiBase;

  constructor() {
  }
}
