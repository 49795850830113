<div class="card-container">

    <div class="container">
        <!-- Bandera nacionalidad y equipo -->
        <div class="info">
            <div class="exd">
                <h3>{{ card?.player?.media }}</h3>
                <label>{{ card?.player?.short_position }}</label>
            </div>
            <img class="flag" src="/assets/images/countries/ar.png" alt="ar.png">
            <img class="shield" [src]="card?.player?.club?.shieldsimage" alt="ar.png">
        </div>
        <div *ngIf="showSkills" class="skill_info">
            <app-hightlight-skill size="sm" [prefixType]="'icon'"></app-hightlight-skill>
        </div>
        <div *ngIf="card?.contract === 0 || card?.nrocontrato === 0" class="contracts font-size-11">
            <ion-icon name="alert-outline"></ion-icon>
            Contrato vencido
        </div>
        <!-- Profile picture -->
        <img class="profile-picture"
            [src]="card?.player?.profilepicture ?  card?.player?.profilepicture  : '/assets/images/profiles/profilePlayer.png'"
            alt="profilePlayer.png">
        <!-- Name and number of contracts -->
        <div class="footer">
            <strong class="title">{{ card?.player?.lastname | uppercase }}</strong>
            <div class="circle">
                {{ card?.nrocontrato ? card?.nrocontrato : card?.contract }}
            </div>
        </div>
    </div>

    <!-- Card type: Bronze, silver or gold -->
    <img *ngIf="card?.player?.category?.id === 3 || card?.player?.category_id === 3 || card?.player?.category?.id === 3"
        class="card-type card-bronze" src="/assets/images/cards/card-bronze.png" alt="card-bronze.png">
    <img *ngIf="card?.player?.category?.id === 2 || card?.player?.category_id === 2 || card?.player?.category?.id === 2"
        class="card-type card-silver" src="/assets/images/cards/card-silver.png" alt="card-silver.png">
    <img *ngIf="card?.player?.category?.id === 1 || card?.player?.category_id === 1 || card?.player?.category?.id === 1"
        class="card-type card-gold" src="/assets/images/cards/card-gold.png" alt="card-gold.png">
</div>