import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sheet-selector',
  templateUrl: './sheet-selector.component.html',
  styleUrls: ['./sheet-selector.component.scss'],
})
export class SheetSelectorComponent {

  @Input() options: any = [];
  @Input() optionSelected = 0;
  @Input() label = 'Seleccionar:';

  constructor(private modalController: ModalController) {
  }

  selectOption(option: any) {
    this.optionSelected = option.id;
    this.modalController.dismiss(option);
  }

}
