<ion-grid class="ion-padding-vertical">
  <ion-row class="actions-wrapper">
    <ion-col size="12">
      <h6 class="preferences-title">
        <ion-label color="light">Modelo de juego</ion-label>
      </h6>
    </ion-col>
  </ion-row>
  <ion-row class="actions-wrapper">
    <ion-col size="4" class="game-style-col" *ngFor="let item of this.formationService.gameModels; index as i">
      <ion-card color="dark" class="game-style-card" (click)="changeModel(item.id)"
        [ngClass]="{'active-card': this.localTeam?.game_styles?.id == item.id}">
        <span class="ion-text-uppercase"> {{item.name}} </span>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row class="actions-wrapper">
    <ion-col size="12">
      <h6 class="preferences-title">
        <ion-label color="light">Sistema de juego</ion-label>
      </h6>
    </ion-col>
  </ion-row>
  <ion-row class="actions-wrapper">
    <ion-col size="4" class="game-style-col"
      *ngFor="let item of this.formationService.gameSystems; index as i">
      <ion-card color="dark" class="game-style-card" (click)="changeFormation(item)"
        [ngClass]="{'active-card': this.localTeam?.formations[0]?.formations?.id === item.id}">
        <span class="ion-text-uppercase"> {{item.name}} </span>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>