<ion-content scroll-y="false">
  <ion-grid *ngIf="!edit" class="container">
    <ion-row class="content ion-justify-content-center gap-50">
      <ion-col size="12" class="d-flex ion-align-items-center">
        <app-information-shield [keyFromS3]="keyFromS3" [name]="user?.teams[0]?.name">
        </app-information-shield>
      </ion-col>
      <ion-col *ngIf="sponsor" class="d-flex ion-align-items-center" size="12">
        <ion-img [src]="sponsor?.imageUrl"></ion-img>
      </ion-col>
      <ion-col size="12">
        <h2 class="text-white">¡Tenemos un regalo para ti!</h2>
      </ion-col>
    </ion-row>
    <ion-footer class="ion-margin-top ion-padding" size="12">
      <ion-button (click)="play()" expand="block">
        Abrir regalo
      </ion-button>
    </ion-footer>
  </ion-grid>
  <ion-grid *ngIf="edit" class="container">
    <ion-row class="content">
      <ion-col size="12" class="d-flex ion-justify-content-center ion-align-items-center">
        <app-information-shield [keyFromS3]="keyFromS3" [name]="user?.teams[0]?.name">
        </app-information-shield>
      </ion-col>
      <ion-col size="12">
        <h1>¡Wow!</h1>
      </ion-col>
      <ion-col class="ion-margin-top ion-padding" size="12">
        <h3 color="primary" class="subtitle">¡Tu nuevo escudo se ve fantástico!</h3>
      </ion-col>
    </ion-row>
    <ion-footer class="ion-margin-top ion-padding" size="12">
      <ion-button (click)="play()" expand="block">¡A jugar!</ion-button>
      <ion-button (click)="close()" expand="block" fill="outline">Cerrar</ion-button>
    </ion-footer>
  </ion-grid>
</ion-content>
