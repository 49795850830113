import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { GatewayPaymentService } from 'src/app/services/gateway-payment.service';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
import { ICoinPack } from '../../../interfaces/ICoinPack';
import { UsersService } from '../../../services/users.service';
import { CoinPackComponent } from '../../coin-pack/coin-pack.component';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-wallet-modal',
  templateUrl: './wallet-modal.component.html',
  styleUrls: ['./wallet-modal.component.scss'],
})
export class WalletModalComponent implements OnInit {
  user: any;
  coinsPacks: ICoinPack[]

  constructor(public utilsService: UtilsService,
    // private gatewayPaymentService: GatewayPaymentService,
    private productServices: ProductsService,
    private userService: UsersService) {
  }

  async ngOnInit() {
    this.user = this.userService.getCurrentUser();
    const bkArray: string[] = ['bg-standard', 'bg-doble', 'bg-super', 'bg-super-x2', 'bg-deluxe']
    const [_, response] = await to<any>(lastValueFrom(this.productServices.getProducts(null)));
    
    console.log(response.data)
    if (response) {
      this.coinsPacks = response?.data?.coinPacks
      .sort((a, b) => a.quantity - b.quantity)
      .map((item, index) => {
        return {
          ...item,
          bk: bkArray[index], // Agrega la propiedad 'bk' con el valor correspondiente de bkArray para dibujar su correspondioente background
        };
      })
    }
  }

  dismiss() {
    this.utilsService.dismissModal();
  }

  async buyCoin(coin: any, gateway: string = 'openpay') {

    const modal = await this.utilsService.showModal(
      CoinPackComponent,
      [''],
      {
        modal: true,
        pack: coin,
      },
    );
    await modal.present();
    await modal.onDidDismiss().then( ()=>{
      this.utilsService.dismissModal();
    });
    /**
     * esto queda comentado hasta que el cliente
     * defina y nos de las keys de openpay
     */
    // switch (gateway) {
    //   case 'openpay':
    //     if (preference) {
    //       await this.utilsService.showModal(RedirectModalComponent, [''], {
    //         urlRedirect: preference.data?.response?.data?.payment_method.url,
    //       });
    //     }
    //     break;
    //   case 'mercado_pago':
    //     if (preference) {
    //       // Avisar que será redireccionado
    //       // Esperar 5 segundos y redireccionar
    //       // Aviso de redirección
    //       await this.utilsService.showModal(RedirectModalComponent, [''], {
    //         urlRedirect: preference.data?.body?.init_point,
    //       });
    //     }
    //     break;
    //   default:
    //     break;
    // }
  }
}
