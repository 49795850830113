import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { GlobalService } from './global.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  urlApi: string;
  private generalBanners: any[];

  constructor(
    private Http: HttpClient,
    public GlobalService: GlobalService,
    private userService: UsersService,
  ) {
    this.urlApi = this.GlobalService.URL_API;
  }
  
  getBannersByType(type: string): Observable<any>{
    return this.Http.get<any>(`${this.urlApi}/banners/type/${type}/ponderation`);
  }

  getGeneralBanners(): Observable<any>{
    const user = this.userService.getLoggedUser();
    const userClubId = user?.clubs[0]?.id ? user?.clubs[0]?.id : null;
    
    if (this.generalBanners) {
      return of(this.generalBanners);
    } else {
      return this.Http.get<any>(`${this.urlApi}/banners/type/generalBanner/ponderation?clubId=${userClubId}`).pipe(
        tap((res) => {
          this.generalBanners = res;
        })
      );
    }
  }

}