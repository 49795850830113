import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-medium-card',
  templateUrl: './medium-card.component.html',
  styleUrls: ['./medium-card.component.scss'],
})
export class MediumCardComponent implements OnInit {
  @Input() card: any ={
      id: 391,
      lastname: 'Castro',
      profilepicture: 'https://sortitoutsi.net/assets/graphic_styles/cut_out_faces.png',
      technical_skill_offensive: 62,
      technical_skill_passing: 84,
      technical_skill_defense: 86,
      technical_skill_smart: 0,
      technical_skill_dribbling: 75,
      technical_skill_speed: 8,
      technical_skill_physical: 0,
      technical_skill_strenght: 0,
      technical_skill_performance_index: 87,
      technical_skill_counter_attack: 79,
      technical_skill_pressing: 88,
      media: 87
    };

  constructor() { }

  ngOnInit() { }

}
