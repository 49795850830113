import { Component, Input, OnInit } from '@angular/core';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
import { ICoinPack } from 'src/app/interfaces/ICoinPack';
import { GatewayPaymentService } from 'src/app/services/gateway-payment.service';
import { UsersService } from 'src/app/services/users.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-coin-pack',
  templateUrl: './coin-pack.component.html',
  styleUrls: ['./coin-pack.component.scss'],
})
export class CoinPackComponent implements OnInit {

  @Input() pack: ICoinPack;
  @Input() last: boolean = true;
  @Input() modal: boolean = false;
  @Input() classList = 'bg-standard';
  user

  constructor(
    private utilsService: UtilsService, 
    private userService: UsersService,
    private gatewayPaymentService: GatewayPaymentService,
    ) { }

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
  }

  async buy(coin: any, gateway: string = 'openpay') {
    await this.utilsService.showLoading();
    coin.coins = coin.packs_templates.quantity
    // const preference =  // queda comentado para utilizarse despues!
    this.gatewayPaymentService.createPreference(coin, this.user.id, gateway).subscribe(async res =>{
      if(res){
        if(res?.data?.purchase?.coins === 10000){
          const [_, data] = await to<any>(lastValueFrom(
            this.userService.payReward({
              id: this.user.id,
              coins: 500,
              firstReward: false
            })
          ));
          if(data){
            this.utilsService.showToast('Obtuviste 500 monedas de regalo!')
            let coin = data?.data?.coins;
            let user_detail_aux = {
              ...res?.data?.user?.user_detail,
              coins: coin
            };
            this.updateCoins(user_detail_aux, coin);
          }
        } else {
          this.updateCoins(res?.data?.user?.user_detail, res?.data?.user?.user_detail?.coins);
        }
      }
      this.utilsService.dismissLoading();
      this.utilsService.dismissModal();
    })
      
    /**
     * esto queda comentado hasta que el cliente
     * defina y nos de las keys de openpay
     */
    // switch (gateway) {
    //   case 'openpay':
    //     if (preference) {
    //       await this.utilsService.showModal(RedirectModalComponent, [''], {
    //         urlRedirect: preference.data?.response?.data?.payment_method.url,
    //       });
    //     }
    //     break;
    //   case 'mercado_pago':
    //     if (preference) {
    //       // Avisar que será redireccionado
    //       // Esperar 5 segundos y redireccionar
    //       // Aviso de redirección
    //       await this.utilsService.showModal(RedirectModalComponent, [''], {
    //         urlRedirect: preference.data?.body?.init_point,
    //       });
    //     }
    //     break;
    //   default:
    //     break;
    // }
  }

  updateCoins(details, coins){
    this.userService.updateUserDetails(details);
    let coin = coins;
    this.userService.coins$.set(coin);
    this.userService.updateCoins(coin);
    this.utilsService.showToast("Compra realizada con exito!");
  }
}
