/* eslint-disable @typescript-eslint/naming-convention */
export type TagSize = 'sm' | 'md' | 'xl';
export type TagType = 'gold' | 'silver' | 'bronze';
export type PrefixType = 'none' | 'icon' | 'number';

export enum Shorthand {
    CAP = 'CAP',
    OFE = 'OFE',
    FIS = 'FIS',
    DEF = 'DEF',
    PAS = 'PAS',
    PRE = 'PRE',
    DRI = 'DRI',
    PER = 'PER',
    VEL = 'VEL'
};

export interface SkillBox {
    icon: string;
    name: string;
}

export const getSkillInformation = (shorthand: Shorthand): SkillBox => {
    switch (shorthand) {
        case Shorthand.CAP:
            return { icon: 'assets/icon/skill_icons/ic_capacidad.svg', name: 'CAPACIDAD' };
        case Shorthand.OFE:
            return { icon: 'assets/icon/skill_icons/ic_ofensiva.svg', name: 'OFENCIVA' };
        case Shorthand.FIS:
            return { icon: 'assets/icon/skill_icons/ic_fisico.svg', name: 'FISICO' };
        case Shorthand.DEF:
            return { icon: 'assets/icon/skill_icons/ic_defensa.svg', name: 'DEFENSA' };
        case Shorthand.PAS:
            return { icon: 'assets/icon/skill_icons/ic_pases.svg', name: 'PASES' };
        case Shorthand.PRE:
            return { icon: 'assets/icon/skill_icons/ic_precision.svg', name: 'PRECISIÓN' };
        case Shorthand.DRI:
            return { icon: 'assets/icon/skill_icons/ic_bribbling.svg', name: 'BRIBBLING' };
        case Shorthand.PER:
            return { icon: 'assets/icon/skill_icons/ic_performance.svg', name: 'PERFORMANCE' };
        case Shorthand.VEL:
            return { icon: 'assets/icon/skill_icons/ic_velocidad.svg', name: 'VELOCIDAD' };
    }
};

