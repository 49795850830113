import { UtilsService } from 'src/app/services/utils.service';
import { AfterViewInit, Component, OnInit, NgZone } from '@angular/core';
import { DeviceUtils } from './utils/device';
import { AnimationOptions } from 'ngx-lottie';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';
import { App, URLOpenListenerEvent } from '@capacitor/app';

register();

declare const gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  options: AnimationOptions = {
    path: '/assets/spinners/spinnerAnimate.json',
  };

  constructor(
    public deviceUtils: DeviceUtils,
    private router: Router,
    private zone: NgZone,
    private UtilsService: UtilsService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
      });

    this.initializeApp();
  }

  async ngOnInit() {
    await this.deviceUtils.getDevice();
  }

  ngAfterViewInit(): void {}

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            //exampleurl: "https://ksport.com/splash" ---> this is the urlapp that triggers the manifest
            //slug = "/splash" --> This is the route, that we will use in the routerManager

            console.log("URL EVENT", event.url);
            const slug = event.url.split(".com").pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }else{ 
              // this.UtilsService.goPage("sign-in")
              console.log("@@@@ ===> URL Mismatch");
            }
        });
    });
}
}
