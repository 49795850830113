import { Component, Input, OnInit } from '@angular/core';
import { ICard } from 'src/app/interfaces/ICard';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-golden-card',
  templateUrl: './golden-card.component.svg',
  styleUrls: ['./golden-card.component.scss'],
})
export class GoldenCardComponent implements OnInit {
  cardId: string;
  gPath1: string;
  @Input() card: ICard;
  constructor(
    private utilsService: UtilsService
  ) {
    this.cardId = `${utilsService.randomHash(8)}-g-path-1`
    this.gPath1 = `#${this.cardId}`
  }
  ngOnInit() {
    console.log("CONTRACT", this.card)
  }

}
