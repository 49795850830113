<div class="skill_box" [ngClass]="['skill_color_' + type, 'skill_padding_' + size, 'skill_box_' + size]">

  <div class="skill_prefix" [ngSwitch]="prefixType">
    <div *ngSwitchCase="'icon'">
      <ion-img class="skill_icon" [src]="skill?.icon"></ion-img>
    </div>
    <div *ngSwitchCase="'number'">
      <strong class="skill_number">{{ skillValue < 10?  '0' + skillValue: skillValue || 0 }}</strong>
    </div>
  </div>

  <div class="skill_body" [ngClass]="['skill_body_' + size]" [ngSwitch]="size">
    <div *ngSwitchCase="'lg'">
      <div>
        {{ skill?.name }}
      </div>
    </div>
    <div *ngSwitchCase="'md'">{{ skill?.name }}</div>
    <div *ngSwitchDefault>
      {{ shorthand }}
    </div>
  </div>

  <div class="skill_footer">

  </div>
</div>