import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ICreateTeam } from '../interfaces/ICreateTeam';
import { GlobalService } from './global.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  urlApi: string;

  createClubForm = this.formBuilder.group({
    id: ['', [Validators.required]],
    name: ['', [Validators.required]],
    shieldsimage: ['', [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public globalService: GlobalService,
    public utilsService: UtilsService
  ) {
    this.urlApi = this.globalService.URL_API;
  }

  getcreateClubForm() {
    return this.createClubForm;
  }

  createTeam(team: ICreateTeam): Observable<any> {
    return this.http.post<any>(
      `${this.urlApi}/create-team`,
      JSON.stringify(team)
    );
  }

  updateTeam(team: ICreateTeam): Observable<any> {
    return this.http.put<any>(
      `${this.urlApi}/teams`,
      JSON.stringify(team)
    );
  }

  updateTeamShield(shield: any): Observable<any> {
    return this.http.put<any>(
      `${this.urlApi}/teams/shield`,
      shield
    );
  }

  getTeamById(id: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/teams/${id}`);
  }

  getTeamInformation(id: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/get-team-all-id/${id}`);
  }

  /**
   *
   * @param teamId
   * @param page Conditional - nro pagina
   * @param take Conditional - nro de cards por pagina
   * @param getAll Conditional - traer todos los cards
   * @returns ICard ICard pero conviene utilizar la variable playerListService.teamCards
   * si no es de vital importancia refrescar el listado de cards
   */
  getTeamCards(
    teamId: number,
    getAll?: boolean,
    page?: number,
    take?: number
  ): Observable<any> {
    let params = `${teamId}`;
    if (!!page) {
      params += `?page=${page}`;
    }
    if (!!page && !!take) {
      params += `&take=${take}`;
    }
    if (!!getAll) {
      params += `?getall=true`;
    }

    return this.http.get<any>(`${this.urlApi}/my-team-cards/${params}`);
  }

  availablePlayers(
    userId: number,
    page: number,
    take?: number,
    searchValue?: string,
    selectedListIds?: any[]
  ) {
    let query = new HttpParams();
    query = page ? query.append('page', page) : query;
    query = query.append('take', take ?? 10);
    query = query.append('userId', userId);
    if (searchValue) {
      query = query.append('searchValue', searchValue);
    }
    if (selectedListIds && selectedListIds.length > 0) {
      const idsString = selectedListIds.join(',');
      query = query.append('selectedListIds', idsString);
    }

    return this.http.get<any>(`${this.urlApi}/teams/available`, {
      params: query,
    });
  }

  getChemistryPlayer(entryPlayerFormation: any, outPlayerFormation: any): 'success' | 'warn' | 'danger' {
    if (entryPlayerFormation?.type === outPlayerFormation?.type &&
      entryPlayerFormation?.name === outPlayerFormation?.name) {
      return 'success';
    } else if (entryPlayerFormation.type === outPlayerFormation?.type ||
      entryPlayerFormation.name === outPlayerFormation?.name) {
      return 'warn';
    }
    return 'danger';
  }

  playersWithoutContracts(id: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/teams/lineup/${id}/contract`);
  }
}
