<ion-row class="ion-align-items-center ion-no-margin">
  <ion-col size="6" class="general-performance-small">
    <ion-row>
      <ion-col size="12" class="column-highlights">
        <ion-label class="team-highlights">PERFORMANCE:</ion-label>
        <ion-label color="primary" class="general-performance-big"> 89 <span class="team-highlights">%</span></ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-progress-bar value="0.89"></ion-progress-bar>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6" class="general-performance-small">
    <ion-row>
      <ion-col size="12" class="column-highlights">
        <ion-label class="team-highlights">QUÍMICA:</ion-label>
    <ion-label color="primary" class="general-performance-big"> 75 <span class="team-highlights">%</span></ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-progress-bar value="0.75" ></ion-progress-bar>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
