import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormationsService } from 'src/app/services/formations.service';
import { TeamService } from 'src/app/services/team.service';
import { UsersService } from 'src/app/services/users.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-game-system-and-model',
  templateUrl: './game-system-and-model.component.html',
  styleUrls: ['./game-system-and-model.component.scss'],
})
export class GameSystemAndModelComponent  implements OnInit {

  user;
  @Input() localTeam;
  @Output() changeForm = new EventEmitter<void>();

  constructor(
    public formationService: FormationsService,
    private utilsService: UtilsService,
    private teamService: TeamService,
    private userService: UsersService,
  ) { }

  ngOnInit() {}

  initTeamFormationData() {
    this.utilsService.showLoading();
    this.user = this.userService.getLoggedUser();
    this.teamService
      .getTeamInformation(this.user.teams[0].id)
      .subscribe((response) => {
        this.localTeam = response.data;
        this.formationService.refreshTeamInformation(response.data);
        this.formationService.setModel(this.localTeam.current_styles_id);
        this.formationService.loadFormation(
          this.localTeam?.current_formations?.name
        );
        this.utilsService.dismissLoading();
        this.changeForm.emit();
      });
  }

  changeFormation(formation: any) {
    this.formationService.changeFormation(formation).subscribe((_) => {
      this.formationService.loadFormation(formation.name);
      this.initTeamFormationData();
    });
  }

  changeModel(type: any) {
    this.formationService.changeModelSubscription(type).subscribe((_) => {
      this.initTeamFormationData();
    });
  }

}
