import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { GlobalService } from './global.service';
import { IApiGenericResponse } from '../interfaces/IApiResponse';
import { IAvailableTeam } from '../interfaces/IAvailableTeam';
import { IQueryCount } from '../interfaces/IQueryCount';
import { IInvitation } from '../interfaces/IInvitation';
import { ISeason } from '../interfaces/ISeason';
import { IStreak } from '../interfaces/IStreak';
import { IStreakCount } from '../interfaces/IStreakCount';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class MatchService {
  urlApi: string;

  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
    private notificationService: NotificationsService
  ) {
    this.urlApi = this.globalService.URL_API;
  }

  prepareMatch(challenger: number, challenged: number, invitationId: string): Observable<any> {
    const payload = {
      challenger,
      challenged,
      invitationId
    };
    return this.http.post<any>(`${this.urlApi}/challenge/player`, payload);
  }

  createTournamentMatch(localUserId, awayUserId, isTournament) {
    return this.getTeamActiveSeassonByTeamId(localUserId).pipe(
      switchMap((activeSeasson) => {
        const payload = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          away_team_id: awayUserId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          local_team_id: localUserId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          tournament_id: activeSeasson.data.tournament_id, // Suponiendo que el ID del torneo se encuentra en `activeSeasson.id`
        };
        this.notificationService.subscribeToTopic(`${payload.tournament_id}`);
        return this.http.post<any>(`${this.urlApi}/create-match`, payload);
      })
    );
  }

  match(id: number, matchToken: string): Observable<any> {
    const data = {
      iduser: id,
      matchtoken: matchToken,
    };
    console.log(data);
    return this.http.post<any>(`${this.urlApi}/invitation-match`, data);
  }

  play(data: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/matchs/play`, data);
  }

  availablePlayers(): Observable<IApiGenericResponse<IAvailableTeam[]>> {
    return this.http.get<IApiGenericResponse<IAvailableTeam[]>>(
      `${this.urlApi}/match/teams`
    );
  }

  pendingPlayers(
    userId: number
  ): Observable<IApiGenericResponse<IInvitation[]>> {
    let query = new HttpParams();
    query = userId ? query.append('userId', userId) : query;
    return this.http.get<IApiGenericResponse<IInvitation[]>>(
      `${this.urlApi}/match/pending`,
      {params: query}
    );
  }

  pendingPlayersAsAwayTeam(
    userId: number
  ): Observable<IApiGenericResponse<IAvailableTeam[]>> {
    let query = new HttpParams();
    query = userId ? query.append('userId', userId) : query;
    return this.http.get<IApiGenericResponse<IAvailableTeam[]>>(
      `${this.urlApi}/match/away/pending`,
      {params: query}
    );
  }

  pendingPlayersAsAwayTeamCount(userId: number): Observable<IApiGenericResponse<IQueryCount>> {
    let query = new HttpParams();
    query = userId ? query.append('userId', userId) : query;
    return this.http
      .get<IApiGenericResponse<IQueryCount>>(`${this.urlApi}/match/away/pending/count`, {params: query});
  }

  pendingPlayersCount(
    userId: number
  ): Observable<IApiGenericResponse<IQueryCount>> {
    let query = new HttpParams();
    query = userId ? query.append('userId', userId) : query;
    return this.http.get<IApiGenericResponse<IQueryCount>>(
      `${this.urlApi}/match/pending/count`,
      {params: query}
    );
  }

  updateMatchToReady(invitationId: string) {
    return this.http.get(
      `${this.urlApi}/invitations/update-status/${invitationId}`
    );
  }

  getSeasonById(
    tournamentId: number,
    teamId: number
  ): Observable<IApiGenericResponse<ISeason>> {
    return this.http.get<IApiGenericResponse<ISeason>>(
      `${this.urlApi}/tournament/${tournamentId}/player/${teamId}`
    );
  }

  getTeamActiveSeassonByTeamId(
    teamId: number
  ): Observable<IApiGenericResponse<ISeason>> {
    return this.http.get<IApiGenericResponse<ISeason>>(
      `${this.urlApi}/tournament/player/${teamId}`
    );
  }

  getStreakOfTheLastGamesByTeamId(id: number, take?: number, tournament_id?: number): Observable<IApiGenericResponse<IStreak[]>> {
    let params = new HttpParams();
    if (take !== undefined) {
      params = params.append('take', take.toString());
    } else {
      take = 10
    }
    
    if (tournament_id !== undefined) {
      params = params.append('tournament_id', tournament_id.toString());
    }
    return this.http.get<IApiGenericResponse<IStreak[]>>(
      `${this.urlApi}/matchs/last-streak/${id}`, { params: params }
    );
  }

  getStatsOfLastGamesByTeamId(id: number): Observable<IApiGenericResponse<IStreakCount>> {
    return this.http.get<IApiGenericResponse<IStreakCount>>(
      `${this.urlApi}/matchs/last-streak/${id}/count`
    );
  }

  getFrirendlyMatchesByTournamentId(tournamentId: number, teamId: number){
    const params = new HttpParams().set('teamId', teamId.toString());
    return this.http.get<any>(
      `${this.urlApi}/get-frirendly-matchs/${tournamentId}`, { params }
    );
  }

  createMatch(matchData: any): Observable<any> {
    console.log(matchData);
    return this.http.post<any>(`${this.urlApi}/create-match`, matchData);
  }
}
