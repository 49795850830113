import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { UsersService } from "src/app/services/users.service";
import { UtilsService } from "src/app/services/utils.service";
import { WalletModalComponent } from "../wallet-modal/wallet-modal.component";

@Component({
  selector: "app-training-team-modal",
  templateUrl: "training-team-modal.component.html",
  styleUrls: ["training-team-modal.component.scss"],
})
export class TrainingTeamModalComponent implements OnInit {
  @Input() days = [];
  @Input() showTrainingDays: boolean = false;
  @Input() consecutiveDays: number = 1;
  @Input() hasToRecoverTraining: boolean = false;
  @Input() isOpenByUser = false;
  @Input() hadTrainingToday = false;
  @Input() fullTrainingRewards = [];
  public recoverCost: number;
  public user: any;
  constructor(
    private modalController: ModalController,
    private router: Router,
    private usersService: UsersService,
    private utilsService: UtilsService,
  ) {}

  async ngOnInit() {
    this.user = await this.usersService.getCurrentUser();
    if (this.hasToRecoverTraining) {
      const firstMissedDayIndex = this.days.findIndex(
        (item) => item.status === "missed",
      );
      this.recoverCost =
        this.fullTrainingRewards[firstMissedDayIndex].recoverCost;
    }
  }

  close() {
    this.modalController.dismiss();
  }

  onClick(hasToRecover: boolean) {
    if (hasToRecover) {
      if (this.user.user_detail.coins > this.recoverCost) {
        this.router.navigate(["/training", { isRecover: true }], {
          replaceUrl: true,
        });
        this.hadTrainingToday = false;
        this.modalController.dismiss();
      } else {
        this.utilsService.showToast("Necesitas más monedas para recuperar entrenamiento. ¿Quieres recargar ahora?")
        this.modalController.dismiss();
        this.utilsService.showModal(WalletModalComponent);
      }
    } else {
      this.router.navigate(["/training"], {
        replaceUrl: true,
      });
      this.hadTrainingToday = false;
      this.modalController.dismiss();
    }
  }
}
