<ion-content>
  <div class="container">
    <ng-container *ngIf="hasToRecoverTraining && !isOpenByUser; else title">
      <div class="red-card">
        <img src="/assets/icon/red-card.svg" alt="" />
      </div>
      <div class="text-small">Perdiste el progreso de tu entrenamiento</div>
      <div class="text font-24">Recupéralo por</div>
      <div class="btn-container">
        <button class="btn">
          <img src="assets/SoccerCoin.svg" alt="" width="30" height="30" />
          <span class="btn-text">{{ recoverCost }}</span>
        </button>
      </div>
    </ng-container>

    <ng-template #title>
      <h2 class="title">Mejora tu rendimiento</h2>

      <div class="box">
        <p class="text">Entrena a tu equipo cada día y recibe recompensas</p>
      </div>

      <p class="text" *ngIf="!showTrainingDays; else consecutiveDay">
        ¡Sumarás más oportunidades de obtener una victoria!
      </p>
    </ng-template>

    <ng-template #consecutiveDay>
      <div class="days-consecutive">
        <span class="trained">
          <img src="/assets/icon/balon.svg" alt="" />
        </span>
        <span class="trained-text"> {{ consecutiveDays }} </span>
      </div>
      <div class="text-small">Días de entrenamiento</div>
      <div class="text-trained">
        Recuperar por
        <button class="btn">
          <img src="assets/SoccerCoin.svg" alt="" width="30" height="30" />
          <span class="btn-text">{{ recoverCost }}</span>
        </button>
      </div>
    </ng-template>

    <div class="balls-container">
      <div
        class="day-container"
        *ngFor="let day of days; let i = index"
        [ngStyle]="!day.date && { opacity: '0.25' }"
      >
        <span
          class="number"
          [style.color]="
            !day.isPassed
              ? '#ffffff'
              : day.status === 'trained'
              ? '#A1E100'
              : '#EB3525'
          "
          >{{ i + 1 }}</span
        >
        <img
          src="/assets/icon/balon.svg"
          alt=""
          *ngIf="!day.isPassed; else passed"
        />
        <ng-template #passed>
          <ng-container>
            <div
              class="circle"
              [style.background]="
                day.status === 'trained' ? '#A1E100' : '#EB3525'
              "
            >
              <ion-icon
                class="icon"
                [name]="
                  day.status === 'trained'
                    ? 'checkmark-outline'
                    : 'close-outline'
                "
              ></ion-icon>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>

    <ion-button
      expand="block"
      class="mb-25"
      [disabled]="hadTrainingToday"
      (click)="onClick(hasToRecoverTraining)"
      >{{
        hasToRecoverTraining
          ? "Recuperar entrenamiento"
          : "Entrenar a mi equipo"
      }}</ion-button
    >
    <ion-button expand="block" fill="outline" color="light" (click)="close()">{{
      isOpenByUser ? "Cerrar" : "Omitir"
    }}</ion-button>
  </div>
</ion-content>
