<!-- <ion-header>
  <app-general-banner></app-general-banner>
  <ion-toolbar>
    <ion-icon slot="start" name="arrow-back-outline" (click)="dismiss()" color="light">
    </ion-icon>

  </ion-toolbar>
</ion-header> -->
<app-headers
      [user]="user"
      typeHeader="sponsor&shield"
      [back]="true"
      (backButton)="dismiss()"
      title="Compra Coins"
></app-headers>
<ion-content>
  <ion-grid class="container"*ngIf="user?.user_detail">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12">
        <ion-card color="dark" class="ml-0 mr-0">
          <div class="ion-text-leftx ion-padding-top ion-margin-bottom">
            <div class="coins-card-content" >
              <span class="text-gray">Tus coins disponibles:</span>
              <h2>
                <ion-icon src="assets/SoccerCoin.svg" color="primary" class="coin-icon">
                </ion-icon>
                {{ user?.user_detail?.coins }}
              </h2>
            </div>
          </div>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>
  <ion-grid class="container">
    <ion-row class="mt-25">
      <ion-col size="12">
        <ion-label>Packs disponibles</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="mt-25">
      <ion-col *ngFor="let coinPack of coinsPacks; let last = last;" [size]="last ? 12 : 6" class="coin-container">
        <app-coin-pack (click)="buyCoin(coinPack)" [pack]="coinPack" [last]="last" [classList]="coinPack?.bk"></app-coin-pack>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
