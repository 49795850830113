import { Component, OnInit } from '@angular/core';
import { MatchService } from '../../services/match.service';
import { UsersService } from '../../services/users.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-bell-notification',
  templateUrl: './bell-notification.component.html',
  styleUrls: ['./bell-notification.component.scss'],
})
export class BellNotificationComponent implements OnInit {
  notification$ = this.notificationService.notificationCount().toString();

  constructor(public usersService: UsersService,
              private notificationService: NotificationsService,
              private utilsService: UtilsService
              ) { }

  ngOnInit() {
    this.updateNotification();
    this.notificationService.setBellNotificationComponent(this)
  }

  async ionViewDidEnter() {
  }

  updateNotification() {
    const user = this.usersService.getCurrentUser();
    this.notification$ = this.notificationService.notificationCount().toString()
  }

  async goPage() {
    this.utilsService.goPage('/notifications')
  }
}
