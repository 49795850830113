import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { GlobalService } from '../../../services/global.service';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-discount-modal',
  templateUrl: './discount-modal.component.html',
  styleUrls: ['./discount-modal.component.scss'],
})
export class DiscountModalComponent {
  URL_COUPON = this.globalService.URL_API + '/coupons';
  inputValue: string = '';
  hasError: boolean = false;

  constructor(
    private modalController: ModalController,
    private http: HttpClient,
    private globalService: GlobalService
  ) {}

  async onSubmit() {
    const [_, res] = await to<any>(
      lastValueFrom(this.http.get(`${this.URL_COUPON}`))
    );
    const coupon = res.data.find((item) => item.code === this.inputValue);
    if (coupon === undefined || new Date(coupon.endDate) < new Date() || coupon.status || coupon.deletedAt === null ) {
      this.hasError = true;
      return;
    }
    this.modalController.dismiss(coupon);
  }

  onInputTime(a: any) {
    this.inputValue = a.detail.value;
  }

  onCancel() {
    this.modalController.dismiss();
  }
}
