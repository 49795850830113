import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WhenLoadingPipe } from './pipes/when-loading.pipe';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    NameInitialsPipe,
    WhenLoadingPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule
  ],
  exports: [
    NameInitialsPipe,
    WhenLoadingPipe
  ],
})
export class SharedModule { }
