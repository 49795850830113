export const environment = {
  production: true,
  apiBase: 'https://spe0fxjzwi.execute-api.us-east-1.amazonaws.com/staging',
  apiGraphql: 'https://ia5ftefwene6bfxkia6qsxipou.appsync-api.us-east-1.amazonaws.com/graphql',
  appsyncApikey: 'da2-ksudi7yyunbkjdewat2kdiffji',
  publicKeyMercadopago: 'TEST-b49fd008-941b-494e-a423-afbd4f1bf8ef',
  appBase: 'http://staging-web.ksport.com.s3-website-us-east-1.amazonaws.com',
  authGoogleUrl: 'https://ksport-staging-domain.auth.us-east-1.amazoncognito.com',
  authGoogleClientId: '42fl32vh18cjpbsgt4559ib1qj',
  authGoogleScope: 'aws.cognito.signin.user.admin+email+openid+phone',
  googleRedirectUri: 'https://d32vectt122wbw.cloudfront.net/sign-in-google',
};
