import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-payment-error-modal',
  templateUrl: './payment-error-modal.component.html',
  styleUrls: ['./payment-error-modal.component.scss'],
})
export class PaymentErrorModalComponent implements OnInit {
  /**
   * This component receive an url and make the redirect after asking if user agree
   * 
   */

  @Input() urlRedirect: string = '';
  timer = null;

  constructor(
    private utilsService: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.timer = setTimeout(() => {
      window.location.href = this.urlRedirect;
    }, 5000);
  }

  dismiss() {
    clearTimeout(this.timer);
    this.utilsService.dismissModal();
  }

}
