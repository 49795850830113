import { Component, Input, OnInit } from '@angular/core';
import { PackService } from '../../../services/pack.service';
import { UsersService } from '../../../services/users.service';
import to from 'await-to-js';
import { empty, lastValueFrom } from 'rxjs';
import { IPack } from '../../../interfaces/IPack';
import { UtilsService } from '../../../services/utils.service';
import { ProductsService } from 'src/app/services/products.service';
import { VideoPlayerComponent } from '../../video-player/video-player.component';
import { SwiperOptions } from 'src/app/interfaces/ISwiperOptions';

@Component({
  selector: 'app-buy-contract-player-modal',
  templateUrl: './buy-contract-player-modal.component.html',
  styleUrls: ['./buy-contract-player-modal.component.scss'],
})
export class BuyContractPlayerModalComponent implements OnInit {
  @Input() card: any;
  user: any = null;
  productContracts: any[] = [];
  auxTotal: any[] = [{}, {}, {}, {}];
  slideOptsPacks: SwiperOptions = {
    initialSlide: 1,
    slidesPerView: 1,
    loop: false,
    scrollbar: true,
  };

  constructor(
    private packService: PackService,
    private userService: UsersService,
    private utilsService: UtilsService,
    private productServices: ProductsService
  ) { }

  async ngOnInit() {
    await this.loadUserPacks();
  }

  async loadUserPacks() {
    this.user = this.userService.getLoggedUser();
    this.utilsService.showLoading();
    const [_, response] = await to(
      lastValueFrom(this.packService.contractPacks())
    );
    if (response) {
      console.log('response.data', response.data);
      const filteredPacks = response.data.filter((pack) => {
        const firstTemplate = pack.packs_templates;
        return firstTemplate && firstTemplate.type === 6;
      });
      console.log('filter', filteredPacks);
  
      // inicializa auxTotal con objetos vacíos
      for (let i = 0; i < filteredPacks.length; i++) {
        this.auxTotal.push({});
      }
      let currentIndex = 0
      this.productContracts = response.data
      this.productContracts.unshift(
        {
          id: null,
          fechaapertura: null,
          user_id: null,
          packtemplate_id: null,
          packs_templates: null,
          packs_details: null
        }
      )
      console.log('auxTotal', this.auxTotal);
      console.log('products', this.productContracts);
      
      this.utilsService.dismissLoading()
    }
  }


  async buyPackAdvertisment(): Promise<number> {
    console.log('USER:', this.user);

    const product = {
      packtemplate_id: 99,
      user_id: this.user.id,
      price: 0,
      quantity: 1,
    };

    const [_, response] = await to<any, any>(
      lastValueFrom(this.productServices.pay(product, false))
    );

    return response.data.id;
  }

  async renew(pack: any, isAds?: boolean) {
    event.preventDefault()
    const title = isAds ? '¡Publicidad reward!' : '¡Atención!';
    const message = isAds
      ? 'Se acreditara 1 contrato para tu jugador si miras la publicidad'
      : `Esta seguro de comprar los ${pack?.packs_templates?.quantity + ' contratos' || 1 + ' contrato'
      }`;
    
      console.log(isAds)

    const shouldProceed = await this.utilsService.showPrompt(
      title,
      message,
      isAds ? "Mirar anuncio": "Comprar contratos",
      'Cerrar'
    );

    if (!shouldProceed) {
      return;
    }

    if (isAds) {
      const modal = await this.utilsService.showModal(
        VideoPlayerComponent,
        [],
        {
          card: this.card,
          canSkip: true
        }
      );
      const { data } = await modal.onDidDismiss();
      this.utilsService.dismissModal(data);
    } else {
      this.utilsService.showLoading()
      const payload = {
        id: pack.id,
        cardId: this.card.code,
        teamId: this.user.teams[0].id,
        packId: pack.id,
        price: pack.price,
        user_id: this.user.id,
        packtemplate_id: pack.packtemplate_id
      };

      console.log(payload)
      const [_, response] = await to(
        lastValueFrom(this.productServices.pay(payload, false))
      );
      if (response) {
        console.log("RESPONSE",response)
        let res: any = response
        this.userService.coins$.set(res?.data?.users?.user_detail?.coins)
        this.userService.updateUserDetails({...res?.data?.users?.user_detail})
        await this.userService.coinsComponent.updateCoins().then( (t)=>{
          this.utilsService.dismissModal(res);
          this.utilsService.dismissLoading();
        })
      }
    }
  }

  goMarket() {
    this.utilsService.dismissModal(true);
    this.utilsService.goPage('market');
  }

}
