import { Component, Input, OnInit } from '@angular/core';
import { ITablePositionTeam } from 'src/app/interfaces/ITablePositionTeam';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-positions-table',
  templateUrl: './positions-table.component.html',
  styleUrls: ['./positions-table.component.scss'],
})
export class PositionsTableComponent implements OnInit {

  @Input() tableTitle: string;
  @Input() teams: any//ITablePositionTeam[];
  @Input() zoneLimit: number;
  constructor(
    private utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.teams = this.utilsService.sortObjectArray(this.teams, false, 'position');
  }

}
