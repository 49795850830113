import { Component, Input } from '@angular/core';
import { ITournament } from '../../interfaces/ITournament';
import { ZonePointsEnum } from 'src/app/enums/zone';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() seasonPoint = 0;
  @Input() tournament: ITournament;

  zoneKeys = Object.keys(ZonePointsEnum) as (keyof typeof ZonePointsEnum)[];
  zones = ZonePointsEnum;
  current: string;

  constructor() {}

  logoTop() {
    let top = 0;
    let currentKey = null;
    if (this.tournament) {
      top = (this.seasonPoint * 80) / 30;
      for (const zoneKey of this.zoneKeys ) {
        if (this.seasonPoint >= this.tournament[zoneKey]) {
          currentKey = zoneKey;
        }
      }
    }
    this.current = currentKey;
    return top.toString() + '%';
  }
}
