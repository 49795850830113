export class AppConstants {
  public static noLoadingRequired = ["users", "match/away/pending/count"];

  // Unsecured operations that shouldn't be subject to adding auth tokens go here
  public static noTokenNeedRequests: any = [
    "getFields",
    "signIn",
    "signUp",
    "editUser",
    "getCountries",
    "getUsers",
  ];

  public static errorMessages: any = {
    name: [
      { type: "required", message: "Campo requerido" },
      { type: "maxlength", message: "El nombre es muy largo" },
    ],
    lastname: [
      { type: "required", message: "Campo requerido" },
      { type: "maxlength", message: "El apellido es muy largo" },
    ],
    province: [{ type: "required", message: "Campo requerido" }],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    locality_id: [{ type: "required", message: "Campo requerido" }],
    email: [{ type: "required", message: "Campo requerido" }],
    birthday: [{ type: "required", message: "Campo requerido" }],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    club_users: [{ type: "required", message: "Campo requerido" }],
    areacode: [{ type: "required", message: "Campo requerido" }],
    referredby: [{ type: "required", message: "Campo requerido" }],
    phonenumber: [{ type: "required", message: "Campo requerido" }],
    password: [
      { type: "required", message: "Campo requerido" },
      {
        type: "pattern",
        message:
          "La contraseña debe ser de almenos 8 caracteres, una minuscula, una mayuscula y un numero",
      },
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    password_confirm: [
      { type: "required", message: "Campo requerido" },
      { type: "mismatch", message: "Las contraseñas deben ser iguales" },
      {
        type: "pattern",
        message:
          "La contraseña debe ser de almenos 8 caracteres, una minuscula, una mayuscula y un numero",
      },
    ],
    gender: [{ type: "required", message: "Campo requerido" }],
  };

  public static keys: any = {
    user: "user",
    userRemember: "userRemember",
    token: "idToken",
    accessToken: "accessToken",
    refreshToken: "refreshToken",
    lang: "lang",
  };

  public static headers: any = {
    authorization: "Authorization",
    accessToken: "Access-Token",
  };

  public static misc: any = {
    file: {
      urlCheck: "http",
      urlSafeCheck: "https",
    },
  };

  public static formats: any = {
    date: "YYYY-MM-DD",
  };

  public static advs: string[] = [
    "./assets/gif-adv/gif-mastercard.gif",
    "./assets/gif-adv/gif-nike.gif",
    "./assets/gif-adv/gif-oreo.gif",
    "./assets/gif-adv/gif-spotify.webp",
  ];

  public static golSound: string[] = [
    "./assets/sound/gol-gol.mp3",
    "./assets/sound/gol-de-river.mp3",
    "./assets/videos/gol.mp4",
  ];

  public static passSound: string[] = [
    "./assets/sound/pase1.mp3",
    "./assets/sound/pase2.mp3",
    "./assets/sound/pase3.mp3",
  ];

  public static logos: any = [
    {
      id: 1,
      url: "./assets/images/logos/logo1.svg",
      svg: `<svg width="54" height="71" viewBox="0 0 54 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.341 7.64564C34.7897 7.64564 29.9505 4.64239 27.3561 0.184937C27.2098 -0.0616457 26.8474 -0.0616457 26.7075 0.184937C24.1321 4.70562 19.2612 7.76577 13.659 7.76577C8.05676 7.76577 3.30664 4.79414 0.705841 0.368293C0.508714 0.0395165 0 0.178614 0 0.564295V44.0514C0 58.2963 24.6726 69.9615 26.8474 70.9668C26.9491 71.0111 27.0572 71.0111 27.1526 70.9668C29.3274 69.9678 54 58.2963 54 44.0514V0.444165C54 0.064807 53.4913 -0.0806136 53.2942 0.248163C50.6934 4.67401 45.8669 7.64564 40.341 7.64564Z" fill="#EDEDED"/>
      </svg>`,
      siluete: "M40.341 7.64564C34.7897 7.64564 29.9505 4.64239 27.3561 0.184937C27.2098 -0.0616457 26.8474 -0.0616457 26.7075 0.184937C24.1321 4.70562 19.2612 7.76577 13.659 7.76577C8.05676 7.76577 3.30664 4.79414 0.705841 0.368293C0.508714 0.0395165 0 0.178614 0 0.564295V44.0514C0 58.2963 24.6726 69.9615 26.8474 70.9668C26.9491 71.0111 27.0572 71.0111 27.1526 70.9668C29.3274 69.9678 54 58.2963 54 44.0514V0.444165C54 0.064807 53.4913 -0.0806136 53.2942 0.248163C50.6934 4.67401 45.8669 7.64564 40.341 7.64564Z"
    },
    {
      id: 2,
      url: "./assets/images/logos/logo2.svg",
      svg: `<svg width="57" height="76" viewBox="0 0 57 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.07387V47.7836C0 63.365 28.5 76 28.5 76C28.5 76 57 63.365 57 47.7836V9.07387L28.5 0L0 9.07387Z" fill="#EDEDED"/>
      </svg>`,
      siluete: "M0 9.07387V47.7836C0 63.365 28.5 76 28.5 76C28.5 76 57 63.365 57 47.7836V9.07387L28.5 0L0 9.07387Z"
    },
    {
      id: 3,
      url: "./assets/images/logos/logo3.svg",
      svg: `<svg width="69" height="81" viewBox="0 0 69 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M61.4018 33.1748C60.0694 29.7171 59.6546 27.015 61.3578 23.5444C62.7656 20.6822 65.8576 17.9288 69 17.0836C67.4288 13.2417 65.3171 10.1682 62.6336 7.73503C60.3711 10.943 56.6819 13.0432 52.5089 13.0432C45.6208 13.0432 40.0337 7.35084 40.0337 0.332977C40.0337 0.275348 40.04 0.224117 40.04 0.166488C38.2677 0.0640379 36.4325 0 34.5031 0C32.4669 0 30.5375 0.0640308 28.6835 0.179288C28.6835 0.230513 28.6898 0.281752 28.6898 0.332977C28.6898 7.35084 23.1027 13.0432 16.2146 13.0432C12.1107 13.0432 8.47809 11.0134 6.20302 7.89511C3.60114 10.3027 1.53976 13.3186 0 17.0836C3.14865 17.9288 6.24073 20.6822 7.64223 23.5444C9.34539 27.015 8.93688 29.7171 7.59823 33.1748C6.42299 36.2163 5.5934 39.4051 5.17861 42.6451C4.34274 49.1699 5.20375 56.1557 9.01229 61.6368C12.1107 66.1062 16.8493 68.7571 21.4623 71.312C23.838 72.6247 26.2199 73.9309 28.4384 75.4997C29.0229 75.9095 34.1386 79.9371 34.4906 81C34.8425 79.9371 39.9646 75.9095 40.5428 75.4997C42.7613 73.9309 45.1432 72.6183 47.5188 71.312C52.1381 68.7635 56.8705 66.1062 59.9689 61.6368C63.7711 56.1557 64.6384 49.1699 63.8025 42.6451C63.3877 39.4051 62.5582 36.2163 61.3829 33.1748H61.4018Z" fill="#EDEDED"/>
      </svg>
      `,
      siluete: "M61.4018 33.1748C60.0694 29.7171 59.6546 27.015 61.3578 23.5444C62.7656 20.6822 65.8576 17.9288 69 17.0836C67.4288 13.2417 65.3171 10.1682 62.6336 7.73503C60.3711 10.943 56.6819 13.0432 52.5089 13.0432C45.6208 13.0432 40.0337 7.35084 40.0337 0.332977C40.0337 0.275348 40.04 0.224117 40.04 0.166488C38.2677 0.0640379 36.4325 0 34.5031 0C32.4669 0 30.5375 0.0640308 28.6835 0.179288C28.6835 0.230513 28.6898 0.281752 28.6898 0.332977C28.6898 7.35084 23.1027 13.0432 16.2146 13.0432C12.1107 13.0432 8.47809 11.0134 6.20302 7.89511C3.60114 10.3027 1.53976 13.3186 0 17.0836C3.14865 17.9288 6.24073 20.6822 7.64223 23.5444C9.34539 27.015 8.93688 29.7171 7.59823 33.1748C6.42299 36.2163 5.5934 39.4051 5.17861 42.6451C4.34274 49.1699 5.20375 56.1557 9.01229 61.6368C12.1107 66.1062 16.8493 68.7571 21.4623 71.312C23.838 72.6247 26.2199 73.9309 28.4384 75.4997C29.0229 75.9095 34.1386 79.9371 34.4906 81C34.8425 79.9371 39.9646 75.9095 40.5428 75.4997C42.7613 73.9309 45.1432 72.6183 47.5188 71.312C52.1381 68.7635 56.8705 66.1062 59.9689 61.6368C63.7711 56.1557 64.6384 49.1699 63.8025 42.6451C63.3877 39.4051 62.5582 36.2163 61.3829 33.1748H61.4018Z"
    },
    {
      id: 4,
      url: "./assets/images/logos/logo4.svg",
      svg: `<svg width="57" height="75" viewBox="0 0 57 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.0494 12.251C46.6235 10.7959 42.3703 6.39837 41.0329 0.823835C40.8427 0.0283978 39.8982 -0.269094 39.3087 0.287066C36.5514 2.88032 32.7229 4.48416 28.495 4.48416C24.2671 4.48416 20.4386 2.88032 17.6813 0.287066C17.0918 -0.269094 16.1473 0.0219308 15.9571 0.823835C14.6133 6.3919 10.3601 10.7894 4.94057 12.251C4.53489 12.3609 4.22429 12.6972 4.17358 13.124L0.129511 46.5518C-0.200099 49.656 0.0851312 52.8248 1.15003 55.7543C3.97707 63.4953 11.6912 66.3278 19.8491 69.0051C21.8711 69.6712 26.1624 73.2992 27.8231 74.7478C28.2098 75.0841 28.7802 75.0841 29.1732 74.7478C30.834 73.2992 35.1316 69.6647 37.1473 69.0051C45.3051 66.3278 53.0193 63.4953 55.8463 55.7543C56.9175 52.8248 57.2028 49.6624 56.8668 46.5518L52.8228 13.124C52.7721 12.6972 52.4614 12.3609 52.0558 12.251H52.0494Z" fill="#EDEDED"/>
      </svg>`,
      siluete: "M52.0494 12.251C46.6235 10.7959 42.3703 6.39837 41.0329 0.823835C40.8427 0.0283978 39.8982 -0.269094 39.3087 0.287066C36.5514 2.88032 32.7229 4.48416 28.495 4.48416C24.2671 4.48416 20.4386 2.88032 17.6813 0.287066C17.0918 -0.269094 16.1473 0.0219308 15.9571 0.823835C14.6133 6.3919 10.3601 10.7894 4.94057 12.251C4.53489 12.3609 4.22429 12.6972 4.17358 13.124L0.129511 46.5518C-0.200099 49.656 0.0851312 52.8248 1.15003 55.7543C3.97707 63.4953 11.6912 66.3278 19.8491 69.0051C21.8711 69.6712 26.1624 73.2992 27.8231 74.7478C28.2098 75.0841 28.7802 75.0841 29.1732 74.7478C30.834 73.2992 35.1316 69.6647 37.1473 69.0051C45.3051 66.3278 53.0193 63.4953 55.8463 55.7543C56.9175 52.8248 57.2028 49.6624 56.8668 46.5518L52.8228 13.124C52.7721 12.6972 52.4614 12.3609 52.0558 12.251H52.0494Z"
    },
    {
      id: 5,
      url: "./assets/images/logos/logo5.svg",
      svg: `<svg width="61" height="73" viewBox="0 0 61 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.5 73C30.5 73 61 59.4786 61 8.88035L30.5 0L0 8.88035C0 59.2364 30.5 73 30.5 73Z" fill="#EDEDED"/>
      </svg>
      `,
      siluete: "M30.5 73C30.5 73 61 59.4786 61 8.88035L30.5 0L0 8.88035C0 59.2364 30.5 73 30.5 73Z"
    },
    {
      id: 6,
      url: "./assets/images/logos/logo6.svg",
      svg: `<svg width="59" height="80" viewBox="0 0 59 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.1216 15.4354H59V9.79644H49.9108C48.2477 4.23405 39.747 0 29.5 0C19.253 0 10.7523 4.23405 9.08922 9.79644H0V15.4354H2.87836C5.11709 15.4354 6.94646 17.2619 6.94646 19.4971C6.94646 21.7323 5.11709 23.5587 2.87836 23.5587H0V50.3999C0 66.7486 29.5 80 29.5 80C29.5 80 59 66.7486 59 50.3999V23.5587H56.1216C53.8829 23.5587 52.0536 21.7323 52.0536 19.4971C52.0536 17.2619 53.8829 15.4354 56.1216 15.4354Z" fill="#EDEDED"/>
      </svg>`,
      siluete: "M56.1216 15.4354H59V9.79644H49.9108C48.2477 4.23405 39.747 0 29.5 0C19.253 0 10.7523 4.23405 9.08922 9.79644H0V15.4354H2.87836C5.11709 15.4354 6.94646 17.2619 6.94646 19.4971C6.94646 21.7323 5.11709 23.5587 2.87836 23.5587H0V50.3999C0 66.7486 29.5 80 29.5 80C29.5 80 59 66.7486 59 50.3999V23.5587H56.1216C53.8829 23.5587 52.0536 21.7323 52.0536 19.4971C52.0536 17.2619 53.8829 15.4354 56.1216 15.4354Z"
    },
    {
      id: 7,
      url: "./assets/images/logos/logo7.svg",
      svg: `<svg width="61" height="75" viewBox="0 0 61 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.1025 14.5545C43.0952 14.5545 34.4711 8.59368 30.5 0C26.5353 8.59368 17.9112 14.5545 7.89749 14.5545C5.13751 14.5545 2.47952 14.0911 0 13.2542V19.5112C0 63.0911 30.5 75 30.5 75C30.5 75 61 63.2971 61 19.5112V13.2542C58.5141 14.0911 55.8625 14.5545 53.1025 14.5545Z" fill="#EDEDED"/>
      </svg>
      `,
      siluete: "M53.1025 14.5545C43.0952 14.5545 34.4711 8.59368 30.5 0C26.5353 8.59368 17.9112 14.5545 7.89749 14.5545C5.13751 14.5545 2.47952 14.0911 0 13.2542V19.5112C0 63.0911 30.5 75 30.5 75C30.5 75 61 63.2971 61 19.5112V13.2542C58.5141 14.0911 55.8625 14.5545 53.1025 14.5545Z"
    },
    {
      id: 8,
      url: "./assets/images/logos/logo8.svg",
      svg: `<svg width="59" height="80" viewBox="0 0 59 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.2475 10.9003C43.3022 4.49338 36.9416 0.0386529 29.547 0.000249932C22.1524 -0.038153 15.748 4.35255 12.74 10.7274L0 10.6635L5.22007 60.9073L29.1647 80L53.2911 61.1505L59 10.9643L46.26 10.9003H46.2475Z" fill="#EDEDED"/>
      </svg>`,
      siluete: "M46.2475 10.9003C43.3022 4.49338 36.9416 0.0386529 29.547 0.000249932C22.1524 -0.038153 15.748 4.35255 12.74 10.7274L0 10.6635L5.22007 60.9073L29.1647 80L53.2911 61.1505L59 10.9643L46.26 10.9003H46.2475Z"
    },
    {
      id: 9,
      url: "./assets/images/logos/logo9.svg",
      svg: `<svg width="61" height="71" viewBox="0 0 61 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H61V50.1855C61 61.6726 51.4671 71 39.7268 71H21.2797C9.53288 71 0 61.6726 0 50.1855V0Z" fill="#EDEDED"/>
      </svg>
      `,
      siluete: "M0 0H61V50.1855C61 61.6726 51.4671 71 39.7268 71H21.2797C9.53288 71 0 61.6726 0 50.1855V0Z"
    },
  ];
  public static desings: any = [
    {
      id: 0,
      url: "./assets/images/desings/desing1.svg",
      svg: (color1, color2) => {
        return `<svg width="77" height="76" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="77" height="76" fill="${color1}"/></svg>`;
      },
    },
    {
      id: 1,
      url: "./assets/images/desings/desing2.svg",
      svg: (color1, color2) => {
        return `<svg width="77" height="76" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="76" width="76" height="39" transform="rotate(-90 0 76)" fill="${color1}"/><rect x="38" y="76" width="76" height="39" transform="rotate(-90 38 76)" fill="${color2}"/></svg>`;
      },
    },
    {
      id: 2,
      url: "./assets/images/desings/desing3.svg",
      svg: (color1, color2) => {
        return `<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="76" width="76" height="19" transform="rotate(-90 0 76)" fill="${color1}"/>
        <rect x="38" y="76" width="76" height="19" transform="rotate(-90 38 76)" fill="${color1}"/>
        <rect x="19" y="76" width="76" height="19" transform="rotate(-90 19 76)" fill="${color2}"/>
        <rect x="57" y="76" width="76" height="19" transform="rotate(-90 57 76)" fill="${color2}"/>
      </svg>
        `;
      },
    },
    {
      id: 3,
      url: "./assets/images/desings/desing4.svg",
      svg: (color1, color2) => {
        return `<svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="77" y="77" width="77" height="77" transform="rotate(180 77 77)" fill="${color1}"/>
        <path d="M77 77L8.9784e-07 -8.97841e-07L77 -7.62939e-06L77 77Z" fill="${color2}"/>
        </svg>`;
      },
    },
    {
      id: 4,
      url: "./assets/images/desings/desing5.svg",
      svg: (color1, color2) => {
        return `<svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="77" width="77" height="77" transform="rotate(-90 0 77)" fill="${color1}"/>
        <path d="M0 77L77 -3.36578e-06L77 77L0 77Z" fill="${color2}"/>
        </svg>
        `;
      },
    },
    {
      id: 5,
      url: "./assets/images/desings/desing6.svg",
      svg: (color1, color2) => {
        return `<svg width="76" height="77" viewBox="0 0 76 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="77" width="77" height="9" transform="rotate(-90 0 77)" fill="${color1}"/>
        <rect x="38" y="77" width="77" height="9" transform="rotate(-90 38 77)" fill="${color1}"/>
        <rect x="19" y="77" width="77" height="9" transform="rotate(-90 19 77)" fill="${color1}"/>
        <rect x="57" y="77" width="77" height="9" transform="rotate(-90 57 77)" fill="${color1}"/>
        <rect x="9" y="77" width="77" height="10" transform="rotate(-90 9 77)" fill="${color2}"/>
        <rect x="47" y="77" width="77" height="10" transform="rotate(-90 47 77)" fill="${color2}"/>
        <rect x="28" y="77" width="77" height="10" transform="rotate(-90 28 77)" fill="${color2}"/>
        <rect x="66" y="77" width="77" height="10" transform="rotate(-90 66 77)" fill="${color2}"/>
        </svg>
        `;
      },
    },
    {
      id: 6,
      url: "./assets/images/desings/desing7.svg",
      svg: (color1, color2) => {
        return `<svg width="77" height="76" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="77" height="76" fill="${color1}"/>
        <rect width="77" height="76" fill="${color1}"/>
        <path d="M48.8362 31.1418C48.9003 31.3297 49.0606 31.455 49.253 31.455C49.4454 31.455 49.6377 31.3297 49.6698 31.1418C50.343 28.3539 52.6513 26.1298 55.4725 25.4719C55.6649 25.4093 55.7931 25.2527 55.7931 25.0647C55.7931 24.8768 55.6649 24.6888 55.4725 24.6575C52.5872 23.9683 50.343 21.7756 49.6698 18.9563C49.6377 18.7684 49.4454 18.6431 49.253 18.6431C49.0606 18.6431 48.8683 18.7684 48.8362 18.9563C48.1309 21.7756 45.9188 23.9683 43.0335 24.6575C42.8411 24.7201 42.7129 24.8768 42.7129 25.0647C42.7129 25.2527 42.8411 25.4406 43.0335 25.4719C45.8547 26.1298 48.1309 28.3539 48.8362 31.1418Z" fill="${color2}"/>
        </svg>
         `;
      },
    },
    {
      id: 7,
      url: "./assets/images/desings/desing8.svg",
      svg: (color1, color2) => {
        return `<svg width="77" height="76" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="77" height="76" fill="${color1}"/>
        <path d="M27.9964 31.1418C28.0605 31.3297 28.2208 31.455 28.4132 31.455C28.6055 31.455 28.7979 31.3297 28.8299 31.1418C29.5032 28.3539 31.8114 26.1298 34.6327 25.4719C34.825 25.4093 34.9533 25.2527 34.9533 25.0647C34.9533 24.8768 34.825 24.6888 34.6327 24.6575C31.7473 23.9683 29.5032 21.7756 28.8299 18.9563C28.7979 18.7684 28.6055 18.6431 28.4132 18.6431C28.2208 18.6431 28.0284 18.7684 27.9964 18.9563C27.2911 21.7756 25.079 23.9683 22.1936 24.6575C22.0013 24.7201 21.873 24.8768 21.873 25.0647C21.873 25.2527 22.0013 25.4406 22.1936 25.4719C25.0149 26.1298 27.2911 28.3539 27.9964 31.1418Z" fill="${color2}"/>
        <path d="M27.9964 55.4514C28.0605 55.6393 28.2208 55.7646 28.4132 55.7646C28.6055 55.7646 28.7979 55.6393 28.8299 55.4514C29.5032 52.6634 31.8114 50.4393 34.6327 49.7815C34.825 49.7189 34.9533 49.5622 34.9533 49.3743C34.9533 49.1863 34.825 48.9984 34.6327 48.9671C31.7473 48.2779 29.5032 46.0852 28.8299 43.2659C28.7979 43.0779 28.6055 42.9526 28.4132 42.9526C28.2208 42.9526 28.0284 43.0779 27.9964 43.2659C27.2911 46.0852 25.079 48.2779 22.1936 48.9671C22.0013 49.0297 21.873 49.1863 21.873 49.3743C21.873 49.5622 22.0013 49.7502 22.1936 49.7815C25.0149 50.4393 27.2911 52.6634 27.9964 55.4514Z" fill="${color2}"/>
        </svg>
        `;
      },
    },
    {
      id: 8,
      url: "./assets/images/desings/desing9.svg",
      svg: (color1, color2) => {
        return `<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="76" height="76" fill="${color1}"/>
        <path d="M50.2173 31.1418C50.2814 31.3297 50.4417 31.455 50.6341 31.455C50.8265 31.455 51.0188 31.3297 51.0509 31.1418C51.7241 28.3539 54.0324 26.1298 56.8536 25.4719C57.046 25.4093 57.1742 25.2527 57.1742 25.0647C57.1742 24.8768 57.046 24.6888 56.8536 24.6575C53.9683 23.9683 51.7241 21.7756 51.0509 18.9563C51.0188 18.7684 50.8265 18.6431 50.6341 18.6431C50.4417 18.6431 50.2494 18.7684 50.2173 18.9563C49.512 21.7756 47.2999 23.9683 44.4146 24.6575C44.2222 24.7201 44.094 24.8768 44.094 25.0647C44.094 25.2527 44.2222 25.4406 44.4146 25.4719C47.2358 26.1298 49.512 28.3539 50.2173 31.1418Z" fill="${color2}"/>
        <path d="M27.4272 31.1418C27.4913 31.3297 27.6516 31.455 27.8439 31.455C28.0363 31.455 28.2286 31.3297 28.2607 31.1418C28.934 28.3539 31.2422 26.1298 34.0634 25.4719C34.2558 25.4093 34.384 25.2527 34.384 25.0647C34.384 24.8768 34.2558 24.6888 34.0634 24.6575C31.1781 23.9683 28.934 21.7756 28.2607 18.9563C28.2286 18.7684 28.0363 18.6431 27.8439 18.6431C27.6516 18.6431 27.4592 18.7684 27.4272 18.9563C26.7219 21.7756 24.5098 23.9683 21.6244 24.6575C21.4321 24.7201 21.3038 24.8768 21.3038 25.0647C21.3038 25.2527 21.4321 25.4406 21.6244 25.4719C24.4456 26.1298 26.7219 28.3539 27.4272 31.1418Z" fill="${color2}"/>
        <path d="M27.4272 55.4514C27.4913 55.6393 27.6516 55.7646 27.8439 55.7646C28.0363 55.7646 28.2286 55.6393 28.2607 55.4514C28.934 52.6634 31.2422 50.4393 34.0634 49.7815C34.2558 49.7189 34.384 49.5622 34.384 49.3743C34.384 49.1863 34.2558 48.9984 34.0634 48.9671C31.1781 48.2779 28.934 46.0852 28.2607 43.2659C28.2286 43.0779 28.0363 42.9526 27.8439 42.9526C27.6516 42.9526 27.4592 43.0779 27.4272 43.2659C26.7219 46.0852 24.5098 48.2779 21.6244 48.9671C21.4321 49.0297 21.3038 49.1863 21.3038 49.3743C21.3038 49.5622 21.4321 49.7502 21.6244 49.7815C24.4456 50.4393 26.7219 52.6634 27.4272 55.4514Z" fill="${color2}"/>
        <path d="M50.2173 55.4514C50.2814 55.6393 50.4417 55.7646 50.6341 55.7646C50.8265 55.7646 51.0188 55.6393 51.0509 55.4514C51.7241 52.6634 54.0324 50.4393 56.8536 49.7815C57.046 49.7189 57.1742 49.5622 57.1742 49.3743C57.1742 49.1863 57.046 48.9984 56.8536 48.9671C53.9683 48.2779 51.7241 46.0852 51.0509 43.2659C51.0188 43.0779 50.8265 42.9526 50.6341 42.9526C50.4417 42.9526 50.2494 43.0779 50.2173 43.2659C49.512 46.0852 47.2999 48.2779 44.4146 48.9671C44.2222 49.0297 44.094 49.1863 44.094 49.3743C44.094 49.5622 44.2222 49.7502 44.4146 49.7815C47.2358 50.4393 49.512 52.6634 50.2173 55.4514Z" fill="${color2}"/>
        </svg>`;
      },
    },
  ];

  public static tourSteps: any = ({ finishTour, tour }) => [
    {
      id: "profile",
      attachTo: {
        element: ".tutorial-profile",
        on: "bottom",
      },
      cancelIcon: {
        enabled: false,
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      highlightClass: "highlight",
      scrollTo: false,
      title: "Tu perfil",
      text: ["Consulta tus datos y modifica tu escudo si lo deseas"],
    },
    {
      id: "training",
      attachTo: {
        element: ".training",
        on: "bottom",
      },
      cancelIcon: {
        enabled: false,
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      highlightClass: "highlight",
      scrollTo: false,
      title: "Entrena a tu equipo",
      text: [
        "Visita la app cada día para entrenar a tu equipo y recibir premios geniales. <br/>¡Serán cada vez mejores si no pierdes la racha!",
      ],
    },
    {
      id: "coins-notification",
      attachTo: {
        element: ".coins-notification",
        on: "bottom",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "Notificaciones y monedas",
      text: [
        "Consulta tus mensajes y visualiza la cantidad de monedas que tienes acumuladas para usar en el market",
      ],
    },
    {
      id: "sponsor",
      attachTo: {
        element: ".sponsor-card",
        on: "bottom",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "El sponsor de tus partidos",
      text: [
        "Selecciona tu sponsor para ver los objetivos por los que recibirás recompensas. <br/>Podrás renovarlo o cambiarlo cuando finalice su contrato.",
      ],
    },
    {
      id: "tournament",
      attachTo: {
        element: ".tournament",
        on: "top",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "Modos de juego",
      text: [
        "Elige la manera en la que quieres jugar. ¡Puedes participar de torneos, crear ligas con amigos o jugar amistosos!",
      ],
    },
    {
      id: "news",
      attachTo: {
        element: ".news",
        on: "top",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "Novedades",
      text: [
        "Entérate de las últimas noticias de tus equipos favoritos de la liga Mexicana",
      ],
    },
    {
      id: "home",
      attachTo: {
        element: ".home",
        on: "bottom",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "Home",
      text: [
        "Desde aquí tienes acceso a tu perfil, patrocinador y a las novedades futbolísticas de la liga mexicana",
      ],
    },
    {
      id: "equipo",
      attachTo: {
        element: ".equipo",
        on: "bottom",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Siguiente",
          type: "next",
          action: tour.next,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "Equipo",
      text: [
        "Revisa tu formación, haz los cambios necesarios, escoge tu modo de juego y tus tácticas para los enfrentamientos",
      ],
    },
    {
      id: "market",
      attachTo: {
        element: ".market",
        on: "bottom-end",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          type: "next",
          text: "Siguiente",
          action: tour.next,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "Market",
      text: [
        "Compra packs de monedas y sobres de jugadores para armar tu equipo ideal",
      ],
    },
    {
      id: "match",
      attachTo: {
        element: ".match",
        on: "bottom",
      },
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Comenzar a jugar",
          action: finishTour,
        },
      ],
      cancelIcon: {
        enabled: false,
      },
      highlightClass: "highlight",
      scrollTo: false,
      title: "Match",
      text: [
        "¡Inicia el desafío! <br/>Escoge un modo de juego y comienza a ganar partidos",
      ],
    },
  ];

  public static positions: any = [
    {
        "id": 1,
        "name": "Centro Delantero",
        "initials": "DC",
        "type": "ofensivo"
    },
    {
        "id": 2,
        "name": "Defensa Derecho",
        "initials": "DFD",
        "type": "defensivo"
    },
    {
        "id": 3,
        "name": "Portero",
        "initials": "PO",
        "type": "portero"
    },
    {
        "id": 4,
        "name": "Volante Izquierdo",
        "initials": "MVI",
        "type": "defensivo"
    },
    {
        "id": 5,
        "name": "Lateral Izquierdo",
        "initials": "LI",
        "type": "defensivo"
    },
    {
        "id": 6,
        "name": "Defensa Izquierdo",
        "initials": "DFI",
        "type": "defensivo"
    },
    {
        "id": 7,
        "name": "Mediocampista Central",
        "initials": "MC",
        "type": "defensivo"
    },
    {
        "id": 8,
        "name": "Lateral Derecho",
        "initials": "LD",
        "type": "defensivo"
    },
    {
        "id": 9,
        "name": "Mediocampista Ofensivo",
        "initials": "MO",
        "type": "ofensivo"
    },
    {
        "id": 10,
        "name": "Media Punta",
        "initials": "MP",
        "type": "ofensivo"
    },
    {
        "id": 11,
        "name": "Mediocampista Externo",
        "initials": "ME",
        "type": "ofensivo"
    },
    {
        "id": 12,
        "name": "Defensor Central",
        "initials": "DFC",
        "type": "defensivo"
    },
    {
        "id": 13,
        "name": "Medio Derecho",
        "initials": "MD",
        "type": "ofensivo"
    },
    {
        "id": 14,
        "name": "Medio Izquierdo",
        "initials": "MI",
        "type": "ofensivo"
    }
  ];

  
  /**
   * Listado de las posiciones en la formación. Esto debe ser temporal, porque tenemos hardcode
   * en refreshTeamInformation (en el formation.service.ts) estos id, que se utilizan para 
   * obtener la posición actual al que se quiere asignar a un jugador (cuando este no tiene posiciones asiganada)
   */
  public static formationPositions: any = [
    {
        "id": 68,
        "position_id": 11,
        "formation_id": 1,
        "order_in_formation": 11
    },
    {
        "id": 67,
        "position_id": 10,
        "formation_id": 1,
        "order_in_formation": 9
    },
    {
        "id": 66,
        "position_id": 9,
        "formation_id": 1,
        "order_in_formation": 8
    },
    {
        "id": 65,
        "position_id": 8,
        "formation_id": 1,
        "order_in_formation": 6
    },
    {
        "id": 63,
        "position_id": 7,
        "formation_id": 1,
        "order_in_formation": 5
    },
    {
        "id": 62,
        "position_id": 6,
        "formation_id": 1,
        "order_in_formation": 2
    },
    {
        "id": 61,
        "position_id": 5,
        "formation_id": 1,
        "order_in_formation": 4
    },
    {
        "id": 64,
        "position_id": 4,
        "formation_id": 1,
        "order_in_formation": 7
    },
    {
        "id": 1,
        "position_id": 3,
        "formation_id": 1,
        "order_in_formation": 1
    },
    {
        "id": 60,
        "position_id": 2,
        "formation_id": 1,
        "order_in_formation": 3
    },
    {
        "id": 59,
        "position_id": 1,
        "formation_id": 1,
        "order_in_formation": 10
    }
  ];
}
