import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-noticia-card",
  templateUrl: "noticia-card.component.html",
  styleUrls: ["noticia-card.component.scss"],
})
export class NoticiaCardComponent implements OnInit {
  @Input() noticia: any;
  constructor(private router: Router) {}

  ngOnInit() {}

  goToNew() {
    this.router.navigate(["/news"]);
  }
}
