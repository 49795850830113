import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { IRangeValue } from 'src/app/interfaces/IRangeValue';

@Component({
  selector: 'app-confirm-slide',
  templateUrl: './confirm-slide.component.html',
  styleUrls: ['./confirm-slide.component.scss'],
})
export class ConfirmSlideComponent implements OnInit {

  @Input() min = 0;
  @Input() max = 10;
  @Input() label;
  @Input() icon = '/assets/images/slider-range.svg';
  @Input() themeStyle: 'dark' | 'black' | 'light' = 'dark';
  @Output() completed: EventEmitter<boolean> = new EventEmitter();
  @Output() actualValue: EventEmitter<IRangeValue> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  async acceptTermsAndConditions(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value === input.max) {
      this.completed.emit(true);
    }
  }

  emitValue(event: Event) {
    const input = event.target as HTMLInputElement;
    this.actualValue.emit({ value: parseInt(input.value, 10), invertedValue: this.invert(this.min, this.max, input.value) });
  }

  invert(min, max, value) {
    return max - value + min;
  }

}
