import { Component, Input, OnInit } from '@angular/core';
import { ICard } from 'src/app/interfaces/ICard';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-silver-card',
  templateUrl: './silver-card.component.svg',
  styleUrls: ['./silver-card.component.scss'],
})
export class SilverCardComponent implements OnInit {
  cardId: string;
  sPath1: string;
  @Input() card: ICard;
  constructor(
    private utilsService: UtilsService
  ) {
    this.cardId = `${utilsService.randomHash(8)}-s-path-1`
    this.sPath1 = `#${this.cardId}`
  }
  ngOnInit() {
  }

}
