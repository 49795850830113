import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { isTokenExpired } from '../../utils/is-token-expired';
import { UsersService } from '../../services/users.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class MessageInterceptor implements HttpInterceptor {

  private token: any;
  private accessToken: any;
  // Unsecured operations that shouldn't be subject to adding auth tokens go here
  readonly UNSECURED_OPERATIONS = [
    '/session/refresh-token',
    '/session/login',
    '/session/signup',
    '/session/sign-in/google',
    '/session/logout',
    '/session/forgot-password',
    '/session/confirm-password',
    '/status/last-status',
    '/check-user-device',
    'ksport-staging-multimedia',
    'ksport-dev-multimedia',
    'ksport-prod-multimedia'
  ];

  constructor(
    private userService: UsersService,
    private utilService: UtilsService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.token = this.userService.getToken();
    this.accessToken = this.token?.accessToken;

    if (!isTokenExpired(this.accessToken) && !this.isUnsecuredOperation(request)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token?.idToken}`,
        },
      });
    }

    if (isTokenExpired(this.accessToken) && !this.isUnsecuredOperation(request)) {
      this.utilService.dismissLoading();
      this.utilService.showAlert("Sesión caducada", "Tu sesión ha caducado, por favor, inicia sesión nuevamente")
      this.handleLogout();
      return EMPTY;
    }


    return next.handle(request).pipe(
      catchError((error) => {
        console.log(error);
        return throwError({ error: error.name, message: error.message, showMessage: error?.error?.message });
      })
    );

  }

  private handleLogout(): void {
    this.userService.logout();
    this.utilService.dismissModal();
    this.utilService.dismissLoading();
    this.router.navigate(['/signUp']);
  }


  /**
   * Check if the graph operation contains a match to one of our unsecured ops
   *
   * @param request graphl query or mutation name
   */
  private isUnsecuredOperation(request): boolean {
    return this.UNSECURED_OPERATIONS.some(unsecuredPath => request.url.includes(unsecuredPath));
  }

}
