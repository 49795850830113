import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITeamInformation } from 'src/app/interfaces/ITeamInformation';
import { IUser } from 'src/app/interfaces/IUser';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-team-created-modal',
  templateUrl: './team-created-modal.component.html',
  styleUrls: ['./team-created-modal.component.scss'],
})
export class TeamCreatedModalComponent {
  @Input() edit;
  @Input() keyFromS3;
  @Input() sponsor;
  teamInformation: ITeamInformation = {} as ITeamInformation;
  user: IUser;

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  play() {
    this.utilsService.goPage('/home');
    this.modalController.dismiss();
  }

  close(){
    this.utilsService.goPage('/profile');
    this.modalController.dismiss();
  }

}
