import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-season-match-state',
  templateUrl: './season-match-state.component.html',
  styleUrls: ['./season-match-state.component.scss'],
})
export class SeasonMatchStateComponent {
  @Input() matches: any[] = [];

  constructor() {}

}
