<ion-grid>
  <ion-row class="seasion-summary ion-justify-content-center ion-align-items-start">
    <ion-col size="12" class="ion-text-center">
      <strong style="color: white">TEMPORADA FINALIZADA</strong>

      <div class="season-match-state">
        <ion-label>{{season?.tournamentTeams?.points}} PTS</ion-label>
        <ion-row class="ion-justify-content-center">
          <ion-img class="image-streak" *ngFor="let match of teamStreak" [src]="getImagePath(match?.result)"></ion-img>
        </ion-row>
      </div>

      <ion-label class="congrats">Felicitaciones</ion-label>
      <br>
      <ion-label class="teams">{{user?.teams[0]?.name}}</ion-label>
      <br>

      <div class="area">
        <div class="description">
          <label style="color: white">{{ stage }}</label>
          <div class="stage">
            {{ this.seasonStats }}
            <div class="triangle"></div>
            <br />
          </div>
        </div>
      </div>
    </ion-col>

    <ion-col size="12">
      <h2>
        <ion-icon src="assets/SoccerCoin.svg" color="primary" class="coin-icon">
        </ion-icon>
        {{ prize }}
      </h2>
    </ion-col>

  <!-- TODO: Reemplace best playes with real data -->
    <ion-col size="12">
      <h6 class="game-mode">
        <ion-label color="light">Mejores jugadores:</ion-label>
      </h6>
      <div class="card-container">
        <app-mini-card
          *ngFor="let player of activePlayers | slice : 0 : 3"
          [card]="player"
        ></app-mini-card>
      </div>
    </ion-col>

    <ion-col size="12">
      <ion-button [disabled]="prize === undefined" (click)="claimPrize()" expand="block">
        Recibir premio
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>