/* eslint-disable @typescript-eslint/dot-notation */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import to from 'await-to-js';
import { IUploadPreSignedUrl } from '../interfaces/IFile.interface';

@Injectable({
  providedIn: 'root',
})
export class FileHandlerService {
  urlApi = '';

  constructor(private http: HttpClient) {
    this.urlApi = environment.apiBase
  }

  /**
   * IMPORTANT: This service should be used after the getUploadPreSignedUrl graphql query subscription.
   * uploads a file to a S3 Bucket after receiving a secure url from the getUploadPreSignedUrl query
   *
   * @param dataUrl this object comes from the getUploadPreSignedUrl query response
   * @param file the file that must to be uploaded to s3
   * @returns
   */

  public uploadFile(dataUrl: IUploadPreSignedUrl, file: File): Observable<any> {
    const formData = new FormData();
    const parameters = dataUrl.fields;
    formData.append('Policy', parameters?.Policy);
    formData.append('X-Amz-Algorithm', parameters.xAmzAlgorithm);
    formData.append('X-Amz-Credential', parameters.xAmzCredential);
    formData.append('X-Amz-Date', parameters.xAmzDate);
    formData.append('X-Amz-Signature', parameters.xAmzSignature);
    formData.append('bucket', parameters.bucket);
    formData.append('key', parameters.key);
    formData.append('file', file, file.name);
    return this.http
      .post<any>(dataUrl['url'], formData)
      .pipe(map(() => ({ key: parameters['key'], displayName: file.name })));
  }

  /**
   * IMPORTANT: This service should be used after the getDownloadPreSignedUrl query subscription.
   * Download a file from the url provided by getDownloadPreSignedUrl query.
   *
   * @param url: comes from the getDownloadPreSignedUrl
   */
  public async downloadFile(Key: string) {
    const [_, resp] = await to<any>(lastValueFrom(this.getSignedUrl(Key)));
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = resp.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public getPreSignedUrl(
    filesData: File[] | any,
    isPublic: boolean,
    folderName?: string,
  ): Observable<any> {
    return this.http.post(`${this.urlApi}/multimedia/presigned`, {
      isPublic,
      filesName: filesData.map((file) => file?.name),
      folderName
    });
  }

  public getSignedUrl(Key: string, responseContentType?: string) {
    return this.http.post(`${this.urlApi}/multimedia/signed`, {
      Key,
      contentType: responseContentType
    });
  }

  public createMultimedia(
    key: string,
    acl: string,
    displayName: string
  ): Observable<any> {
    return this.http.post(`${this.urlApi}/multimedia`, {
      key,
      acl,
      displayName
    });
  }

}
