<ion-content scroll-y="false">
  <ion-grid class="container">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12">
        <h1>Sobre inicial</h1>
      </ion-col>
    </ion-row>
    <ion-footer class="product" size="12">
      <ion-col size="12" class="product-content">
        <ng-container
          [ngTemplateOutlet]="isOpen ? openProductTemplate : productTemplate"
        ></ng-container>
      </ion-col>
    </ion-footer>
  </ion-grid>
</ion-content>

<ng-template #productTemplate>
  <div class="body">
    <div class="image-container">
      <div class="slider-content">
        <app-confirm-slide
          class="slider"
          themeStyle="black"
          [icon]="'/assets/images/scissors.svg'"
          [label]="'Desliza para abrir'"
          (actualValue)="changeOpacity($event)"
          (completed)="openPack()"
        ></app-confirm-slide>
      </div>
      <img
        class="image-container-top"
        src="/assets/images/pack_part_top.svg"
        alt="pack_part_top.svg"
        [ngStyle]="{
          transform: 'rotateZ(' + (354 + elementRotateDeg) + 'deg)',
          '-webkit-transform': 'rotateZ(' + (354 + elementRotateDeg) + 'deg)',
          '-ms-transform': 'rotateZ(' + (354 + elementRotateDeg) + 'deg)'
        }"
      />
      <img
        class="image-container-bottom ion-margin-bottom"
        src="/assets/images/pack_part_bottom.svg"
        alt="pack_part_bottom.svg"
      />
      <img
        class="image-container-back"
        [ngStyle]="{ opacity: elementsOpacity }"
        [ngClass]="{ animated: elementsOpacity === 1 }"
        src="/assets/images/card_part_back.svg"
        alt="card_part_back.svg"
      />
    </div>
  </div>
</ng-template>

<ng-template #openProductTemplate>
  <div class="card ion-margin-top stacked--down-right">
    <div class="content">
      <img
        class="product-container-back"
        src="/assets/images/card_part_back.svg"
        alt="card_part_back.svg"
      />
      <app-medium-card
        [card]="this.firstCard"
        class="first-card animate-first-card"
      ></app-medium-card>
    </div>
  </div>
</ng-template>
