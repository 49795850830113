import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixed-action',
  templateUrl: './fixed-action.component.html',
  styleUrls: ['./fixed-action.component.scss'],
})
export class FixedActionComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }

}
