import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-email-alert-modal',
  templateUrl: './email-alert-modal.component.html',
  styleUrls: ['./email-alert-modal.component.scss'],
})
export class EmailAlertModalComponent implements OnInit {

  constructor(
    public utilsService: UtilsService,
    private userService: UsersService
  ) { }

  ngOnInit() {
  }

  goVerify(){
    const user = this.userService.getLoggedUser()
    let userData = {
      email: user.email,
      name: user.name,
      lastName: user.lastName,
    };
    this.userService.validateEmail(userData).subscribe(res =>{
      console.log(res);
      this.utilsService.dismissModal();
      this.utilsService.goPage('confirm-email');
    })
  }

  dismiss(){
    this.utilsService.dismissModal();
  }

  collectReward(){
    const user = this.userService.getLoggedUser();
    this.userService.payReward({id: user.id, coins: 10}).subscribe( res =>{
      console.log(res);
    })
  }
}
