<div class="container">
  <ion-grid>
    <ion-row class="ion-justify-content-end">
      <ion-button (click)="goTo()" color="secondary-black">
        <ion-icon name="close-outline" color="light" size="large"></ion-icon>
      </ion-button>
    </ion-row>
    <ion-row class="ion-text-center ion-margin-bottom ion-padding-horizontal">
      <ion-label color="light">{{ message }}</ion-label>
    </ion-row>
    <ion-row class="ion-margin-bottom ion-padding-horizontal">
      <ion-button (click)="goTo()" color="primary" class="goto-button">
        <ion-label>{{ buttonText }}</ion-label>
      </ion-button>
    </ion-row>
  </ion-grid>
</div>
