import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IApiGenericResponse } from '../interfaces/IApiResponse';
import { IPack } from '../interfaces/IPack';

@Injectable({
  providedIn: 'root'
})
export class PackService {
  url = environment.apiBase;


  constructor(private httpClient: HttpClient) {
  }

  useAvailableContract(payload) {
    return this.httpClient.post<any>(`${this.url}/packs/use`, payload);
  }

  purchasedContracts(userId: number) {
    return this.httpClient.get<IApiGenericResponse<IPack[]>>(`${this.url}/user/${userId}/available-contracts`);
  }

  contractPacks() {
    return this.httpClient.get<any>(`${this.url}/products/contracts`);
  }

  coinPacks() {
    return this.httpClient.get<any>(`${this.url}/packs/coins`);
  }

  getCoupons() {
    return this.httpClient.get<any>(`${this.url}/coupons`);
  }

  isValidCoupon(code: string) {
    return this.httpClient.get<any>(`${this.url}/coupons/code/${code}`);
  }
}
