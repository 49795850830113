<span class="tooltip-container d-flex font-size-15 ion-align-items-center ion-justify-content-center">
    <ion-icon *ngIf="!hideIcon" [name]="iconName" class="icon"></ion-icon>
    <div class="tooltip" [ngStyle]="{'translate': translate + '%'}">
        <div *ngIf="translocoDescription">
            {{ translocoDescription | transloco }}
        </div>
        <div *ngIf="label">
            {{ label }}
        </div>
    </div>
</span>