import { HttpClient } from "@angular/common/http";
import { Injectable, WritableSignal, signal } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalService } from "./global.service";
import { UtilsService } from "./utils.service";

export type friendlyLeaguePlayersType = {
  teamId: number,
  tournamentTeamsId: number,
}


export type startFriendlyTournamentType = { 
  playerTeams: friendlyLeaguePlayersType[]
  tournamentId: number,
  playersPending: any[],
  prizePool: number
}

@Injectable({
  providedIn: "root",
})
export class TournamentService {
  urlApi: string;
  constructor(
    private Http: HttpClient,
    public GlobalService: GlobalService,
    private utilsService: UtilsService
  ) {
    this.urlApi = this.GlobalService.URL_API;
  }

  tounamentId: WritableSignal<number> = signal(0);
  tournamentName: WritableSignal<string> = signal("Torneo de amigos");
  tournamentStarted: WritableSignal<boolean> = signal(false);

  friendlyTournamentActive: WritableSignal<any[]> = signal([])
  friendlyTournamentFinished: WritableSignal<any[]> = signal([])

  closeSeason(data: any): Observable<any> {
    return this.Http.post<any>(`${this.urlApi}/tournament/close-seasson`, data);
  }

  checkEndOfSeason(data: any) {
    return this.Http.post<any>(`${this.urlApi}/tournament/check-end-season`, data);
  }

  createTournament(data: any): Observable<any> {
    console.log("create tournament @===>", data);
    return this.Http.post<any>(`${this.urlApi}/tournaments`, data);
  }

  getTournamentbyId(tournamentId: number) {
    return this.Http.get<any>(`${this.urlApi}/tournaments/${tournamentId}`);
  }

  createTournamentTeam(data: any) {
    return this.Http.post<any>(`${this.urlApi}/tournament-team`, data);
  }

  getTournamentTeamsByTournamentId(tournamentId, inviteStatus) {
    return this.Http.get<any>(
      `${this.urlApi}/tournament-team/${tournamentId}/${inviteStatus}`,
    );
  }

  getAllTournamentTeamsByyteamId(teamId) {
    return this.Http.get<any>(`${this.urlApi}/all-tournament-team/${teamId}`);
  }

  updateTournamentTeams(data: any) {
    return this.Http.put<any>(`${this.urlApi}/update-team-tournament`, data);
  }

  updateTournament(data: any) {
    return this.Http.put<any>(`${this.urlApi}/tournaments`, data);
  }

  closeInscriptionAndStartFriendlyTournament(data: startFriendlyTournamentType) {
    return this.Http.post<any>(`${this.urlApi}/tournaments/start-friendly-tournament`, data);
  }

  createMultipleTournamentTeams(creatorTeamId, tournamentTeams) {
    const data = {
      creatorTeamId,
      tournamentTeams,
    };
    return this.Http.post<any>(
      `${this.urlApi}/tournament-teams/create-many`,
      data,
    );
  }

  findBotOpponent(teamId: number) {
    const data = {
      teamId,
    }
    return this.Http.post<any>(`${this.urlApi}/tournament-team/bot`, data);
  }

  closeFriendlyTournament(tournament_id: number){
    return this.Http.post<any>(`${this.urlApi}/tournament/closeFriendlyTournament`, JSON.stringify({id: tournament_id}));
  }
  

  saveTemporalFriendlyTournament(active: any[], finish: any[]){
    this.friendlyTournamentActive.set(active);
    this.friendlyTournamentFinished.set(finish);
    this.utilsService.goPage('/match/friendly-tournament/active-arena');
  }
}
