import { Component, Input, OnInit } from '@angular/core';
import { ICard } from 'src/app/interfaces/ICard';

@Component({
  selector: 'app-mini-card',
  templateUrl: './mini-card.component.html',
  styleUrls: ['./mini-card.component.scss'],
})
export class MiniCardComponent implements OnInit {
  @Input() card: ICard;
  @Input() showSkills: boolean;
  kShape1: string;
  kShape2: string;
  kShape3: string;
  backgroundColor: string;
  gradientY2: string;

  ngOnInit() {
    this.setCardType();
  }

  setCardType() {
    switch (this.card?.type) {
      case 1:
        this.kShape1 = '#D6A568';
        this.kShape2 = '#FBDCA2';
        this.kShape3 = '#D6A568';
        this.backgroundColor = '#D6A568';
        this.gradientY2 = '64.779877%';
        break;
      case 2:
        this.kShape1 = '#B2B6BC';
        this.kShape2 = '#F1F2F4';
        this.kShape3 = '#B2B6BC';
        this.backgroundColor = '#B2B6BC';
        this.gradientY2 = '67.2548635%';
        break;
      case 3:
        this.kShape1 = '#E8CF58';
        this.kShape2 = '#FFF5AB';
        this.kShape3 = '#C4AE44';
        this.backgroundColor = '#DAC14A';
        this.gradientY2 = '68.3255938%';
        break;
      case 4:
        this.kShape1 = '';
        this.kShape2 = '';
        this.kShape3 = '';
        this.backgroundColor = '';
        break;
      case 5:
        this.kShape1 = '';
        this.kShape2 = '';
        this.kShape3 = '';
        this.backgroundColor = '';
        break;
      default:
        this.kShape1 = '';
        this.kShape2 = '';
        this.kShape3 = '';
        this.backgroundColor = '';
        break;
    }
  }
}
