<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-padding">
    <ion-grid class="button-bar">
      <ion-row class="ion-justify-content-center ion-align-items-baseline ion-padding-bottom">
        <ion-col size="3" class="ion-no-padding home" (click)="goPage('home')">
          <img src="assets/icon-home.svg" alt="menu-active.svg" />
          <img src="assets/menu-active.svg" class="active" *ngIf="active === '/home'" alt="menu-active.svg" />
        </ion-col>
        <ion-col size="3" class="ion-no-padding equipo" (click)="goPage('team')">
          <img src="assets/icon-team.svg" alt="menu-active.svg" />
          <img src="assets/menu-active.svg" class="active" *ngIf="
              active === '/team/formation' ||
              active === '/team/information' ||
              active === '/team/cards' ||
              active === '/team/list'
            " alt="menu-active.svg" />
        </ion-col>
        <ion-col size="3" class="ion-no-padding match" (click)="goPage('match')">
          <img src="assets/icon-game.svg" alt="menu-active.svg" />
          <img src="assets/menu-active.svg" class="active" *ngIf="active === '/match'" alt="menu-active.svg" />
        </ion-col>
        <ion-col size="3" class="ion-no-padding market" (click)="goPage('market')">
          <img src="assets/icon-market.svg" alt="menu-active.svg" />
          <img src="assets/menu-active.svg" class="active" *ngIf="active.includes('/market')" alt="menu-active.svg" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>