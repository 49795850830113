import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PrefixType, Shorthand, SkillBox, TagSize, TagType, getSkillInformation } from 'src/app/interfaces/highlight-skill.types';

@Component({
  selector: 'app-hightlight-skill',
  templateUrl: './hightlight-skill.component.html',
  styleUrls: ['./hightlight-skill.component.scss'],
})
export class HightlightSkillComponent implements OnChanges {
  @Input() size: TagSize = 'sm';
  @Input() type: TagType = 'gold';
  @Input() shorthand: Shorthand = Shorthand.CAP;
  @Input() skillValue: number = 0;
  @Input() prefixType: PrefixType = 'none';
  skill: SkillBox = getSkillInformation(this.shorthand);

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.shorthand?.currentValue) {
      this.skill = getSkillInformation(changes?.shorthand.currentValue);
    }
  }

}
