<ion-content>
  <ion-grid class="container">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12">
        <h1 class="title">Operación completada</h1>
      </ion-col>
      <ion-col size="12">
        <label class="">
          Tus tokens fueron acreditados en tu cuenta
        </label>
      </ion-col>
    </ion-row>
    <ion-row class="cancel-button">
      <ion-col size="12">
        <ion-button (click)="dismiss()" expand="block" fill="outline">Volver a inicio</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
