<ion-row class="ion-justify-content-center ion-align-items-center">
  <ion-col size="12" class="ion-text-center">
    <div class="card" *ngIf="newSeason">
      <ion-img class="background-img" src="assets/images/cards/camino-a-la-gloria-sin-jugar.svg"></ion-img>
      <ion-row class="ion-no-margin">
        <ion-col size="12" class="streak">
          <ion-row class="ion-justify-content-center">
            <ion-img class="image-streak" *ngFor="let match of teamStreak" [src]="getImagePath(match.result)"></ion-img>
          </ion-row>
        </ion-col>
        <div class="description">
          <p>
            Record: {{ season?.tournamentTeams?.won }} -
            {{ season?.tournamentTeams?.lost }} -
            {{ season?.tournamentTeams?.draw }}
          </p>
          <p>Partidos restantes: {{ season?.tournamentTeams?.tournament?.duration - season?.matches?.length }}</p>
        </div>
      </ion-row>
    </div>
    <div class="box" [style.background-color]="team?.primarycolor || '#A1E100'" *ngIf="!newSeason" (click)="openSeasonResume()">
      <span class="letra" [style.color]="team?.secondarycolor || '#5C6064'">K</span>
      <ion-row class="ion-no-margin">
        <ion-col size="12" class="streak">
          <ion-row class="ion-justify-content-center">
            <ion-img class="image-streak" *ngFor="let match of this.displayMatches" [src]="getImagePath(match.state)"></ion-img>
          </ion-row>
        </ion-col>
        <div class="description">
          <p>
            Record:  {{ season?.tournamentTeams?.won }} -
            {{ season?.tournamentTeams?.lost }} -
            {{ season?.tournamentTeams?.draw }}
          </p>
          <p>Partidos restantes: {{ this.season?.tournamentTeams?.tournament?.duration - this.season?.matches?.length }}</p>
        </div>
      </ion-row>
    </div>
  </ion-col>
</ion-row>

<ion-row class="stepper">
  <ion-col size="12">
    <app-stepper
      [tournament]="season?.tournamentTeams?.tournament"
      [seasonPoint]="season?.tournamentTeams?.points"
    ></app-stepper>
  </ion-col>
</ion-row>
