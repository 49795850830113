<ion-header>
  <ion-toolbar class="ion-text-left ">
    <h4 *ngIf="!deleteBtn">Equipo </h4>
    <h5 *ngIf="deleteBtn">Jugadores por desvincular: {{excess - playerDeleted?.length < 0? 0: excess - playerDeleted?.length}}</h5>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid class="container ion-no-padding">
    <ion-row>
      <ion-col size="12" class="ion-align-self-center">
        <ion-searchbar (ionInput)="filterByCriteria($event)" color="dark"></ion-searchbar>
      </ion-col>
    </ion-row>
    <ion-row class="action-icons">
      <ion-col size="12">
        <ion-select label="Ordenar" value="valor" multiple="false" interface="popover">
          <ion-select-option value="valor">Por valoración</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" *ngIf="allPlayers?.length > 0">
        <h4>Jugadores con contrato</h4>
        <app-player-card-list *ngIf="!modeView"
                              [playerList]="remainingPlayers.length === 0? 
                              playerReserveListFiltered: 
                                remainingPlayers">
        </app-player-card-list>
        <app-player-list *ngIf="modeView"
                        [contract]="false"
                        [deleteBtn]="deleteBtn"
                        (player)="checkSell($event)"
                        [playerList]="remainingPlayers.length === 0? 
                          playerReserveListFiltered: 
                          remainingPlayers">
        </app-player-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" *ngIf="playerWOContractList?.length > 0">
        <h4>Jugadores sin contrato</h4>
        <app-player-card-list *ngIf="!modeView"
                              [playerList]="playerWOContractListFiltered"></app-player-card-list>
        <app-player-list *ngIf="modeView"
                        [contract]="true"
                        [playerList]="playerWOContractListFiltered"></app-player-list>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="pane">
    <ion-row>
      <ion-col size="12" class="ion-align-self-center ion-text-center box">
        <ion-button *ngIf="deleteBtn" size="small" (click)="sell()">Desvincular</ion-button>
        <ion-button *ngIf="!deleteBtn" size="small" (click)="close()">Continuar</ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>