<ion-card
  [ngClass]="{ card: true, 'card-disabled': isDisabled, 'card-toast': isToast }"
>
  <ion-label class="label" (click)="onClick()">{{ cardData?.title }}</ion-label>
  <ion-label class="date" *ngIf="!isToast">{{ cardData?.date }}</ion-label>
  <img
    *ngIf="isToast"
    class="icon"
    src="/assets/icon/close.svg"
    alt="Close icon"
    (click)="onCloseClick()"
  />
</ion-card>
