import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-check-match-conditions',
  templateUrl: './check-match-conditions.component.html',
  styleUrls: ['./check-match-conditions.component.scss'],
})
export class CheckMatchConditionsComponent  implements OnInit {
  message: any;
  buttonText: any;
  buttonAction: any;

  constructor(
    public utilsService: UtilsService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  goTo() {
    this.utilsService.dismissModal();
    this.router.navigate([this.buttonAction]);
  }

}
