import { Component, Input, OnInit } from '@angular/core';
import { IStreak } from 'src/app/interfaces/IStreak';

@Component({
  selector: 'app-season-card',
  templateUrl: './season-card.component.html',
  styleUrls: ['./season-card.component.scss'],
})
export class SeasonCardComponent implements OnInit {

  @Input() team: any;
  @Input() teamStreak: IStreak[];
  backgroundColor: string;

  constructor() { }

  ngOnInit() {
    this.backgroundColor = this.team?.primarycolor || '#FFFFFF';
  }

  getImagePath(result: string): string {
    switch (result) {
      case 'win':
        return 'assets/icon/match-win-season.svg';
      case 'tie':
        return 'assets/icon/match-tie-season.svg';
      case 'lose':
        return 'assets/icon/match-lose-season.svg';
      case 'first-pending':
      case 'pending':
        return 'assets/icon/match-pending-season.svg';
      default:
        return '';
    }
  }

}
