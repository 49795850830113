<ion-card *ngIf="availableTeam" [style.--background]="background">
  <ion-card-content>
    <ion-grid class="ion-padding">
      <ion-row class="border-bottom">
        <ion-col size="3">
          <app-information-shield containerClass="none" [keyFromS3]="availableTeam?.svg_shield" [height]="45"
            [svg]="availableTeam?.shield_templates?.svg" [name]="availableTeam?.name"
            [primaryColor]="availableTeam.primarycolor" [secondaryColor]="availableTeam.secondarycolor">
          </app-information-shield>
        </ion-col>
        <ion-col class="d-flexr" size="9">
          <section class="team-info">
            <h2><strong>{{availableTeam?.name}}</strong></h2>
            <h5 [innerHTML]="(user.name === availableTeam?.users?.name && user.lastname === availableTeam?.users?.lastname) ? 'DT: Tú' : ('DT: ' + availableTeam?.users?.name + ' ' + availableTeam?.users?.lastname)"></h5>
          </section>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="showActions && (status === 'pending' || !status)" class="ion-align-items-center row-info">
        <ion-col>
          <h5>Ganados: <ion-label color="light">{{ teamStreakCount?.wins }}</ion-label></h5>
        </ion-col>
        <ion-col>
          <h5>Perdidos: <ion-label color="light">{{ teamStreakCount?.losses }}</ion-label></h5>
        </ion-col>
        <ion-col>
          <h5>Empatados: <ion-label color="light">{{ teamStreakCount?.ties }}</ion-label></h5>
        </ion-col>
      </ion-row>
      <section *ngIf="!showActions && !status">
        <ion-row>
          <ion-col size="12" class="no-padding-lateral m-v-5">
            <h5>Racha de últimos partidos</h5>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="teamStreak?.length > 0">
          <ion-img class="m-r-5" *ngFor="let match of teamStreak" [src]="getImagePath(match.result)"></ion-img>
        </ion-row>
        <ion-row *ngIf="teamStreak?.length === 0">
          <ion-col size="12" class="no-padding-lateral">
            <h5>No hay partidos jugados aún</h5>
          </ion-col>
        </ion-row>
      </section>
      <ion-row>
        <ion-col class="ion-no-padding ion-no-margin">
          <app-status-match 
            [status]="status"
            [availableTeam]="availableTeam"
            [showActions]="showActions"
            [receivedInvitation]="receivedInvitation"
            [playedByAway]="playedByAway"
            [playedMatch]="playedMatch"
            (reliveInvitation)="reliveInvitation.emit(availableTeam)"
            (declineInvitation)="declineInvitation.emit(availableTeam)"
            (acceptInvitation)="acceptInvitation.emit(availableTeam)"></app-status-match>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>