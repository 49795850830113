<ion-row class="ion-justify-content-center ion-align-items-center">
  <!-- win the match -->
  <ion-col size="12" *ngIf="win && !tie" class="ion-text-center">
    <div class="cssanimation sequence fadeInBottom">
      <ion-img class="logo" src="assets/icon/SoccerCoin.svg"></ion-img>
      <label class="coins">+{{ WON_MATCH }}</label>
    </div>
    <ion-img class="winner-cup" src="assets/images/match/dt_win.svg"></ion-img>
    <h2>¡Has ganado!</h2>
    <h6>¿Vamos por la revancha?</h6>
    <div class="ion-text-start">
      <ion-button (click)="mute()" color="light" fill="outline">
        <ion-icon [name]="isMute ? 'volume-mute-outline' : 'volume-high-outline'"></ion-icon>
      </ion-button>
    </div>
    <ion-button color="light" fill="outline" class="ion-margin-top block" (click)="shareResult()" *ngIf="isCordovaAvailable()">
      Compartir resultado
    </ion-button>
    <ion-button (click)="backTo()" [color]="isTournament ? 'primary' : 'light'"
      [fill]="isTournament ? 'solid' : 'outline'" class="ion-margin-top block">
      {{ isTournament ? (typeOfTournament === 'season' ? "Volver a temporada" : "Volver a torneo") : "Ir al inicio" }}
    </ion-button>
    <ion-button *ngIf="!isTournament" (click)="redirectToMatch()" color="primary" class="ion-margin-top block">
      Volver a jugar
    </ion-button>
  </ion-col>

  <!-- Lose the match -->
  <ion-col size="12" *ngIf="!win && !tie" class="ion-text-center">
    <div class="cssanimation sequence fadeInBottom">
      <div class="cssanimation sequence fadeInBottom">
        <ion-img class="logo" src="assets/icon/SoccerCoin.svg"></ion-img>
        <label class="coins">+{{ LOSE_MATCH }}</label>
      </div>
    </div>
    <ion-img class="winner-cup" src="assets/images/match/dt_loss.svg"></ion-img>
    <h2>¡Perdiste!</h2>
    <h6>A veces se gana, a veces se pierde</h6>
    <div class="ion-text-start">
      <ion-button (click)="mute()" color="light" fill="outline">
        <ion-icon [name]="isMute ? 'volume-mute-outline' : 'volume-high-outline'"></ion-icon>
      </ion-button>
    </div>
    <ion-button color="light" fill="outline" class="ion-margin-top block" (click)="shareResult()" *ngIf="isCordovaAvailable()">
      Compartir resultado
    </ion-button>
    <ion-button (click)="backTo()" [color]="isTournament ? 'primary' : 'light'"
      [fill]="isTournament ? 'solid' : 'outline'" class="ion-margin-top block">
      {{ isTournament ? (typeOfTournament === 'season' ? "Volver a temporada" : "Volver a torneo") : "Ir al inicio" }}
    </ion-button>
    <ion-button *ngIf="!isTournament" (click)="redirectToMatch()" color="primary" class="ion-margin-top block">
      Quiero la revancha
    </ion-button>
  </ion-col>

  <!-- tie the match -->
  <ion-col size="12" *ngIf="tie" class="ion-text-center">
    <div class="cssanimation sequence fadeInBottom">
      <div class="cssanimation sequence fadeInBottom">
        <ion-img class="logo" src="assets/icon/SoccerCoin.svg"></ion-img>
        <label class="coins">+{{ TIE_MATCH }}</label>
      </div>
    </div>
    <ion-img class="winner-cup" src="assets/images/match/dt_tie.svg"></ion-img>
    <h2>¡Empate!</h2>
    <h6>¿Vamos por la revancha?</h6>
    <div class="ion-text-start">
      <ion-button (click)="mute()" color="light" fill="outline">
        <ion-icon [name]="isMute ? 'volume-mute-outline' : 'volume-high-outline'"></ion-icon>
      </ion-button>
    </div>
    <ion-button color="light" fill="outline" class="ion-margin-top block" (click)="shareResult()" *ngIf="isCordovaAvailable()">
      Compartir resultado
    </ion-button>
    <ion-button (click)="backTo()" [color]="isTournament ? 'primary' : 'light'"
      [fill]="isTournament ? 'solid' : 'outline'" class="ion-margin-top block">
      {{ isTournament ? (typeOfTournament === 'season' ? "Volver a temporada" : "Volver a torneo") : "Ir al inicio" }}
    </ion-button>
    <ion-button *ngIf="!isTournament" (click)="redirectToMatch()" color="primary" class="ion-margin-top block">
      Volver a jugar
    </ion-button>
  </ion-col>
  <canvas *ngIf="tie || win" id="canvas"></canvas>
</ion-row>