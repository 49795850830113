import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  @Input() iconName = 'alert-circle-outline';
  @Input() translocoDescription = '';
  @Input() label = '';
  @Input() hideIcon = false;
  @Input() translate = -65;
}
