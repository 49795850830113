<ion-card class="match-table ion-margin-vertical">
  <ion-card-header>
    <ion-row class="row-border-bottom">
      <ion-col size="7">
        <small class="text-gray">{{tableTitle}}</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">Pl</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">W</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">D</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">L</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">PTS</small>
      </ion-col>
    </ion-row>
  </ion-card-header>

  <ion-card-content>
    <ion-row *ngFor="let team of teams; let i = index"
             class="row-border-bottom ion-align-items-center"
             [ngClass]="{'winners-zone': i < zoneLimit - 1}">
      <ion-col size="7">
        {{team.position}}
        <img src="{{team.shield}}" class="va-icon">
        {{team.name}}
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">{{team.plays}}</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">{{team.wins}}</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">{{team.draws}}</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">{{team.loses}}</small>
      </ion-col>
      <ion-col class="ion-text-center" size="1">
        <small class="text-gray">{{team.points}}</small>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>