<ion-content>
  <ion-grid class="content">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12" class="ion-padding">
        <img [src]="srcQr" alt="">
      </ion-col>
      <ion-col size="12" class="ion-padding">
        <ion-label (click)="copyReferrCode()"> 
          <b>{{referredcode}} 
            <ion-icon src="assets/icon/content_copy.svg" slot="end" class="withe"></ion-icon>
          </b>
        </ion-label>
      </ion-col>
      <ion-col size="12" class="ion-padding">
        <ion-button 
        (click)="socialShareMatch()" 
        expand="block" 
        shape="round">
          Compartir
        </ion-button>
      </ion-col>
      <ion-col size="12" *ngIf="referrals?.length > 0">
        <ion-label>Referidos:</ion-label>
        <hr>
        <ion-row>
          <ion-col size="7" class="ion-text-start"><b>Nombre</b></ion-col>
          <ion-col size="5" class="ion-text-start"><b>Estado</b></ion-col>
        </ion-row>
        <ion-row *ngFor="let referral of referrals">
          <ion-col size="7" class="ion-text-start">
            <ion-label>{{referral?.name}}</ion-label>
          </ion-col>  
          <ion-col size="5" class="ion-text-start">
            <ion-label>{{referral?.status==="no_verify"? 'No verificado': "Verificado"}}</ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>