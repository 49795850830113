import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shield-picker',
  templateUrl: './shield-picker.component.svg',
  styleUrls: ['./shield-picker.component.scss'],
})
export class ShieldPickerComponent implements OnInit {

  @Input() svg: string;
  

  constructor() {
  }

  ngOnInit() {
  }

}
