<ion-row
  class="seasion-summary ion-justify-content-center ion-align-items-start"
>
  <ion-col size="12" class="ion-text-center">
    <strong style="color: white">RESUMEN DE TEMPORADA</strong>

    <div class="season-match-state">
      <app-season-match-state
        [ngStyle]="{ 'z-index': 1 }"
        [matches]="matches"
      ></app-season-match-state>
    </div>

    <div class="area">
      <ion-img class="range" src="assets/escudos/rango_plata.svg"></ion-img>
      <div class="description">
        <div class="stage">
          ZONA DE PERMANENCIA
          <div class="triangle"></div>
        </div>
        <label class="leage">LIGA PROFESIONAL</label>
        <br />
        <strong class="score">+{{ season?.tournamentTeams?.points }}</strong>
      </div>
    </div>
  </ion-col>
  <!-- To DO: Reemplace best playes with real data -->
  <ion-col size="12">
    <h6 class="game-mode">
      <ion-label color="light">Mejores jugadores:</ion-label>
    </h6>
    <div class="card-container">
      <app-mini-card
        *ngFor="let player of activePlayers | slice : 0 : 3"
        [card]="player"
      ></app-mini-card>
    </div>
  </ion-col>
  <!-- To DO: Reemplace game mode with real data -->
  <ion-col size="12">
    <h6 class="game-mode">
      <ion-label color="light">Mejor modelo de juego:</ion-label>
    </h6>
    <div class="card-container">
      <ion-card
        *ngFor="let item of formationsService.gameModels; index as i"
        [ngClass]="{ active: i === 1 }"
        class="game-style-card ml-0 mr-0"
        color="dark"
      >
        <span class="ion-text-uppercase"> {{ item.name }} </span>
      </ion-card>
    </div>
  </ion-col>
  <!-- To DO: Reemplace game system with real data -->
  <ion-col size="12">
    <h6 class="game-mode">
      <ion-label color="light">Mejor sistema de juego:</ion-label>
    </h6>
    <div class="card-container">
      <ion-card
        *ngFor="let item of this.formationsService?.gameSystems; index as i"
        [ngClass]="{ active: i === 0 }"
        class="game-style-card ml-0 mr-0"
        color="dark"
      >
        <span class="ion-text-uppercase"> {{ item.name }} </span>
      </ion-card>
    </div>
  </ion-col>
</ion-row>
