<ion-content class="p-0">
  <ion-row>
    <ion-col size="12">
      <div class="content">
        <ion-label class="title">Atención</ion-label>
        <br><br>
        <ion-label class="subtitle">¡LLegaste a la capacidad máxima de jugadores!</ion-label>
        <br><br>
        <ion-label>Has superado la cantidad permitida de 25 jugadores</ion-label>
        <br><br>
        <ion-label>Para que podamos acreditar tu sobre debes desvincular <b>{{excess}} jugadores</b></ion-label>
        <br><br>
        <ion-label>¿Tenés dudas? <br> <a class="link pointer">Envianos un mensaje</a></ion-label>
      </div>
      <ion-button (click)="sell()" expand="block">
        Desvincular
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>