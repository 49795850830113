import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-performance-and-chemistry',
  templateUrl: './performance-and-chemistry.component.html',
  styleUrls: ['./performance-and-chemistry.component.scss'],
})
export class PerformanceAndChemistryComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
