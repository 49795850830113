/* eslint-disable @typescript-eslint/naming-convention */
export enum ZoneEnum {
  coins_for_champ = 'ZONA DE CAMPEONES',
  coins_for_asc = 'ZONA DE ASCENSO',
  coins_honorary = 'ZONA HONORÍFICA',
  coins_for_stay = 'ZONA DE PERMANENCIA',
  coins_for_desc = 'ZONA DE DESCENSO',
}

export enum ZonePointsEnum {
  points_to_desc = 'ZONA DE DESCENSO',
  points_to_stay = 'ZONA DE PERMANENCIA',
  points_honorary_zone = 'ZONA HONORÍFICA',
  points_to_asc = 'ZONA DE ASCENSO',
  points_to_champ = 'ZONA DE CAMPEONES',
}
