import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReferralsService {
  url: string;

  constructor(private httpClient: HttpClient) {
    this.url = `${environment.apiBase}`;
  }

  getReferrals(userId) {
    return this.httpClient.get<any>(`${this.url}/getReferrals/${userId}`);
  }
}
