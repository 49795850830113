import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of, startWith } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ObsWithStatusResult } from '../../interfaces/observable-async-response';

@Pipe({
  name: 'whenLoading'
})
export class WhenLoadingPipe implements PipeTransform {

  transform(val: Observable<any>): Observable<ObsWithStatusResult<any>> {
    return val.pipe(
      map((value: any) => ({
          loading: value.type === 'start',
          error: value.type === 'error' ? 'error' : '',
          value: value.type ? value.value : value,
        })),
      startWith({ loading: true }),
      catchError(error => of({ loading: false, error: typeof error === 'string' ? error : 'error' }))
    );
  }

}
