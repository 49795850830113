<ion-grid [style.display]="state.value === 'inactive' ? 'none' : ''">
  <ion-row>
    <ion-col
      [size]="options?.payload?.teams || options?.payload?.envelope ? 9 : 12"
    >
      <div
        *ngIf="message && options.enableHtml"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></div>

      <div *ngIf="options?.payload?.link">
        <br />
        <a (click)="actionLink($event, options?.payload?.link)" class="link"
          >Abrilo ahora</a
        >
      </div>
    </ion-col>
    <ion-col *ngIf="options?.payload?.teams" class="place-item-end" size="3">
      <app-information-shield
        class="img"
        size="small"
        [svg]="options.payload?.teams?.shield_templates?.svg"
        [name]="options.payload?.teams?.name"
        [primaryColor]="options.payload?.teams.primarycolor"
        [secondaryColor]="options.payload?.teams.secondarycolor"
        [keyFromS3]="options.payload?.teams?.svg_shield" [height]="36"
      >
      </app-information-shield>
    </ion-col>
    <ion-col *ngIf="options?.payload?.envelope" class="place-item-end" size="3">
      <img [height]="85" alt="/deck-green.jpg" src="assets/deck-green.jpg" />
    </ion-col>
  </ion-row>
</ion-grid>
