import { Component, HostBinding } from '@angular/core';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-html-toast',
  templateUrl: './html-toast.component.html',
  styleUrls: ['./html-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
})
export class HtmlToastComponent extends Toast {
  // constructor is only necessary when not using AoT
  @HostBinding('class') class = 'default';
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private router: Router
  ) {
    // eslint-disable-next-line constructor-super
    super(toastrService, toastPackage);
    this.class = this.options['borderColor'];
  }

  actionLink(event: Event, link: string) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    this.router.navigate([link]);
    return false;
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
