<ion-row class="ion-align-items-center ion-no-margin" *ngIf="team && teamStreak">
  <ion-col size="12">
    <app-information-shield class="img" containerClass="none" [keyFromS3]="team?.svg_shield"
      [svg]="team?.shield_templates?.svg" [name]="team?.name" [primaryColor]="team.primarycolor"
      [secondaryColor]="team.secondarycolor">
    </app-information-shield>
    <div class="box" [style.background-color]="team?.primarycolor || '#A1E100'">
      <span class="letra" [style.color]="team?.secondarycolor || '#5C6064'">K</span>
      <ion-row class="ion-align-items-end ion-no-margin">
        <ion-col size="12" class="ion-no-padding">
          <h3 class="team-name">{{team?.name}}</h3>
        </ion-col>
        <ion-col size="12" class="col-streak">
          <ion-row class="row-streak" *ngIf="teamStreak?.length > 0">
            <ion-img class="image-streak" *ngFor="let match of teamStreak" [src]="getImagePath(match.result)"></ion-img>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
  </ion-col>
</ion-row>