import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
// import { ITeamPlayers } from '../interfaces/ITeamPlayers';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { UtilsService } from './utils.service';
import { UsersService } from './users.service';
import { ISuggestLineUp } from '../interfaces/ISuggestLineUp';
import { IClearLineUp } from '../interfaces/IClearLineup';
import to from 'await-to-js';
import { AppConstants } from '../../app/app.constants'

// TODO Emprolijar esta interfaz, hacerla file aparte y dejarla identica a la interfaz de la API
export interface ITeamInformation {
  cards: [];
  createdDate: Date;
  current_formation_id: any;
  formations: any;
  current_styles: any;
}

@Injectable({
  providedIn: 'root',
})
export class FormationsService {
  actualFormationSubject = new BehaviorSubject(this.getStoredFormation());
  actualFormation: Observable<any> = this.actualFormationSubject.asObservable();
  public teamInformation: ITeamInformation;
  public teamPlayers: any[];
  public teamPlayersWOPlaceholder: any[];
  public currentModel;
  urlApi: string;

  constructor(
    private Http: HttpClient,
    private GlobalService: GlobalService,
    private UtilsService: UtilsService,
    private UserService: UsersService
  ) {
    this.urlApi = this.GlobalService.URL_API;
  }

  loadFormation(type) {
    localStorage.setItem('defaultFormation', type);
    this.actualFormationSubject.next(type);
  }

  getData(key: string) {
    return localStorage.getItem(key);
  }

  changeFormation(item) {
    let user: any = this.UserService.getCurrentUser();
    let obj = {
      id: user.teams[0].id,
      current_formation_id: Number(item.id),
    };
    return this.Http.put<any>(
      `${this.urlApi}/team-current-formation`,
      JSON.stringify(obj)
    );
  }

  async changeModel(type) {
    const user: any = this.UserService.getCurrentUser();
    const obj = {
      id: user.teams[0].id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      current_styles_id: Number(type),
    };
    const [error, response] = await to(
      lastValueFrom(
        this.Http.put<any>(
          `${this.urlApi}/team-current-style`,
          JSON.stringify(obj)
        )
      )
    );
    if (response) {
      this.setModel(response.data.current_styles_id);
    }
  }

  changeModelSubscription(type) {
    const user: any = this.UserService.getCurrentUser();
    const obj = {
      id: user.teams[0].id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      current_styles_id: Number(type),
    };
    return this.Http.put<any>(
      `${this.urlApi}/team-current-style`,
      JSON.stringify(obj)
    );
  }

  susbstitutePlayerInFormation(teamFormationId, formationPositionId, cardId) {
    return this.Http.put<any>(
      `${this.urlApi}/substitute-player-in-formation`,
      JSON.stringify({
        teamformation_id: teamFormationId,
        formationposition_id: formationPositionId,
        card_id: cardId,
      })
    );
  }


  susbstitutePlayersInFormation(
    teamFormationId, oldPlayerformationPositionId, selectedPlayerformationPositionId, oldPlayerCardId, selectedPlayerCardId) {
    return this.Http.put<any>(
      `${this.urlApi}/substitute-players-in-formation`,
      JSON.stringify({
        teamFormationId,
        oldPlayerformationPositionId,
        selectedPlayerformationPositionId,
        oldPlayerCardId,
        selectedPlayerCardId
      })
    );
  }

  setData(key, data) {
    localStorage.setItem(key, data);
  }

  setModel(type) {
    let aux = this.gameModels.find((style) => style.id === type?.toString());
    this.teamInformation.current_styles = aux;
    localStorage.setItem('current_styles', JSON.stringify(aux));
    this.currentModel = aux;
  }

  getStoredFormation(): any {
    let formation = localStorage.getItem('defaultFormation');
    if (!formation) {
      formation = '4-3-3';
    }
    return formation;
  }

  refreshTeamInformation(data) {
    this.teamInformation = data;
    const actualFormationDetails = {
      ...this.teamInformation.formations.find(
        (formation) =>
          formation.formation_id === this.teamInformation.current_formation_id
      ),
    };
    this.teamPlayersWOPlaceholder = actualFormationDetails?.lineUp;

    return this.lineupFillWithPlaceholder(actualFormationDetails?.lineUp);
  }

  lineupFillWithPlaceholder(actualLineUp) {
    const occupiedPositions: any[] =
      actualLineUp.map((position: any) => position.order_in_formation) ?? [];
    const lineUpPlaceholder: any[] = [];
    const auxPositionIds: number[] = [
      1, 62, 60, 61, 63, 65, 64, 66, 67, 59, 68,
    ]; //Sin miedo al hardcode
    for (let index = 1; index <= 11; index++) {
      lineUpPlaceholder.push({
        order_in_formation: index,
        formationposition_id: auxPositionIds[index - 1],
        actualPosition: AppConstants.formationPositions.find(i => i.order_in_formation === index ).position_id
      });
    }
    return [
      ...actualLineUp,
      ...lineUpPlaceholder.filter(
        (position: any) =>
          !occupiedPositions.includes(position.order_in_formation)
      ),
    ];
  }

  suggestLineUp(body: ISuggestLineUp) {
    return this.Http.post<any>(`${this.urlApi}/teams/suggest-lineup`, body);
  }

  clearLineUp(body: IClearLineUp) {
    return this.Http.post<any>(`${this.urlApi}/teams/clear-lineup`, body);
  }

  /**
   *
   * @returns Los jugadores del lineUp actual sin completar los lugares sobrantes
   */
  getActivePlayersWOPlaceholder() {
    return this.teamPlayersWOPlaceholder;
  }

  /**
   *
   * @returns Los jugadores del lineUp actual completando el template de formacion con jugadores vacios
   */
  getActivePlayers() {
    return this.teamPlayers;
  }

  //Fake Data placeholder
  public gameModels = [
    {
      id: '1',
      name: 'ofensivo',
    },
    {
      id: '2',
      name: 'pressing',
    },
    {
      id: '3',
      name: 'defensivo',
    },
  ];

  public gameSystems = [
    { id: 1, name: '4-3-3' },
    { id: 2, name: '4-4-2' },
    { id: 5, name: '4-2-3-1' },
    // { id: 3	, name: "4-5-1"},
    // { id: 4	, name: "4-1-4-1"},
    // { id: 6	, name: "4-3-2-1"},
    // { id: 7	, name: "4-4-1-1"},
    // { id: 8	, name: "4-1-3-2"},
    // { id: 9	, name: "4-2-2-2"},
    // { id: 10, name: "3-4-3"},
    // { id: 11, name: "3-1-3-3"},
    // { id: 12, name: "3-3-1-3"},
    // { id: 13, name: "3-3-3-1"},
    // { id: 14, name: "5-4-1"},
    // { id: 15, name: "5-3-2"},
    // { id: 16, name: "5-2-3"},
  ];
}
