import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ISignupChallenge } from '../../../interfaces/ISignupChallenge';
import { UtilsService } from '../../../services/utils.service';
import { InvitationsService } from '../../../services/invitations.service';
import { DeviceUtils } from '../../../utils/device';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { ReferralsService } from 'src/app/services/referrals.service';

@Component({
  selector: 'app-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['./qr-modal.component.scss'],
})
export class QrModalComponent implements OnInit {

  @Input() referredcode: string;
  @Input() srcQr: string = 'assets/qr-example.svg';
  referrbase = environment.appBase
  referrals

  constructor(
    public utilsService: UtilsService,
    private invitationsService: InvitationsService,
    private deviceUtils: DeviceUtils,
    private referralService: ReferralsService
    ) { }

  ngOnInit() {
    this.referralService.getReferrals(JSON.parse(localStorage.getItem('ksport')).user.id).subscribe( res =>{
      console.log(res)
      this.referrals = Object.values(res.data)
      console.log(this.referrals)
    });
  }

  async socialShareMatch() {
    const user = JSON.parse(localStorage.getItem('ksport')).user;
    const challengeDetails: ISignupChallenge = {
      challengerId: user.id,
    };


    this.invitationsService
      .signupChallenge(challengeDetails)
      .subscribe(async (invitationDetails) => {

        const matchUrl = `${this.referrbase}/sign-up?referrcode=${this.referredcode}`;
        if (this.deviceUtils.DEVICE.info.platform !== 'web') {
          await Share.share({
            title: '¡Juega  K-sport Fantasy!',
            text: 'Arma tu equipo, reta a tus amigos y gana en K-sport Fantasy',
            url: matchUrl,
            dialogTitle: 'Compartir',
          });
        } else {
          navigator.share({
            title: '¡Juega  K-sport Fantasy!',
            text: 'Arma tu equipo, reta a tus amigos y gana en K-sport Fantasy',
            url: matchUrl,
          });
        }
      });
  }

  copyReferrCode() {
    Clipboard.write({
      // eslint-disable-next-line id-blacklist
      string: `${this.referrbase}/sign-up?referrcode=${this.referredcode}`,
    });
    this.utilsService.showToast('Enlace de referido copiado!');
  }
}
