<ion-card class="button-card ion-text-left">
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size="4">
        <ion-card class="stats-card ion-text-center">
          <h6 class="general-performance-small">Modelo de juego</h6>
          <h6>
            <ion-label color="primary">{{
              'team_formation.'+teamInformation?.game_styles?.name | transloco
            }}</ion-label>
          </h6>
        </ion-card>
      </ion-col>
      <ion-col size="4">
        <ion-card class="stats-card ion-text-center">
          <h6 class="general-performance-small">Sistema de juego</h6>
          <h6>
            <ion-label color="primary">{{
              teamInformation?.current_formations?.name
            }}</ion-label>
          </h6>
        </ion-card>
      </ion-col>
      <ion-col size="4">
        <ion-card class="stats-card ion-text-center">
          <h6 class="general-performance-small">Team Performance</h6>
          <h6>
            <ion-label color="primary">{{
              teamInformation?.teamPerformance
            }}</ion-label>
          </h6>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row
      *ngIf="showEditFormationButton"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col size="8">
        <ion-button
          size="small"
          color="light"
          expand="block"
          fill="outline"
          shape="default"
          class="button"
          routerLink="/team/formation"
        >
          Editar formaciones
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
